import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Switch, Route, Redirect } from 'react-router-dom';

import Auth from '../modules/Auth';

import { PrivateRoute } from './PrivateRoute';

import LoginContainer from '../components/loginContainer/LoginContainer';

import QuotePage from '../components/pages/QuotePage';
import TopicPage from '../components/pages/TopicPage';
import CalendarPage from '../components/pages/CalendarPage';
import TagPage from '../components/pages/TagPage';

import 'typeface-roboto'

import { alertActions } from '../redux/actions';
import { history } from '../helpers/history';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleUp, faTrashAlt,faAngleRight,faEdit } from '@fortawesome/free-solid-svg-icons'

library.add( faAngleUp, faTrashAlt, faAngleRight,faEdit)

class App extends Component { 

  constructor(props) {
    super(props);

      const { dispatch } = this.props;
      history.listen((location, action) => {
        // clear alert on location change
        dispatch(alertActions.clear());
      });
   }

   render(){
      return <Switch>
          <Route exact path="/login" component={LoginContainer} />
          <Route exact path="/logout" render={ () => {
                Auth.deauthenticateUser()
                return <Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />             
              }
            }
          />

          <PrivateRoute exact path="/" component={TopicPage} />
          <PrivateRoute exact path="/quote" component={QuotePage} />
          <PrivateRoute exact path="/calendar" component={CalendarPage} />
          <PrivateRoute exact path="/topic" component={TopicPage} />
          <PrivateRoute exact path="/topic/:topicId" component={TopicPage} />
          <PrivateRoute exact path="/tag/:type" component={TagPage} />
      </Switch>
    }
}

const mapStateToProps = (state, ownProps) => {
  const { authentication } = state;
  return {
    auth: authentication,
  };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));  