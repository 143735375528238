import React, {Component} from 'react';

import { withStyles } from '@material-ui/core/styles'

import QuoteFilterContainer from '../../quoteFilterContainer/QuoteFilterContainer'
import QuoteListContainer from '../../quoteListContainer/QuoteListContainer'
import Grid from '@material-ui/core/Grid';

import 'moment/locale/es' 
var moment = require("moment");
moment.locale('es');




const styles = theme => ({
	title: {
		textAlign: "center",
		borderBottom: "1px solid rgba(0,0,0,.1)",
		paddingBottom: theme.spacing(1),
		paddingTop: theme.spacing(1)
	}
});

class CalendarAdd extends Component { 

	render() {
		const {date,
			filterQuote,filterWord,
			handleAuthorSelected,handleFilterWordChange,handleFilterWordBlur,
			handleCheckChange,isChecked,
			quoteList,classes
		} = this.props

		return <div>
			<h3 className={classes.title}>{moment(date).format("dddd, D [de] MMMM")}</h3>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<QuoteListContainer
                		reload={(aId) => this.reload(aId)}
                		view="quoteCheck"
                		isChecked={isChecked}
                		handleCheckChange={handleCheckChange}
                		items={quoteList} />
                </Grid>
				<Grid item xs={6}>
					<QuoteFilterContainer 
			            items={filterQuote}
			            handleAuthorSelected={handleAuthorSelected} 
			            handleFilterWordChange={handleFilterWordChange}
			            handleFilterWordBlur={handleFilterWordBlur}            
			            filterWord={filterWord} />
			    </Grid>
			</Grid>
		</div>
	}
}

export default withStyles(styles)(CalendarAdd);