import React, {Component} from 'react';

import { withStyles } from '@material-ui/core/styles'


import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';


const styles = theme => ({
	root: {}	
});

class QuoteCheck extends Component {    	
	
	render() {
		const {quote, author,
			handleCheckChange,isChecked } = this.props
		if( !quote )return false


		return <ListItem>
			<ListItemIcon>
            	<Checkbox
                	edge="start"
                	checked={isChecked(quote.frs_id)}
                	tabIndex={-1}
                	disableRipple
                	onChange={handleCheckChange}
                	inputProps={{ 'aria-labelledby': quote.frs_id }}
              	/>
            </ListItemIcon>
            <ListItemText id={quote.frs_id} 
            	primary={quote.frs_frase}
            	secondary={author.aut_nombre} />
		</ListItem>
	}
}

export default withStyles(styles)(QuoteCheck);