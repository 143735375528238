
import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { authorActions } from '../../../redux/actions';

import QuoteEditContainer from '../quoteEditContainer/QuoteEditContainer';

import Quote from './quote/Quote';
import QuoteXs from './quoteXs/QuoteXs';
import QuoteNoPhoto from './quoteNoPhoto/QuoteNoPhoto';
import QuoteCalendar from './quoteCalendar/QuoteCalendar';
import QuoteCheck from './quoteCheck/QuoteCheck';

class QuoteContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			id: "",
			quote: "",
			author: "",
			openDialogEdit: false			
		}
	}
	setQuote( dispatch, storeQuote, storeAuthor, id ){

		if( !id ) return false;
		if( !storeQuote 
			|| !storeQuote.list 
			|| !storeQuote.list[id]) return false	
		
		const q = storeQuote.list[id]	
		if( !q ) return false

		if( storeAuthor 
			&& ( storeAuthor.isFetching || storeAuthor.isError ) ) return false

		if( !storeAuthor 
			|| !storeAuthor.list 
			|| !storeAuthor.list[q.autor_id] ){
           	
           	dispatch( authorActions.loadByIds( [q.autor_id] ) )
			return false
		} 	

		const a = storeAuthor.list[q.autor_id]

		this.setState({
			quote: q,
			author: a
		})

		return true
	}

	componentDidMount(){		
		this.componentWillReceiveProps(this.props)	
  	}

	componentWillReceiveProps( nextProps ){
		const {dispatch,storeAuthor,id,storeQuote} = nextProps
		if( !this.state.id ){
			this.setState({
				id: id
			})
			this.setQuote( dispatch, storeQuote, storeAuthor, id )

			return false
		}else{
			this.setQuote( dispatch, storeQuote, storeAuthor, this.state.id )
		}
	}
	

	handleOpenDialogEdit = () => {
        const newState = update(this.state, { 
            openDialogEdit: {$set: true},
        })
        this.setState(newState)
    }

    handleCloseDialogEdit = () => {
        const newState = update(this.state, { openDialogEdit: {$set: false} } )
        this.setState(newState) 
    }

    handleQuoteCheckChange = (event) => {
    	const {handleCheckChange} = this.props
    	const {id} = this.state

    	return handleCheckChange(event, id)
    }
    
	render(){
		const {quote,author,openDialogEdit} = this.state
		const {isChecked} = this.props

		if ( !quote ) return false
		if ( !author ) return false

		let dialogEdit = <Dialog open={openDialogEdit} 
            onClose={this.handleCloseDialogEdit} 
            fullWidth={true}
            maxWidth="md">
            <DialogContent dividers>
              	<QuoteEditContainer quote={quote} author={author} />
            </DialogContent>
       </Dialog>

        let quoteView = ""
        const viewType = ( this.props.size )?this.props.size:""
        switch(viewType){
        	case "xs":
				quoteView =  <QuoteXs
					quote={quote}
					author={author}
					page={this.props.page}
					reload={this.props.reload}  />
				break;
			case "noPhoto":
				quoteView =  <QuoteNoPhoto 
					quote={quote}
					author={author}
					openDialogEdit={openDialogEdit}
					handleOpenDialogEdit={this.handleOpenDialogEdit}
					handleCloseDialogEdit={this.handleCloseDialogEdit}
					actions={this.props.actions}
					page={this.props.page}
					reload={this.props.reload} />
				break;
			case "quoteCalendar":
				quoteView =  <QuoteCalendar 
					quote={quote}
					author={author}
					openDialogEdit={openDialogEdit}
					handleOpenDialogEdit={this.handleOpenDialogEdit}
					handleCloseDialogEdit={this.handleCloseDialogEdit}
					actions={this.props.actions}
					page={this.props.page}
					reload={this.props.reload} />
				break;
			case "quoteCheck":
				quoteView =  <QuoteCheck 
					quote={quote}
					author={author}
					handleCheckChange={this.handleQuoteCheckChange}
					isChecked={isChecked} />
				break;
			default:
				quoteView =  <Quote 
					quote={quote}
					author={author}
					openDialogEdit={openDialogEdit}
					handleOpenDialogEdit={this.handleOpenDialogEdit}
					handleCloseDialogEdit={this.handleCloseDialogEdit}
					actions={this.props.actions}
					page={this.props.page}
					reload={this.props.reload}  />
        }


		return <div>{quoteView}{dialogEdit}</div>
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeQuote: state.quote,
		storeAuthor: state.author,     
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteContainer);
