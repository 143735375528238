import React, {Component} from 'react';

import * as moment from 'moment';



import ColDay from './ColDay';
import CalendarAddContainer from '../../quote/calendarAddContainer/CalendarAddContainer';

import { YEAR_INI } from '../../../constants';

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';

import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';




import { withStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';



const styles = theme => ({
	root: {
	},
	calendar: {
		display: 'flex',
		margin: theme.spacing(2)
	},
	colDay:{
		flexGrow: 1,
		flexBasis: 1,
		width: "14%",
		borderRight: "1px solid cyan",
		height: "100vh"

	},
	navBar: {
		marginTop: theme.spacing(2),
		display: "flex"
	},
	navIcons: {
		paddingLeft: theme.spacing(2),		
		flexGrow: 1,
	},
	navButton:{
		margin: theme.spacing(1)
	},
	formControl: {
		margin: theme.spacing(1)
	},
	navSelects: {
		paddingRight: theme.spacing(2),
		flexGrow: 1,
		textAlign: "right"
	}

});

class Calendar extends Component {    	
	
	render() {
		const { classes, current,
			monthList,
			handlePrevClick,handleNextClick,handleTodayClick,
			handleMonthChange,handleYearChange,
			openDialogAdd, handleOpenDialogAdd, handleCloseDialogAdd, dateDialogAdd,
			eventList,
			reload } = this.props
		if( !current )return false 
		let yearList = []
		const nextYear = parseInt(moment().format("YYYY")) + 1
		for ( let y = YEAR_INI; y <= nextYear; y++) {
  			yearList.push( <MenuItem key={y} value={y}>{y}</MenuItem>)
		}
		if( !eventList ) return false
		return <div className={classes.root}>
			<div className={classes.navBar}>
				<div className={classes.navIcons}>
					<Button variant="contained" color="primary"
						onClick={handlePrevClick}
						className={classes.navButton}><NavigateBeforeIcon/></Button>
					<Button variant="contained" color="primary"
						onClick={handleTodayClick}
						className={classes.navButton}>Hoy</Button>
					<Button variant="contained" color="primary"
						onClick={handleNextClick}
						className={classes.navButton}><NavigateNextIcon /></Button>
				</div>
				<div className={classes.navSelects}>
					<FormControl className={classes.formControl}>
						<Select 
							onChange={handleMonthChange}
						    labelId="select-month"
					        id="select-month"
							value={current.format("M")}>
							{monthList.map( m => <MenuItem key={m.number} value={m.number}>{m.label}</MenuItem>)}
						</Select>
					</FormControl>
					<FormControl className={classes.formControl}>
						<Select 
							onChange={handleYearChange}
							labelId="select-year"
					        id="select-year"
							label="Year"
							value={current.format("YYYY")} >
							{yearList.map( o => o)}
						</Select>
					</FormControl>
				</div>
			</div>
			<div className={classes.calendar}>			 	
					{Object.keys(eventList).map( i => {
						let d = eventList[i]
						return <div key={i} className={classes.colDay}>
							<ColDay date={d.date} eventList={d.list}
								handleOpenDialogAdd={handleOpenDialogAdd} />							
						</div>
					})}				
			</div>
			<Dialog open={openDialogAdd} 
            	onClose={handleCloseDialogAdd} 
            	fullWidth={true}
            	maxWidth="md">
            	<DialogContent dividers>
              		<CalendarAddContainer 
              			date={dateDialogAdd}
              			reload={reload} />
            	</DialogContent>
       		</Dialog>
		</div>
		
	}
}

export default withStyles(styles)(Calendar);