import {fetchApi} from '../helpers';

export const authorService = {
    load,
	loadById,
    loadByIds,
	loadByUrl,
    loadSuggest,
    add,
    saveField,
    saveProfesionId,
    deleteProfesionId,
    addSportId,
    deleteSportId,
    addGroupId,
    deleteGroupId,
    addAuthorRelatedId,
    deleteAuthorRelatedId,
    uploadPhoto,
    uploadPhotoBlob,
    _delete
};

function load( ) {
    const res  = fetchApi('api/authorLoad', {}) 

    return res
}

function loadById( id ) {
    const res  = fetchApi('api/authorLoadById', {
        'id': id
    }) 

    return res
}

function loadByIds( ids ) {
    const res  = fetchApi('api/authorLoadByIds', {
        'ids': ids
    }) 

    return res
}

function loadByUrl( url ) {
    const res  = fetchApi('api/authorLoadByUrl', {
        'url': url
    }) 

    return res
}

async function loadSuggest( name  ) {
    const res  = fetchApi('api/authorLoadSuggest', {
        'name': name
    }) 

    return res
}

function add( author,img,imgOriginal ) {
    let oData = {
        object: {
            author: author,   
        },
        blob: {
            imageCropped: img,
            imageOriginal: imgOriginal
        }
    }

    const res  = fetchApi('api/authorAdd', oData,'blob') 

    return res
}

function saveField(id,name,value) {
    const res  = fetchApi('api/authorSaveField', {
        id: id,
        name: name,
        value: value,
    }) 

    return res
}

function saveProfesionId( authorId, tagId ){
    const res  = fetchApi('api/authorSaveProfesionId', {
        authorId: authorId,
        tagId: tagId,
    }) 

    return res
}

function deleteProfesionId( authorId, tagId ){
    const res  = fetchApi('api/authorDeleteProfesionId', {
        authorId: authorId,
        tagId: tagId,
    }) 

    return res
}


function addSportId( authorId, tagId ){
    const res  = fetchApi('api/authorAddSportId', {
        authorId: authorId,
        tagId: tagId,
    }) 

    return res
}

function deleteSportId( authorId, tagId ){
    const res  = fetchApi('api/authorDeleteSportId', {
        authorId: authorId,
        tagId: tagId,
    }) 

    return res
}


function addGroupId( authorId, tagId ){
    const res  = fetchApi('api/authorAddGroupId', {
        authorId: authorId,
        tagId: tagId,
    }) 

    return res
}

function deleteGroupId( authorId, tagId ){
    const res  = fetchApi('api/authorDeleteGroupId', {
        authorId: authorId,
        tagId: tagId,
    }) 

    return res
}



function addAuthorRelatedId( authorId, relatedId ){
    const res  = fetchApi('api/authorSaveRelatedId', {
        authorId: authorId,
        relatedId: relatedId,
    }) 

    return res
}

function deleteAuthorRelatedId( authorId, relatedId ){
    const res  = fetchApi('api/authorDeleteRelatedId', {
        authorId: authorId,
        relatedId: relatedId,
    }) 

    return res
}

function uploadPhoto( formData ) {
    const res  = fetchApi('api/authorUploadPhoto', formData,'file') 

    return res
}

function uploadPhotoBlob( authorId, oImg, oOriginal ) {
    let oData = {
        authorId: authorId,
        object: {},
        blob: {
            imageCropped: oImg,
            imageOriginal: oOriginal
        }
    }
    const res  = fetchApi('api/authorUploadPhotoBlob', oData,'blob') 

    return res
}


function _delete(id) {
    const res  = fetchApi('api/authorDelete', {
        id: id,
    }) 

    return res
}