import React, {Component} from 'react';
import { connect } from 'react-redux'

import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';

import update from 'immutability-helper';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import TopicDeleteContainer from '../topicDeleteContainer/TopicDeleteContainer'
import TopicEditContainer from '../topicEditContainer/TopicEditContainer'
import Topic from './topic/Topic'

class TopicContainer extends Component { 
	
	constructor(props) {
		super(props)

		this.state = {
			openDialog: false,
			list: [],	
		}
	}
	
	handleOpenDialog = (cityId) => {
        const newState = update(this.state, { 
            openDialog: {$set: true},
        })
        this.setState(newState)
    }

    handleCloseDialog = () => {
        const newState = update(this.state, { openDialog: {$set: false} } )
        this.setState(newState) 
    }
	
	render(){
		const {openDialog} = this.state
		var sBtnEdit = ""
		if( this.props.topic.id){
			sBtnEdit = <Button color="primary" onClick={this.handleOpenDialog}>
        			Editar
      			</Button>
		}
		return  <div>
			<Typography variant="h4" component="h4">
				{this.props.topic.name}
				{sBtnEdit}
			</Typography>
			<Dialog open={openDialog} onClose={this.handleCloseDialog} >
		     	<DialogContent dividers>
		     		<TopicEditContainer id={this.props.id} />
		      	</DialogContent>	
		      	<DialogActions>
		          <TopicDeleteContainer 
						id={this.props.id}
						callBack={this.props.handleDeleteCallBack} />
		        </DialogActions>
		   	</Dialog>
		   	
			<Topic items={this.props.items}
				handleDeleteClick={this.props.handleDeleteClick} />
		</div>
  }
}
/*
			<ExpansionPanel>
				<ExpansionPanelSummary expandIcon={<FontAwesomeIcon icon="angle-up" />}>
					Editar Tema
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<TopicEditContainer id={this.props.id} />
				</ExpansionPanelDetails>
				<ExpansionPanelActions>
					<TopicDeleteContainer 
						id={this.props.id}
						callBack={this.props.handleDeleteCallBack} />
        		</ExpansionPanelActions>
			</ExpansionPanel>*/

const mapStateToProps = (state, ownProps) => {
  	return {
		storeTopic: state.topic, 
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicContainer)
