import { authorService } from '../../services';
import { alertActions } from './';


export const authorActions = {
    load,
    loadById,
    loadByIds,
    loadByUrl,
    loadSuggest,
    add,
    saveField,
    addProfesionId,
    deleteProfesionId, 
    addSportId,
    deleteSportId, 
    addGroupId,
    deleteGroupId, 
    addAuthorRelatedId,
    deleteAuthorRelatedId, 
    uploadPhoto,
    uploadPhotoBlob,
    _delete 
}

export const AUTHOR_LOAD_REQUEST = 'AUTHOR_LOAD_REQUEST'
export const AUTHOR_LOAD_SUCCESS = 'AUTHOR_LOAD_SUCCESS'
export const AUTHOR_LOAD_FAILURE = 'AUTHOR_LOAD_FAILURE'

function load( ) {
    return dispatch => {
        dispatch(request( ));   
        authorService.load( )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: AUTHOR_LOAD_REQUEST } 
    }
    function success(rows) { return { type: AUTHOR_LOAD_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_LOAD_FAILURE, error } }
}


export const AUTHOR_LOAD_ID_REQUEST = 'AUTHOR_LOAD_ID_REQUEST'
export const AUTHOR_LOAD_ID_SUCCESS = 'AUTHOR_LOAD_ID_SUCCESS'
export const AUTHOR_LOAD_ID_FAILURE = 'AUTHOR_LOAD_ID_FAILURE'

function loadById( id ) {
    return dispatch => {
        dispatch(request( ));   
        authorService.loadById( id )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: AUTHOR_LOAD_ID_REQUEST } 
    }
    function success(rows) { return { type: AUTHOR_LOAD_ID_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_LOAD_ID_FAILURE, error } }
}

export const AUTHOR_LOAD_IDS_REQUEST = 'AUTHOR_LOAD_IDS_REQUEST'
export const AUTHOR_LOAD_IDS_SUCCESS = 'AUTHOR_LOAD_IDS_SUCCESS'
export const AUTHOR_LOAD_IDS_FAILURE = 'AUTHOR_LOAD_IDS_FAILURE'

function loadByIds( ids ) {
    return dispatch => {
        dispatch(request( ));   
        authorService.loadByIds( ids )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: AUTHOR_LOAD_IDS_REQUEST } 
    }
    function success(rows) { return { type: AUTHOR_LOAD_IDS_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_LOAD_IDS_FAILURE, error } }
}

export const AUTHOR_LOAD_URL_REQUEST = 'AUTHOR_LOAD_URL_REQUEST'
export const AUTHOR_LOAD_URL_SUCCESS = 'AUTHOR_LOAD_URL_SUCCESS'
export const AUTHOR_LOAD_URL_FAILURE = 'AUTHOR_LOAD_URL_FAILURE'

function loadByUrl( url ) {
    return dispatch => {
        dispatch(request( ));   
        authorService.loadByUrl( url )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: AUTHOR_LOAD_URL_REQUEST } 
    }
    function success(rows) { return { type: AUTHOR_LOAD_URL_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_LOAD_URL_FAILURE, error } }
}

export const AUTHOR_LOAD_SUGGEST_REQUEST = 'AUTHOR_LOAD_SUGGEST_REQUEST'
export const AUTHOR_LOAD_SUGGEST_SUCCESS = 'AUTHOR_LOAD_SUGGEST_SUCCESS'
export const AUTHOR_LOAD_SUGGEST_FAILURE = 'AUTHOR_LOAD_SUGGEST_FAILURE'

function loadSuggest( name ) {
    return dispatch => {
        dispatch(request( ));   
        authorService.loadSuggest( name )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: AUTHOR_LOAD_SUGGEST_REQUEST } 
    }
    function success(rows) { return { type: AUTHOR_LOAD_SUGGEST_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_LOAD_SUGGEST_FAILURE, error } }
}



export const AUTHOR_ADD_REQUEST = 'AUTHOR_ADD_REQUEST'
export const AUTHOR_ADD_SUCCESS = 'AUTHOR_ADD_SUCCESS'
export const AUTHOR_ADD_FAILURE = 'AUTHOR_ADD_FAILURE'

function add( userId, author, img, imgOriginal ) {
    return dispatch => {
        dispatch(request( userId, author, img, imgOriginal ));   
        authorService.add(author,img, imgOriginal)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( author, img, imgOriginal ) { 
        return { type: AUTHOR_ADD_REQUEST, userId, author,img,imgOriginal } 
    }
    function success(rows) { return { type: AUTHOR_ADD_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_ADD_FAILURE, error } }
}

export const AUTHOR_SAVE_FIELD_REQUEST = 'AUTHOR_SAVE_FIELD_REQUEST'
export const AUTHOR_SAVE_FIELD_SUCCESS = 'AUTHOR_SAVE_FIELD_SUCCESS'
export const AUTHOR_SAVE_FIELD_FAILURE = 'AUTHOR_SAVE_FIELD_FAILURE'

function saveField( id, name, value ) {
    return dispatch => {
        dispatch(request( id, name, value ));   
        authorService.saveField(id, name, value)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: AUTHOR_SAVE_FIELD_REQUEST, id, name, value } 
    }
    function success(rows) { return { type: AUTHOR_SAVE_FIELD_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_SAVE_FIELD_FAILURE, error } }
}

export const AUTHOR_ADD_PROFESION_REQUEST = 'AUTHOR_ADD_PROFESION_REQUEST'
export const AUTHOR_ADD_PROFESION_SUCCESS = 'AUTHOR_ADD_PROFESION_SUCCESS'
export const AUTHOR_ADD_PROFESION_FAILURE = 'AUTHOR_ADD_PROFESION_FAILURE'

function addProfesionId( quoteId, profesionId ) {
    return dispatch => {
        dispatch(request( quoteId, profesionId ));   
        authorService.saveProfesionId(quoteId, profesionId )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: AUTHOR_ADD_PROFESION_REQUEST, quoteId, profesionId } 
    }
    function success(rows) { return { type: AUTHOR_ADD_PROFESION_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_ADD_PROFESION_FAILURE, error } }
}

export const AUTHOR_DELETE_PROFESION_ID_REQUEST = 'AUTHOR_DELETE_PROFESION_ID_REQUEST'
export const AUTHOR_DELETE_PROFESION_ID_SUCCESS = 'AUTHOR_DELETE_PROFESION_ID_SUCCESS'
export const AUTHOR_DELETE_PROFESION_ID_FAILURE = 'AUTHOR_DELETE_PROFESION_ID_FAILURE'

function deleteProfesionId( authorId, profesionId ) {
    return dispatch => {
        dispatch(request( authorId, profesionId ));   
        authorService.deleteProfesionId(authorId, profesionId )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: AUTHOR_DELETE_PROFESION_ID_REQUEST, authorId, profesionId } 
    }
    function success(rows) { return { type: AUTHOR_DELETE_PROFESION_ID_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_DELETE_PROFESION_ID_FAILURE, error } }
}

export const AUTHOR_ADD_SPORT_ID_REQUEST = 'AUTHOR_ADD_SPORT_ID_REQUEST'
export const AUTHOR_ADD_SPORT_ID_SUCCESS = 'AUTHOR_ADD_SPORT_ID_SUCCESS'
export const AUTHOR_ADD_SPORT_ID_FAILURE = 'AUTHOR_ADD_SPORT_ID_FAILURE'

function addSportId( authorId, sportId ) {
    return dispatch => {
        dispatch(request( authorId, sportId ));   
        authorService.addSportId(authorId, sportId )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: AUTHOR_ADD_SPORT_ID_REQUEST, authorId, sportId } 
    }
    function success(rows) { return { type: AUTHOR_ADD_SPORT_ID_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_ADD_SPORT_ID_FAILURE, error } }
}

export const AUTHOR_DELETE_SPORT_ID_REQUEST = 'AUTHOR_DELETE_SPORT_ID_REQUEST'
export const AUTHOR_DELETE_SPORT_ID_SUCCESS = 'AUTHOR_DELETE_SPORT_ID_SUCCESS'
export const AUTHOR_DELETE_SPORT_ID_FAILURE = 'AUTHOR_DELETE_SPORT_ID_FAILURE'

function deleteSportId( authorId, sportId ) {
    return dispatch => {
        dispatch(request( authorId, sportId ));   
        authorService.deleteSportId(authorId, sportId )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: AUTHOR_DELETE_SPORT_ID_REQUEST, authorId, sportId } 
    }
    function success(rows) { return { type: AUTHOR_DELETE_SPORT_ID_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_DELETE_SPORT_ID_FAILURE, error } }
}


export const AUTHOR_ADD_GROUP_ID_REQUEST = 'AUTHOR_ADD_GROUP_ID_REQUEST'
export const AUTHOR_ADD_GROUP_ID_SUCCESS = 'AUTHOR_ADD_GROUP_ID_SUCCESS'
export const AUTHOR_ADD_GROUP_ID_FAILURE = 'AUTHOR_ADD_GROUP_ID_FAILURE'

function addGroupId( authorId, groupId ) {
    return dispatch => {
        dispatch(request( authorId, groupId ));   
        authorService.addGroupId(authorId, groupId )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: AUTHOR_ADD_GROUP_ID_REQUEST, authorId, groupId } 
    }
    function success(rows) { return { type: AUTHOR_ADD_GROUP_ID_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_ADD_GROUP_ID_FAILURE, error } }
}

export const AUTHOR_DELETE_GROUP_ID_REQUEST = 'AUTHOR_DELETE_GROUP_ID_REQUEST'
export const AUTHOR_DELETE_GROUP_ID_SUCCESS = 'AUTHOR_DELETE_GROUP_ID_SUCCESS'
export const AUTHOR_DELETE_GROUP_ID_FAILURE = 'AUTHOR_DELETE_GROUP_ID_FAILURE'

function deleteGroupId( authorId, groupId ) {
    return dispatch => {
        dispatch(request( authorId, groupId ));   
        authorService.deleteGroupId(authorId, groupId )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: AUTHOR_DELETE_GROUP_ID_REQUEST, authorId, groupId } 
    }
    function success(rows) { return { type: AUTHOR_DELETE_GROUP_ID_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_DELETE_GROUP_ID_FAILURE, error } }
}




export const AUTHOR_ADD_AUTHOR_RELATED_ID_REQUEST = 'AUTHOR_ADD_AUTHOR_RELATED_ID_REQUEST'
export const AUTHOR_ADD_AUTHOR_RELATED_ID_SUCCESS = 'AUTHOR_ADD_AUTHOR_RELATED_ID_SUCCESS'
export const AUTHOR_ADD_AUTHOR_RELATED_ID_FAILURE = 'AUTHOR_ADD_AUTHOR_RELATED_ID_FAILURE'

function addAuthorRelatedId( authorId, relatedId ) {
    return dispatch => {
        dispatch(request( authorId, relatedId ));   
        authorService.addAuthorRelatedId(authorId, relatedId )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( relatedId ) { 
        return { type: AUTHOR_ADD_AUTHOR_RELATED_ID_REQUEST, authorId, relatedId } 
    }
    function success(rows) { return { type: AUTHOR_ADD_AUTHOR_RELATED_ID_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_ADD_AUTHOR_RELATED_ID_FAILURE, error } }
}

export const AUTHOR_DELETE_AUTHOR_RELATED_ID_REQUEST = 'AUTHOR_DELETE_AUTHOR_RELATED_ID_REQUEST'
export const AUTHOR_DELETE_AUTHOR_RELATED_ID_SUCCESS = 'AUTHOR_DELETE_AUTHOR_RELATED_ID_SUCCESS'
export const AUTHOR_DELETE_AUTHOR_RELATED_ID_FAILURE = 'AUTHOR_DELETE_AUTHOR_RELATED_ID_FAILURE'

function deleteAuthorRelatedId( authorId, relatedId ) {
    return dispatch => {
        dispatch(request( authorId, relatedId ));   
        authorService.deleteAuthorRelatedId(authorId, relatedId )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( profesionId ) { 
        return { type: AUTHOR_DELETE_AUTHOR_RELATED_ID_REQUEST, authorId, relatedId } 
    }
    function success(rows) { return { type: AUTHOR_DELETE_AUTHOR_RELATED_ID_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_DELETE_AUTHOR_RELATED_ID_FAILURE, error } }
}


export const AUTHOR_UPLOAD_PHOTO_REQUEST = 'AUTHOR_UPLOAD_PHOTO_REQUEST'
export const AUTHOR_UPLOAD_PHOTO_SUCCESS = 'AUTHOR_UPLOAD_PHOTO_SUCCESS'
export const AUTHOR_UPLOAD_PHOTO_FAILURE = 'AUTHOR_UPLOAD_PHOTO_FAILURE'

function uploadPhoto( formData ) {
    return dispatch => {
        dispatch(request( formData ));   
        authorService.uploadPhoto(formData)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: AUTHOR_UPLOAD_PHOTO_REQUEST, task } 
    }
    function success(rows) { return { type: AUTHOR_UPLOAD_PHOTO_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_UPLOAD_PHOTO_FAILURE, error } }
}

export const AUTHOR_UPLOAD_PHOTO_BLOB_REQUEST = 'AUTHOR_UPLOAD_PHOTO_BLOB_REQUEST'
export const AUTHOR_UPLOAD_PHOTO_BLOB_SUCCESS = 'AUTHOR_UPLOAD_PHOTO_BLOB_SUCCESS'
export const AUTHOR_UPLOAD_PHOTO_BLOB_FAILURE = 'AUTHOR_UPLOAD_PHOTO_BLOB_FAILURE'

function uploadPhotoBlob( authorId, oImg, oOriginal ) {
    return dispatch => {
        dispatch(request( authorId, oImg, oOriginal  ));   
        authorService.uploadPhotoBlob(authorId, oImg, oOriginal)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( authorId, oImg, oOriginal ) { 
        return { type: AUTHOR_UPLOAD_PHOTO_BLOB_REQUEST, authorId, oImg, oOriginal } 
    }
    function success(rows) { return { type: AUTHOR_UPLOAD_PHOTO_BLOB_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_UPLOAD_PHOTO_BLOB_FAILURE, error } }
}

export const AUTHOR_DELETE_REQUEST = 'AUTHOR_DELETE_REQUEST'
export const AUTHOR_DELETE_SUCCESS = 'AUTHOR_DELETE_SUCCESS'
export const AUTHOR_DELETE_FAILURE = 'AUTHOR_DELETE_FAILURE'

function _delete( id ) { 
    return dispatch => {
        dispatch(request( id ));   
        authorService._delete( id )
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(id) { 
        return { type: AUTHOR_DELETE_REQUEST, id: id } 
    }
    function success(rows) { return { type: AUTHOR_DELETE_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_DELETE_FAILURE, error } }
}