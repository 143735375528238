import {  
  QUOTE_LOAD_CURRENT_REQUEST,
  QUOTE_LOAD_CURRENT_SUCCESS,
  QUOTE_LOAD_CURRENT_FAILURE,
  QUOTE_LOAD_ID_REQUEST,
  QUOTE_LOAD_ID_SUCCESS,
  QUOTE_LOAD_ID_FAILURE,
  QUOTE_LOAD_IDS_REQUEST,
  QUOTE_LOAD_IDS_SUCCESS,
  QUOTE_LOAD_IDS_FAILURE,
  QUOTE_ADD_REQUEST,
  QUOTE_ADD_SUCCESS,
  QUOTE_ADD_FAILURE,
  QUOTE_SAVE_FIELD_REQUEST,
  QUOTE_SAVE_FIELD_SUCCESS,
  QUOTE_SAVE_FIELD_FAILURE,
  QUOTE_DELETE_REQUEST,
  QUOTE_DELETE_SUCCESS,
  QUOTE_DELETE_FAILURE,
} from '../actions/quote'

import update from 'immutability-helper';


const quote = (state = [], action) => {
	let newState = ""
	let rows = ""
	let elem = ""
	let iDeletedId = ""
	let stateList = Object.assign( {}, state.list )
	let currentList = Object.assign( [], state.currentList )

	switch (action.type) {
		case QUOTE_LOAD_CURRENT_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},	
				currentList: {$set: []},	
			});
			return newState 
		case QUOTE_LOAD_CURRENT_SUCCESS:
			action.rows.map( (r) => {
				currentList.push( r )
				return true
			})
			
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				currentList: {$set: currentList},				
			});

			return newState 

		case QUOTE_LOAD_CURRENT_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState


		case QUOTE_LOAD_ID_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case QUOTE_LOAD_ID_SUCCESS:			
			action.rows.map( (r) => {
				stateList[r.frs_id] =   r	
				return true
			})
			

			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				list: {$set: stateList},				
			});

			return newState 

		case QUOTE_LOAD_ID_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case QUOTE_LOAD_IDS_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case QUOTE_LOAD_IDS_SUCCESS:			
			action.rows.map( (r) => {
				stateList[r.frs_id] =   r	
				return true
			})
			

			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				list: {$set: stateList},				
			});

			return newState 

		case QUOTE_LOAD_IDS_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState
		case QUOTE_ADD_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case QUOTE_ADD_SUCCESS:
			elem = Object.assign({},action.rows)
			newState = Object.assign( {}, state )
			if( !newState.list ){
				newState = update( state, {
					list: {$set: {}}
				});
			}
			if( elem ){
				newState = update( state, {
					isSaving: {$set: false},
					list: {[elem.frs_id] : {$set: elem}}
				});
			}
			return newState 

		case QUOTE_ADD_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState
		case QUOTE_SAVE_FIELD_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case QUOTE_SAVE_FIELD_SUCCESS:
			rows = Object.assign({},action.rows[0])
			newState = update( state, {
				isSaving: {$set: false},
				list: {
            		[rows.frs_id]: {$set: rows},
          		},				
			});
			return newState 

		case QUOTE_SAVE_FIELD_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case QUOTE_DELETE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case QUOTE_DELETE_SUCCESS:
			iDeletedId = action.rows
			newState = update( state, {
				isSaving: {$set: false},
				list: {$unset: [iDeletedId]},
			});
			return newState 

		case QUOTE_DELETE_FAILURE:
			return {};
		default:
			return state
  }
}

export default quote