import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles'

import TagDeleteContainer from '../../tagDeleteContainer/TagDeleteContainer';


import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({});

class TagRow extends Component {    	
	
	render() {
		const { tag,
			handleOpenDialogEdit,
    } = this.props
		if( !tag )return false

		return <TableRow key={tag.name}>
          		
          		<TableCell scope="row" component="th">{tag.name}</TableCell>
          		<TableCell >{tag.title}</TableCell>
          		<TableCell >{tag.name_plural}</TableCell>
          		<TableCell >{tag.name_female}</TableCell>
          		<TableCell >{tag.name_female_plural}</TableCell>
          		<TableCell>
          			<IconButton onClick={(id) => handleOpenDialogEdit(tag.id)} ><EditIcon/></IconButton>
          			<TagDeleteContainer id={tag.id} view="iconButton" />
          		</TableCell>
        	</TableRow>
	}
}

export default withStyles(styles)(TagRow);