import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { authorService,quoteService, tagService } from '../../../services';

import { authorActions } from '../../../redux/actions'

import QuoteAdd from './quoteAdd/QuoteAdd'

class QuoteAddContainer extends Component { 
	
	constructor(props) {
		super(props)

		this.state = {
			quote: this.emptyQuote(),	
			authorId: "",
			author: {},		
			errorMsg: ""
		}
	}
	
	emptyQuote(){
		let emptyQuote = { 
			frase: "",
			autor_id: "",
			autor_name: "",
			date: "",
			tagList: {}
		}

		return emptyQuote;
	}

	setEmptyQuote(){
		let newState = update( this.state, {
			quote: {$set: this.emptyQuote()}
		});		
		this.setState( newState );
	}

	// FROM AUTHOR CONTAINER
	setAuthor( dispatch, storeAuthor, id ){
		if( !id ) return false;

		if( storeAuthor 
			&& ( storeAuthor.isFetching || storeAuthor.isError ) ) return false

		if( !storeAuthor 
			|| !storeAuthor.list 
			|| !storeAuthor.list[id] ){

            dispatch( authorActions.loadByIds( [id] ) )

			return false	
		}

		const a = storeAuthor.list[id]
		a.profession = []
		a.related = []

		this.setState({
			author: a
		})

		return true
	}

	componentWillMount(){		
		const {dispatch,storeAuthor,authorId} = this.props

		if( this.props.authorId){
			this.setState({
				quote: {
					autor_id: authorId
				}
			})
			this.setAuthor( dispatch, storeAuthor, authorId)
		}
  	}

	componentWillReceiveProps( nextProps ){
		const {dispatch,storeAuthor,authorId} = nextProps
		const {quote} = this.state
		if( authorId ){
			if( !this.state.authorId ){
				this.setState({
					authorId: authorId
				})
				this.setAuthor( dispatch, storeAuthor, authorId)

				return false
			}else{
				if( quote.autor_id !== this.state.authorId ){					
					if( !storeAuthor ) return false;
					if( storeAuthor.isFetching ) return false;
					this.setState({
						authorId: quote.autor_id
					})

					this.setAuthor( dispatch, storeAuthor, quote.autor_id )
				}				
			}
		}
	}

	handleChange = (event) => {
		const target = event.target;
	   	const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			quote: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleAutorIdChange = (selectedOption) => {
		const {dispatch,storeAuthor} = this.props

		if( selectedOption ){
			let newState = update( this.state, {
				quote: {
					autor_id: {$set: selectedOption.value},
				},
			});
			this.setState( newState );
			this.setAuthor( dispatch, storeAuthor, selectedOption.value )			
		}
  	}
  	handleTagListChange = (selectedOption) => {
		if( selectedOption ){
			let newState = update( this.state, {
				quote: {
					tagList: {$set: selectedOption.value},
				},
			});
			this.setState( newState );
		}
  	}

  	

	handleSubmit = (event) => {
		var quote = this.state.quote
		if( quote.frase === "" || quote.autor_id === "" || quote.autor_id === 0 ){
			let newState = update( this.state, {
				errorMsg: {$set: "La frase y el autor no deben estar vacíos"},
			})
			this.setState( newState );
		}else{
			quoteService.add(quote).then(()=>{
				this.props.reload(quote.autor_id)
				this.setEmptyQuote()
			})			
		}
	}

	loadOptionsAuthor = (inputValue, callback) => {
   		authorService.loadSuggest( inputValue ).then( (list) => {
   			callback( list.rows.map( (aut) =>{
   					return ({ value: aut.id, label: aut.label})
   			}))
	    }) 		
	}

	loadOptionsTag = (inputValue, callback) => {
   		tagService.loadSuggest( inputValue ).then( (list) => {
   			callback( list.rows.map( (aut) =>{
   					return ({ value: aut.id, label: aut.label})
   			}))
	    }) 		
	}

	render(){
		const {quote,errorMsg,author} = this.state
		
		return  <QuoteAdd quote={quote}
			author={author}
			errorMsg={errorMsg}
			loadOptionsAuthor={this.loadOptionsAuthor}
			loadOptionsTag={this.loadOptionsTag}
			handleChange={this.handleChange}
			handleAutorIdChange={this.handleAutorIdChange}
			handleTagListChange={this.handleTagListChange}			
			handleSubmit={this.handleSubmit} />
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeTopic: state.topic, 
		storeAuth: state.auth,
		storeAuthor: state.author
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteAddContainer)
