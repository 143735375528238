import {
  TOPIC_LOAD_REQUEST,
  TOPIC_LOAD_SUCCESS,
  TOPIC_LOAD_FAILURE,
  TOPIC_ADD_REQUEST,
  TOPIC_ADD_SUCCESS,
  TOPIC_ADD_FAILURE,
  TOPIC_SAVE_REQUEST,
  TOPIC_SAVE_SUCCESS,
  TOPIC_SAVE_FAILURE,
  TOPIC_SAVE_FIELD_REQUEST,
  TOPIC_SAVE_FIELD_SUCCESS,
  TOPIC_SAVE_FIELD_FAILURE,
  TOPIC_UPLOAD_PHOTO_REQUEST,
  TOPIC_UPLOAD_PHOTO_SUCCESS,
  TOPIC_UPLOAD_PHOTO_FAILURE,
  TOPIC_UPLOAD_PHOTO_BLOB_REQUEST,
  TOPIC_UPLOAD_PHOTO_BLOB_SUCCESS,
  TOPIC_UPLOAD_PHOTO_BLOB_FAILURE,
  TOPIC_DELETE_REQUEST,
  TOPIC_DELETE_SUCCESS,
  TOPIC_DELETE_FAILURE,
} from '../actions/topic'

import update from 'immutability-helper';

import {DOMAIN_CONTENT} from '../../constants/constants';

function setTopicImg( t, setVersion = false ){
	t.topic_img = DOMAIN_CONTENT +"topic/"+ t.url +".jpg"
	t.topic_img_orig = DOMAIN_CONTENT +"topic/orig/"+ t.url +".jpg"
	if(setVersion){
		t.topic_img += "?v="+ parseInt(Math.random()*100,10)
	}

	return t
}

const topic = (state = [], action) => {
	let newState = ""
	let rows = ""
	let stateList = Object.assign( [], state.list )	

	switch (action.type) {
		case TOPIC_LOAD_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case TOPIC_LOAD_SUCCESS:			
			newState = Object.assign( {}, state )
			if( action.rows ){				 
				stateList = action.rows.map( (top) => {
					let t = setTopicImg(top)
					return t

				})	
				newState = Object.assign( {}, state )
				newState = update( newState, {
					isFetching: {$set: false},
					list: {$set: stateList},				
				});
				/*newState = update( newState, {
					isFetching: {$set: false},
					list: {$set: action.rows},
				});*/
			}
			return newState 

		case TOPIC_LOAD_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case TOPIC_ADD_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case TOPIC_ADD_SUCCESS:
			rows = Object.assign({},action.rows)
			if( rows ){
				newState = update( state, {
					isSaving: {$set: false},
					list:  {$push: [rows]},
				});
			}
			return newState 

		case TOPIC_ADD_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case TOPIC_SAVE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case TOPIC_SAVE_SUCCESS:
			rows = Object.assign({},action.rows)
			if( rows.kaizenDay ){
				let row = rows.kaizenDay
				newState = update( state, {
					isSaving: {$set: false},
					list: {
					  	[row.year] :{
						 	[row.month]: {
								[row.day]: {
									[row.task_id]:	{$set: row}
								}
							}
					  	}
					},
				});
			}
			return newState 

		case TOPIC_SAVE_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case TOPIC_SAVE_FIELD_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case TOPIC_SAVE_FIELD_SUCCESS:
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {$apply: function(topicList){
					topicList.map( t => {
						if(t.id === rows.id){
							return rows
						}
						return t
					})					
				}},
			});
			return newState 

		case TOPIC_SAVE_FIELD_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case TOPIC_UPLOAD_PHOTO_REQUEST:
		case TOPIC_UPLOAD_PHOTO_BLOB_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case TOPIC_UPLOAD_PHOTO_SUCCESS:
		case TOPIC_UPLOAD_PHOTO_BLOB_SUCCESS:
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {$apply: function(topicList){
					const topicId = rows.id
					if( action.type === "TOPIC_UPLOAD_PHOTO_SUCCESS"
						|| action.type === "TOPIC_UPLOAD_PHOTO_BLOB_SUCCESS"){
						topicList[topicId] = setTopicImg( rows, true)
					}else{
						topicList[topicId] = setTopicImg( rows, false)
					}
					return topicList			
				}},
			});
			if( action.type === "TOPIC_UPLOAD_PHOTO_SUCCESS"){
				newState = update( newState, {
					isUploading: {$set: false}
				})
/*				const index = newState.uploadingListId.indexOf(action.todoId);
				if (index >= 0) {
  					//newState = update(newState, { uploadingListId: { $splice: [[index, 1]] } });
				}*/
			}
			return newState 
		
		case TOPIC_UPLOAD_PHOTO_FAILURE:
		case TOPIC_UPLOAD_PHOTO_BLOB_FAILURE:		
			return {};

		case TOPIC_DELETE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case TOPIC_DELETE_SUCCESS:
			rows = Object.assign({},action.rows)
			if( rows.kaizenDay ){
				let row = rows.kaizenDay
				newState = update( state, {
					isSaving: {$set: false},
					list: {$uset: row.id},
				});
			}
			return newState 

		case TOPIC_DELETE_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		default:
			return state
  }
}

export default topic