import React, {Component} from 'react';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button';

import { quoteActions } from '../../../redux/actions'
import MenuItem from '@material-ui/core/MenuItem';


class QuoteDeleteContainer extends Component {


	handleClick = (event) => {
		const {dispatch,callBack} = this.props

		const removeItem = (id, dispatch) => new Promise((resolve, reject) => {
			dispatch( quoteActions._delete(id) )
		  	resolve();
		})


		if(window.confirm('Seguro que quieres eliminar esta frase?')){

			removeItem(this.props.id, dispatch).then(() => {
				if(callBack){
        			callBack()
        		}      			
    		})
        }

	}

	render(){	
		if( !this.props.id ) return false

		let view = <button type="button"
			className="btn btn-outline-danger" 
			onClick={this.handleClick}>
              <FontAwesomeIcon icon="trash-alt"/> Eliminar
      		</button>
		
		if( this.props.view && this.props.view === "menuItem" ){
			view = <MenuItem onClick={this.handleClick}>Delete</MenuItem>
		}

		if( this.props.view && this.props.view === "min" ){
			view = <Button  variant="contained" 
				style={{backgroundColor: "red",color: "#FFF"}}
				onClick={this.handleClick}>
              <FontAwesomeIcon icon="trash-alt"/>
      	</Button>
		}

		return view 
	}
}

const mapStateToProps = (state, ownProps) => {
	return {

	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteDeleteContainer);