import React, {Component} from 'react';


import { withStyles } from '@material-ui/core/styles'


import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


const styles = theme => ({});

class QuoteCalendar extends Component {    	
	
	render() {
		const { quote, author,
			handleOpenDialogEdit } = this.props
		if( !quote )return false

		return <ListItem alignItems="flex-start" button onClick={handleOpenDialogEdit}>
        		<ListItemText
        			primary={quote.frs_frase}
          			secondary={author.aut_nombre}          		
        		/>
      		</ListItem>
	}
}

export default withStyles(styles)(QuoteCalendar);