import React, {Component} from 'react';
import { connect } from 'react-redux'

import { topicActions } from '../../../redux/actions'

import TopicList from './topicList/TopicList'

class TopicListContainer extends Component { 
	
	constructor(props) {
		super(props)

		this.state = {
			list: [],	
		}
	}

	getTopicList(dispatch, storeTopic){
		if( storeTopic && (storeTopic.isFetching || storeTopic.isError) ) return false
		if( !storeTopic || !storeTopic.list  ){
			dispatch( topicActions.load() )
			return false
		}

		if( storeTopic && storeTopic.list ){
			this.setState({
				list: storeTopic.list
			})
		}

		return true
	}
	
	componentWillMount(){
		const {dispatch, storeTopic} =	this.props
		this.getTopicList(dispatch, storeTopic)
	}

	componentWillReceiveProps( nextProps ){
		const {dispatch, storeTopic} =	nextProps

		this.getTopicList(dispatch, storeTopic)
	}

	render(){
		const {list} = this.state
		return  <TopicList 
			list={list} />
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeTopic: state.topic, 
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicListContainer)
