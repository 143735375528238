import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles'

import MenuContainer from './menuContainer/MenuContainer';

const styles = theme => ({
  	container: {
   	  height: '100vh',
   	  overflow: 'hidden',
  	},
    columns: {
      display: "flex"
    },
  	main: {
   	  height: '100vh',
   	  overflow: 'auto',
    	width: '100%',
      paddingTop: theme.spacing(8)
  	},
});

const Page1Col = (props) => {
  const {classes} = props
 
  return <div className={classes.container}>
    <MenuContainer />
    <div className={classes.columns}>
      <main className={classnames(classes.main,classes.colSettings)}>
        {props.main}      
      </main>
    </div>
  </div>    
}

export default withStyles(styles)(Page1Col)