import React, {Component} from 'react';


import QuoteDeleteContainer from '../../quoteDeleteContainer/QuoteDeleteContainer';

import {Card,CardContent,CardActions} from '@material-ui/core/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '@material-ui/core/Button';
import { Rating } from '@material-ui/lab';

import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography';

var moment = require("moment");

const styles = theme => ({
	card: {
  		marginBottom: theme.spacing(2),
  	},  	 
  	details: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between'
  	},
  	content: {  
  		display: 'flex',
  		flexDirection: 'column',
  		flexGrow: 1,		
  	},	
  	action: {
  		display: 'flex',
  		justifyContent: 'center',
  		flexDirection: "column",
  	},
  	actionButton: {
  		margin: theme.spacing(1)
  	},
  	bottomBar:{
  		display: 'flex',
  		justifyContent: "space-between",
  		backgroundColor: "cyan"
  	},
  	bottomCol:{
  		textAlign: "center",
  		width: "33%",
  		flexGrow: 1,
  		color: "rgba(0,0,0,.5)"
  	}
});

class QuoteNoPhoto extends Component {    	
	
	render() {
		const { quote, author,classes,actions,
      handleOpenDialogEdit,
      reload} = this.props
		if( !quote )return false

		const votos = ( quote.frs_votosok > 0 )?quote.frs_votosok +" votos":""
		return <Card className={classes.card}>	
			<div className={classes.details}>		
				<CardContent className={classes.content}>
					<Typography component="h1">{quote.frs_frase}</Typography>
          			<Typography component="h2" color="textSecondary">
            			{author.aut_nombre}
          			</Typography>
			   	</CardContent>  
                <div className={classes.action}>
                    <Button variant="contained" className={classes.actionButton}
                    	color="primary" 
                    	onClick={handleOpenDialogEdit}>
                        <FontAwesomeIcon icon="edit"/>
                    </Button>
                    
                    {(actions)?actions.map( (a) => <Button key={a.label} size="small" color="primary" onClick={(e) => a.handleClick(e, quote.frs_id)}>
	       			     {a.label}
	     			</Button> ):""}

                    <div className={classes.actionButton}><QuoteDeleteContainer id={quote.frs_id}  callBack={reload} view="min"/></div>
 			    </div> 			    
			</div>
			<CardActions className={classes.bottomBar}>
				<div className={classes.bottomCol} >{moment(quote.frs_fechapublicacion).format("YYYY-MM-DD")}</div>
				<div className={classes.bottomCol} > 
					<Rating
		          		value={quote.puntuacion}
		          		readOnly
		        	/>
		        </div>
				<div className={classes.bottomCol} >{votos}</div>
			</CardActions>
		</Card>
	}
}

export default withStyles(styles)(QuoteNoPhoto);