import React, {Component} from 'react';

import { connect } from 'react-redux'
import update from 'immutability-helper';
import * as moment from 'moment';


import CalendarAdd from './calendarAdd/CalendarAdd'

import { isEmpty } from '../../../functions';

import { quoteService } from '../../../services';
import { quoteActions,authorActions } from '../../../redux/actions';


class CalendarAddContainer extends Component { 
	
	constructor(props) {
		super(props)

		this.state = {
			quoteList: [],
        	filterQuote: [],
            filterWord: "",
            authorId: "",
		}
	}

	setQuoteList( items ){
		this.setState({
            quoteList: items
        })
        this.loadQuote( items )

	}

	loadQuote = ( quoteList ) => {
        const {storeQuote,dispatch} = this.props
        let quoteIdList = []
        let authorIdList = []

        quoteList.map( q => {
            if( !storeQuote || !storeQuote.list || !storeQuote.list[q.id] ){
                quoteIdList.push(q.id)
                authorIdList.push(q.autor_id)
            }

            return true
        })
        if( !isEmpty(quoteIdList) ){
            dispatch( quoteActions.loadByIds( quoteIdList ) )
        }
        if( !isEmpty(authorIdList) ){
            dispatch( authorActions.loadByIds( authorIdList ) )
        }

        return true
    }


    loadAuthorQuotes = ( authorId ) => {
        const {dispatch} = this.props
        if( !authorId ) return false
        this.setState({
            authorId: authorId,
            filterWord: "",
        })

        quoteService.loadByAuthorId( authorId ).then( (list) =>{
            let items = list.rows.filter( r => r.frs_fechapublicacion === "0000-00-00")

            dispatch( quoteActions.loadByIds( items ) )
            
            this.setQuoteList( items )            
        })
    }
    handleAuthorSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        const authorId  = suggestion.id
        if( !authorId ) return false

        this.loadAuthorQuotes( authorId )
    }

    handleFilterWordChange = ( event ) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let newState = update( this.state, {
            filterWord: {$set: value},
            authorId: {$set: ""}
        });

        this.setState( newState );
    }

    loadFilterWord( word ){
       quoteService.loadFilterWord( word ).then( (list) =>{
       	    this.setQuoteList( list.rows )            
        }) 
    }

    handleFilterWordBlur = ( event ) => {
        this.loadFilterWord( this.state.filterWord )
    }

    handleCheckChange = (event,id) => {
    	const {dispatch,date} = this.props
    	const {quoteList} = this.state

    	if( !id || !event || !date) return false

  		const target = event.target

	    let value = ""
		if( target.checked ){
			value = moment(date).format("YYYY-MM-DD")
		}		

        
        const saveQuoteDate = (aId,aValue, dispatch) => new Promise((resolve, reject) => {
            dispatch(quoteActions.saveField( aId, "frs_fechapublicacion", aValue ))    
            resolve();
        })

        saveQuoteDate(id, value, dispatch).then(() => {
            if(this.props.reload){
                this.props.reload()
            }             
        })

		//dispatch(quoteActions.saveField( id, "frs_fechapublicacion", value )) 	 

        let list = quoteList.map( q => {
        	if( q.id === id ){
        		q.frs_fechapublicacion = value
        	}
        	return q
        })

        let newState = update( this.state, {
            quoteList: {$set: list},
        });

        this.setState( newState )        
    }

    isChecked = (quoteId) => {
    	const {quoteList} = this.state
    	const {date} = this.props

    	let quote = quoteList.filter( q => q.id === quoteId  )
    	quote= quote[0]

    	if( moment(quote.frs_fechapublicacion).isSame(date) ){
    		return true
    	}else{
    		return false
    	}

    }

	render(){
		const {quoteList,filterQuote,filterWord,authorId} = this.state
		const {date} = this.props

		return  <CalendarAdd 
			quoteList={quoteList} 

			isChecked={this.isChecked}
			handleCheckChange={this.handleCheckChange}

			filterQuote={filterQuote}
			filterWord={filterWord}
			authorId={authorId}

			handleFilterWordChange={this.handleFilterWordChange}
			handleFilterWordBlur={this.handleFilterWordBlur}
			handleAuthorSelected={this.handleAuthorSelected} 

			date={date} />
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeAuthor: state.author, 
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarAddContainer)
