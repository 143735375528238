import React, {Component} from 'react';

import Calendar from './calendar/Calendar'


import update from 'immutability-helper';

import * as moment from 'moment';


class CalendarContainer extends Component { 
	
	constructor(props) {
		super(props)

		
		this.state = {
			current: "",
			weekMonday: "",
			weekSunday: "",
			openDialogAdd: false,
			dateDialogAdd: "",
		}
	}

	componentDidMount(){
		const {date} = this.props
		let dCurrent = moment()
		if( date ){
			dCurrent = moment(date)
		}
		
		this.getQuotes( dCurrent.format("YYYY-MM-DD") )
	}
	
	getQuotes = (date) => {
		const {loadEvents} = this.props

		let dCurrent = moment(date)
		
		let dMonday = moment(dCurrent).startOf('isoweek')
		let dSunday = moment(dCurrent).endOf('isoweek')

		this.setState({
			current: dCurrent,
			weekIni: dMonday,
			weekEnd: dSunday
		})
		if(loadEvents){
			loadEvents(dMonday.format("YYYY-MM-DD"),dSunday.format("YYYY-MM-DD"))
		}	
	}

	handleTodayClick = () => {
		const dToday =  moment()
		this.getQuotes( dToday.format("YYYY-MM-DD") )
		this.setState({
			current: dToday
		})
	}

	handlePrevClick = () => {
		const {current} = this.state
		const dPrev =  moment(current).subtract(1,"week")
		this.getQuotes( dPrev.format("YYYY-MM-DD") )
		this.setState({
			current: dPrev
		})
	}
	handleNextClick = () => {
		const {current} = this.state
		const dNext =  moment(current).add(1,"week")
		this.getQuotes( dNext.format("YYYY-MM-DD") )
		this.setState({
			current: dNext
		})
	}

	handleMonthChange = (event) => {
		const {current} = this.state
		if( event.target.value ){
			let dCurrent = moment(current).set({'month': event.target.value, 'year': current.format("DD"),'day': 1})			
			this.setState({
				current: dCurrent
			})
		}
		
  	}
  	handleMonthChange = (event) => {
		const {current} = this.state

		if( event.target.value ){

			let sNewDate = current.format("YYYY")  +"-"+ event.target.value +"-1"
			let dCurrent = moment(sNewDate)
			
			this.getQuotes( dCurrent.format("YYYY-MM-DD") )
			this.setState({
				current: dCurrent
			})
		}
		
  	}
  	handleYearChange = (event) => {
		const {current} = this.state

		if( event.target.value ){

			let sNewDate = event.target.value +"-"+ current.format("MM") +"-1"
			let dCurrent = moment(sNewDate)
			
			this.getQuotes( dCurrent.format("YYYY-MM-DD") )
			this.setState({
				current: dCurrent
			})
		}
		
  	}

	handleOpenDialogAdd = (date) => {
        const newState = update(this.state, { 
            openDialogAdd: {$set: true},
            dateDialogAdd: {$set: date}
        })
        this.setState(newState)
    }

    handleCloseDialogAdd = () => {
        const newState = update(this.state, { 
        	openDialogAdd: {$set: false},
        	dateDialogAdd: {$set: ""} 
        })
        this.setState(newState) 
    }

    reload = () => {
    	const {weekIni,weekEnd} = this.state
    	console.log("CALENDAR RELOAD", weekIni, weekEnd)
    	this.props.loadEvents(weekIni,weekEnd)
    }
	render(){
		const {weekIni,weekEnd,current,
			openDialogAdd,dateDialogAdd} = this.state
		const {eventList} = this.props
		
		let days = {}
		let dCurrent =  moment(weekIni)
		while(moment(dCurrent) <= moment(weekEnd)) {
			let sKey = dCurrent.format("YYYY-MM-DD")
			if( !days[sKey] ){
				days[sKey] = {
					date: moment(dCurrent),
					list: []
				}
			}
    		dCurrent.add(1, 'days');
		}

		let monthList = []
		let dM =  moment("2020/1/1")

		while(dM <= moment("2020/12/31")) {
			
			monthList.push({
				number: moment(dM).format("M"),
				label: moment(dM).format("MMMM")
			})
    		dM.add(1, 'month');
		}

		if(eventList){
            Object.keys(eventList).map( date => {
                const eList = eventList[date]
                if( !days[date] ){
                    days[date] = []
                }
                days[date].list = eList
                return true
            })
        }

		return  <Calendar 
			current={current}
			weekIni={weekIni} weekEnd={weekEnd} eventList={days} 

			monthList={monthList}
			
			handleNextClick={this.handleNextClick}
			handlePrevClick={this.handlePrevClick}
			handleTodayClick={this.handleTodayClick}
			handleMonthChange={this.handleMonthChange}
			handleYearChange={this.handleYearChange}

			openDialogAdd={openDialogAdd}
			dateDialogAdd={dateDialogAdd}
			handleOpenDialogAdd={this.handleOpenDialogAdd}
			handleCloseDialogAdd={this.handleCloseDialogAdd}

			reload={this.reload}
			/>
		
  }
}


export default CalendarContainer
