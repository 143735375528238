import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import { Droppable, Draggable } from 'react-beautiful-dnd';
import Paper from '@material-ui/core/Paper';

import QuoteContainer from '../../../quote/quoteContainer/QuoteContainer'

const styles = theme => ({
    draggableTopicQuoteList: {
        width: '100%',
        padding: theme.spacing(1),
    },
    draggableTopicQuote: {
        padding: theme.spacing(2),
        margin: '0 0 $'+ theme.spacing(1) +'px 0'
    },
    paperEmptyList: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: 'center',
    },
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'transparent',

    // styles we need to apply on draggables
    ...draggableStyle
});


const Topic = (props) => {
	const {items,classes} = props

    if( !items ) return false 

    let itemList = ""
    if( items.length === 0 ){
        itemList = <Paper className={classes.paperEmptyList} elevation={1}>
        Arrastra una frase aquí para añadirla al Panel</Paper>
    }else{
        itemList = items.map((item, index) => <Draggable
                key={item.id}
                draggableId={item.id.toString()}
                index={index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}>
                        <QuoteContainer key={item.id} id={item.id} actions={[
                            {label: "Eliminar",
                            handleClick: props.handleDeleteClick},
                        ]}/>
                    </div>
                )}
            </Draggable>
        )
    }
	return <div>		
		<Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    className={classes.draggableTopicQuoteList}
                    style={getListStyle(snapshot.isDraggingOver)}>
                    {itemList}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
	</div>
}

export default withStyles(styles)(Topic);