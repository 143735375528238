import {
  TAG_LOAD_BY_TYPE_REQUEST,
  TAG_LOAD_BY_TYPE_SUCCESS,
  TAG_LOAD_BY_TYPE_FAILURE,
  TAG_ADD_REQUEST,
  TAG_ADD_SUCCESS,
  TAG_ADD_FAILURE,
  TAG_SAVE_REQUEST,
  TAG_SAVE_SUCCESS,
  TAG_SAVE_FAILURE,
  TAG_SAVE_FIELD_REQUEST,
  TAG_SAVE_FIELD_SUCCESS,
  TAG_SAVE_FIELD_FAILURE,
  TAG_DELETE_REQUEST,
  TAG_DELETE_SUCCESS,
  TAG_DELETE_FAILURE,
} from '../actions/tag'

import update from 'immutability-helper';


const tag = (state = [], action) => {
	let newState = ""
	let rows = ""
	let deletedId = ""
	let stateList = Object.assign( [], state.list )	

	switch (action.type) {
		case TAG_LOAD_BY_TYPE_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case TAG_LOAD_BY_TYPE_SUCCESS:			
			newState = Object.assign( {}, state )
			if( action.rows ){				 
				stateList = action.rows.map( (top) => {
					//let t = setTopicImg(top)
					return top

				})	
				newState = Object.assign( {}, state )
				newState = update( newState, {
					isFetching: {$set: false},
					list: {$set: stateList},				
				});
				/*newState = update( newState, {
					isFetching: {$set: false},
					list: {$set: action.rows},
				});*/
			}
			return newState 

		case TAG_LOAD_BY_TYPE_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case TAG_ADD_REQUEST:
			newState = update( state, {
				isAdding: {$set: true},					
			});
			return newState 
		case TAG_ADD_SUCCESS:
			rows = Object.assign({},action.rows)
			if( rows ){
				newState = update( state, {
					isAdding: {$set: false},
					list:  {$push: [rows]},
				});
			}
			return newState 

		case TAG_ADD_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case TAG_SAVE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case TAG_SAVE_SUCCESS:
			rows = Object.assign({},action.rows)
			if( rows.kaizenDay ){
				let row = rows.kaizenDay
				newState = update( state, {
					isSaving: {$set: false},
					list: {
					  	[row.year] :{
						 	[row.month]: {
								[row.day]: {
									[row.task_id]:	{$set: row}
								}
							}
					  	}
					},
				});
			}
			return newState 

		case TAG_SAVE_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case TAG_SAVE_FIELD_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
				isSavingId: {$set: action.id},	
			});
			return newState 
		case TAG_SAVE_FIELD_SUCCESS:
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				isSavingId: {$set: false},
				list: {$apply: function(tagList){
					return tagList.map( t => {
						if(t.id === rows.id){
							return rows
						}
						return t
					})					
				}},
			});
			return newState 

		case TAG_SAVE_FIELD_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case TAG_DELETE_REQUEST:
			newState = update( state, {
				isDeleting: {$set: true},
				isDeletingId: {$set: action.id},
			});
			return newState 
		case TAG_DELETE_SUCCESS:
			deletedId = action.id
			var indexToRemove = state.list.findIndex(item => item.id === deletedId);
			newState = update( state, {
				isDeleting: {$set: false},
				isDeletingId: {$set: ""},
				list: {$splice: [[indexToRemove, 1]]},
			});
			return newState 

		case TAG_DELETE_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		default:
			return state
  }
}

export default tag