import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import StarBorderIcon from '@material-ui/icons/StarBorder';

import {  NavLink } from 'react-router-dom';


const TopicList = (props) => {
	const {list} = props
	if( !list ) return false

	return <List>		
		{list.sort((a, b) => {
			/*let iA = parseInt(a.position,10) 
			let iB = parseInt(b.position,10)
			if( iA === iB ){
				return 0
			}else if( iA < iB ){
				return -1
			}else{
				return 1
			}*/
			if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  			if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  			return 0;
		}).map( (t) => {
			let icon = ""
			if( t.featured ){
				icon = <ListItemIcon>
					<StarBorderIcon />
				</ListItemIcon>
			}
			return <NavLink to={"/topic/"+ t.id} key={t.id}>
					<ListItem button >
						{icon}
	  					<ListItemText primary={t.name} />
					</ListItem>
			</NavLink>
		})}
	</List>
}

export default TopicList;