import React, {Component} from 'react';

import { connect } from 'react-redux'

import update from 'immutability-helper';
import {  NavLink,withRouter } from 'react-router-dom';

import TagListContainer from '../tag/tagListContainer/TagListContainer';
import TagAddContainer from '../tag/tagAddContainer/TagAddContainer';
import TagEditContainer from '../tag/tagEditContainer/TagEditContainer';



import Page2Col from '../Page2Col';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';


import {DOMAIN_TITLE} from '../../constants';

import AddIcon from '@material-ui/icons/Add';
import PublicIcon from '@material-ui/icons/Public';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';



class TagPage extends Component {
	constructor(props) {
		super(props)
        
        this.state = {
            openDialogAdd: false,
            openDialogEdit: false,
            editId: "",
            type: "",
		}
    }

     setHeaders() {
        document.title = DOMAIN_TITLE + ": Temas"
    }

    componentDidMount(){
        this.setHeaders()
        let type  = this.props.match.params.type
        this.setState({
            type: type
        })
    }

    componentDidUpdate(prevProps) {
        if( prevProps.match.params.type !== this.props.match.params.type ){
            this.setState({
                type: this.props.match.params.type
            })
        }
 
    }

    handleOpenDialogAdd = () => {
        const newState = update(this.state, { 
            openDialogAdd: {$set: true},
        })
        this.setState(newState)
    }    

    handleCloseDialogAdd = () => {
        const newState = update(this.state, { 
            openDialogAdd: {$set: false} 
        })
        this.setState(newState) 
    }


    handleOpenDialogEdit = (tagId) => {
        const newState = update(this.state, { 
            openDialogEdit: {$set: true},
            editId:  {$set: tagId},
        })
        this.setState(newState)
    }

    handleCloseDialogEdit = () => {
        const newState = update(this.state, { 
            openDialogEdit: {$set: false},
            editId: {$set: ""},
        })
        this.setState(newState) 
    }

	render(){
		const {            
            openDialogAdd, openDialogEdit, editId,type
        } = this.state

        if( !type ) return false

        const dialog = <div>
            <Dialog open={openDialogAdd} 
                onClose={this.handleCloseDialogAdd} 
                type={type}
                maxWidth="xs">
                <DialogContent dividers>
                    <TagAddContainer type={type} />
                </DialogContent>
           </Dialog>
            <Dialog open={openDialogEdit} 
                onClose={this.handleCloseDialogEdit} 
                maxWidth="xs">
                <DialogContent dividers>
                    <TagEditContainer id={editId} />
                </DialogContent>
           </Dialog>
       </div>

		const main = <div>
            <TagListContainer type={type} 
                handleOpenDialogEdit={this.handleOpenDialogEdit} />
            {dialog}
        </div>
		
        const left = <List>
            <ListItem button onClick={this.handleOpenDialogAdd}
                style={{marginBottom: "12px"}}>
                <ListItemIcon><AddIcon /></ListItemIcon>
                <ListItemText primary="Añadir" />
            </ListItem>
            
            <Divider style={{marginBottom: "12px"}} />
            
            <ListItem button component={NavLink} to="/tag/country">
                <ListItemIcon><PublicIcon /></ListItemIcon>
                <ListItemText primary="Países" />
            </ListItem>
            
            <ListItem button component={NavLink} to="/tag/profesiones">
                <ListItemIcon><WorkIcon /></ListItemIcon>
                <ListItemText primary="Profesiones" />
            </ListItem>
            
            <ListItem button component={NavLink} to="/tag/profesion">
                <ListItemIcon><WorkOutlineIcon /></ListItemIcon>
                <ListItemText primary="Profesión" />
            </ListItem>
            
            <ListItem button component={NavLink} to="/tag/main">
                <ListItemIcon><ImportContactsIcon /></ListItemIcon>
                <ListItemText primary="Principal" />
            </ListItem>
            
            <ListItem button component={NavLink} to="/tag/sport">
                <ListItemIcon><ImportContactsIcon /></ListItemIcon>
                <ListItemText primary="Deportes" />
            </ListItem>
            
            <ListItem button component={NavLink} to="/tag/group">
                <ListItemIcon><ImportContactsIcon /></ListItemIcon>
                <ListItemText primary="Agrupación" />
            </ListItem>

        </List>
		return <Page2Col  left={left} main={main}  />
		
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
        storeQuote: state.quote,
        storeTopic: state.topic,
    };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TagPage));
