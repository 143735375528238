import React from 'react';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'


import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';


const styles = theme => ({
	errorMsg: {
    	padding: theme.spacing(3, 2),
    	margin: theme.spacing(3, 2),
    	backgroundColor: "Red"
  	},
  	textField: {
  		marginBottom: theme.spacing(1)
  	},
});

const TagEdit = (props) => {
	const {tag,
		handleChange,handleBlur,
	classes} = props

	var sError = ""
	if( props.errorMsg){
		sError = <Paper className={classes.errorMsg}>
			<strong>Error: </strong> {props.errorMsg}
		</Paper>
	}

	return <div className={classes.root}>
		<Typography variant="h5" gutterBottom>
        	Editar {tag.name}
      	</Typography>
		{sError}
  		<TextField
			label="Nombre"
			placeholder="Nombre"
	    	
	    	name="name"
	    	value={tag.name} 
	    	
	    	onChange={handleChange}
	    	onBlur={handleBlur} 

			className={classes.textField}
	    	fullWidth
	    	/>
		<TextField
			label="Título"
	    	placeholder="Título"
	    	
	    	name="title"
	    	value={tag.title} 
	    	
	    	onChange={handleChange} 
	    	onBlur={handleBlur} 

	    	className={classes.textField}
	    	fullWidth
	    	/>
		<TextField
			label="Plural"
			placeholder="Plural" 
	    	
	    	name="name_plural"
	    	value={tag.name_plural}			    	
	    	
		    onChange={handleChange}
		    onBlur={handleBlur} 
		    
		    className={classes.textField}
	    	fullWidth
		    />
		<TextField
			label="Femenino"
	    	placeholder="Femenino"

	    	name="name_female"
	    	value={tag.name_female}

		    onChange={handleChange} 
		    onBlur={handleBlur}

		    className={classes.textField}
	    	fullWidth 
		    />
		<TextField
			label="Femenino Plural"
	    	placeholder="Femenino Plural"

	    	name="name_female_plural"
	    	value={tag.name_female_plural}

		    onChange={handleChange} 
		    onBlur={handleBlur} 

		    className={classes.textField}
	    	fullWidth
		    />
	</div>
				
}

export default withStyles(styles)(TagEdit);