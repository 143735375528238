import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import {  NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';


import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const styles = {
  root: {
      flexGrow: 1,
      color: "white"
   },
   flex: {
      flexGrow: 1,
      colorInherit: "white"
   },
   menuButton: {
      marginLeft: -12,
      marginRight: 20,
      '&:hover': {
         backgroundColor: 'rgba(255,255,255,.95)',
         color: '#3c52b2',
      }
   },

   navLink:{
      color: "white",
      textDecoration: 'none',
      '&:hover':{
         color: "white",
         textDecoration: 'none'
      }

   }
};

const Menu = ({siteName,classes}) => {
  const buttonMenu = [
      {to: "/calendar", label: "Calendario"},
      {to: "/quote", label: "Frases"},
      {to: "/topic", label: "Temas"},
      {to: "/tag/country", label: "Etiquetas"},
   ]

	return  <React.Fragment>
      <AppBar position="fixed">
         <Toolbar>
   			<Typography component="h1" color="inherit" className={classes.flex} >
   			    <NavLink to="/" className={classes.navLink}>{siteName}</NavLink>
   			</Typography>     

            {buttonMenu.map( m => 
               <NavLink to={m.to} className={classes.navLink}>
                  <Button color="inherit" className={classes.menuButton}>
                     {m.label}
                  </Button>
               </NavLink>
            )}
                 
            <NavLink to="/logout" className={classes.navLink}>
               <IconButton color="inherit"><ExitToAppIcon /></IconButton>
            </NavLink>
         </Toolbar>
	   </AppBar>
   </React.Fragment>
}

export default withStyles(styles)(Menu);