import React, {Component} from 'react';
import { connect } from 'react-redux'
import difference from 'lodash/difference'

import update from 'immutability-helper';

import Resizer from 'react-image-file-resizer';

import { authorService, tagService } from '../../../services';
import { authorActions } from '../../../redux/actions'

import AuthorEdit from './authorEdit/AuthorEdit'

import {authorGender} from '../../../constants';

class AuthorEditContainer extends Component { 
	
	constructor(props) {
		super(props)

		this.state = {
			id: props.author.aut_id,
			author: props.author,
			errorMsg: "",
			isAuthorUploading: false,
			openDialogPhotoEditor: false,
			countryList: []			


		}
	}

	componentWillMount(){

        tagService.loadByType( "country" ).then( (list) => {
            let items = list.rows
            this.setState({
                countryList: items
            })
        })
	}
	componentWillReceiveProps(nextProps){
		if( nextProps.author.author_img !== this.state.author_img ){
			let newState = update( this.state, {
				author: { author_img: {$set: nextProps.author.author_img } },
			});

			this.setState( newState );		
		}
	}

	handleChange = (event) => {
		const target = event.target;
	   	const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		let newState = update( this.state, {
			author: { [name]: {$set: value} },
		});

		this.setState( newState );
	}
	
	handleBlur = ( event ) => {
		const {dispatch} = this.props

  		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		
        dispatch(authorActions.saveField( this.state.id, name, value )) 	    
    }

	handleChange = (event) => {
		const target = event.target;
	   	const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			author: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleGenderChange = (selectedOption) => {
		const {dispatch} = this.props

		let newState = update( this.state, {
			author: {
				sexo: { $set: selectedOption.value},
			}
		});

		this.setState( newState );

		dispatch(authorActions.saveField( this.state.id, "sexo", newState.author.sexo )) 	    

	}

	handleCountryChange = (selectedOption) => {
		const {dispatch} = this.props
		
		if( selectedOption ){
			dispatch(authorActions.saveField( this.state.id, "country_id", selectedOption.value ))

			let newState = update( this.state, {
				author: {
					country_id: {$set: selectedOption.value},
					country_name: {$set: selectedOption.label},
				},
			});
			this.setState( newState );
		}

  	}

	
	loadOptionsCountry = (inputValue, callback) => {
   		tagService.loadSuggestByType( "country", inputValue).then( (list) => {
   			callback( list.rows.map( (tag) =>{
   				return ({ value: tag.id, label: tag.label})
   			}))
	    }) 		
	}

  	handleAuthorRelatedChange = (selectedOption) => {
		const {dispatch} = this.props
		console.log(this.state.author,"STAUTHOR")
		if( selectedOption ){
			let currentAuthorRelated = []
			if( this.state.author.relatedList){
				currentAuthorRelated = this.state.author.relatedList
					.filter( p => p.aut_id )
					.map( p => p.aut_id )
			}
			const optionsValue = selectedOption.map(({ value }) => value)
			console.log( "UUU", currentAuthorRelated, optionsValue)
		    if( currentAuthorRelated 
				&& currentAuthorRelated.length < selectedOption.length) {		    	
		      	const addedElement = difference(optionsValue,currentAuthorRelated)[0]		      
		      	console.log(addedElement,"addedElement")
		      	dispatch(authorActions.addAuthorRelatedId( this.state.id, addedElement ) )      
		    } else {
		      	let removedElement = difference(currentAuthorRelated, optionsValue)[0]
		      	console.log(removedElement,"removedElement")

				dispatch(authorActions.deleteAuthorRelatedId( this.state.id, removedElement ) ) 		     
		    }
		}
  	}
	loadOptionsAuthorRelated = (inputValue, callback) => {
   		authorService.loadSuggest( inputValue ).then( (list) => {
   			callback( list.rows.map( (aut) =>{
   				return ({ value: aut.id, label: aut.label})
   			}))
	    }) 		
	}

	handleProfessionChange = (selectedOption) => {
		const {dispatch} = this.props

		if( selectedOption ){
			let currentProfession = []
			if( this.state.author.professionList){
				currentProfession = this.state.author.professionList
					.filter( p => p.id )
					.map( p => p.id )				
			}
			const optionsValue = selectedOption.map(({ value }) => value)

		    if( currentProfession && currentProfession.length < selectedOption.length) {		    	
		      	const addedElement = difference(optionsValue,currentProfession)[0]		      
		      	dispatch(authorActions.addProfesionId( this.state.id, addedElement ) )      
		    } else {
		      	let removedElement = difference(currentProfession, optionsValue)[0]
				dispatch(authorActions.deleteProfesionId( this.state.id, removedElement ) ) 		     
		    }

			let newState = update( this.state, {
				author: {
					profession: {$push: selectedOption}
				}
			});
			this.setState( newState );
		}
  	}

	loadOptionsProfession = (inputValue, callback) => {
   		tagService.loadSuggestByType( "profesion", inputValue ).then( (list) => {
   			callback( list.rows.map( (tag) =>{
   				return ({ value: tag.id, label: tag.label})
   			}))
	    }) 		
	}	

	handleSportChange = (selectedOption) => {
		const {dispatch} = this.props

		if( selectedOption ){
			let currentSport = []
			if( this.state.author.sportList){
				currentSport = this.state.author.sportList
					.filter( p => p.id )
					.map( p => p.id )
			}
			const optionsValue = selectedOption.map(({ value }) => value)

		    if( currentSport && currentSport.length < selectedOption.length) {		    	
		      	const addedElement = difference(optionsValue,currentSport)[0]		      
		      	console.log(addedElement,"addedElement")
		      	dispatch(authorActions.addSportId( this.state.id, addedElement ) )      
		    } else {
		      	let removedElement = difference(currentSport, optionsValue)[0]
		      	console.log(removedElement,"removedElement")

				dispatch(authorActions.deleteSportId( this.state.id, removedElement ) ) 		     
		    }
		}
  	}
	
	loadOptionsSport = (inputValue, callback) => {
   		tagService.loadSuggestByType( "sport", inputValue ).then( (list) => {
   			callback( list.rows.map( (tag) =>{
   				return ({ value: tag.id, label: tag.label})
   			}))
	    }) 		
	}	
	
  	handleGroupChange = (selectedOption) => {
		const {dispatch} = this.props

		if( selectedOption ){
			let currentGroup = []
			if( this.state.author.groupList){
				currentGroup = this.state.author.groupList
					.filter( p => p.id )
					.map( p => p.id )
			}
			const optionsValue = selectedOption.map(({ value }) => value)

		    if( currentGroup && currentGroup.length < selectedOption.length) {		    	
		      	const addedElement = difference(optionsValue,currentGroup)[0]		      
		      	console.log(addedElement,"addedElement")
		      	dispatch(authorActions.addGroupId( this.state.id, addedElement ) )      
		    } else {
		      	let removedElement = difference(currentGroup, optionsValue)[0]
		      	console.log(removedElement,"removedElement")

				dispatch(authorActions.deleteGroupId( this.state.id, removedElement ) ) 		     
		    }
		}
  	}
	loadOptionsGroup = (inputValue, callback) => {
   		tagService.loadSuggestByType( "group", inputValue ).then( (list) => {
   			callback( list.rows.map( (tag) =>{
   				return ({ value: tag.id, label: tag.label})
   			}))
	    }) 		
	}	


	onChangeAuthorUpload = e => {
		const {dispatch} = this.props

    	const files = Array.from(e.target.files)

    	let oData = {
    		files: files,
    		authorId: this.state.id
    	}
	    dispatch( authorActions.uploadPhoto( oData ) )    	
  	}

  	handleOpenDialogPhotoEditor = () => {
        const newState = update(this.state, { 
            openDialogPhotoEditor: {$set: true},
        })
        this.setState(newState)
    }

    handleCloseDialogPhotoEditor = () => {
        const newState = update(this.state, { openDialogPhotoEditor: {$set: false} } )
        this.setState(newState) 
    }

    handlePhotoEditorSave = (oImageCropped,oImageOriginal) => {
		const {dispatch} = this.props

		Resizer.imageFileResizer(
    		oImageCropped, //is the file of the new image that can now be uploaded...
    		300, // is the maxWidth of the  new image
    		300, // is the maxHeight of the  new image
    		'JPEG', // is the compressFormat of the  new image
    		'100', // is the quality of the  new image
    		0, // is the rotation of the  new image
    		oCropped => {
    			dispatch( authorActions.uploadPhotoBlob( this.state.id, oCropped, oImageOriginal ) )    	
            },// is the callBack function of the new image URI
    		'blob'// is the output type of the new image
    	);

	    this.handleCloseDialogPhotoEditor()
	    
    	return true
    }

	render(){
		const {author,
			isAuthorUploading,
			openDialogPhotoEditor,countryList} = this.state


		let optionsGender = Object.keys(authorGender).map( (i) => {
			let g = authorGender[i]
			if( g.value === author.sexo ) author.sexo_name = g.label
   			return {value: g.value, label: g.label}
   		})

   		let optionsCountry = Object.keys(countryList).map( (i) => {
			let c = countryList[i]
			//if( c.id === author.country_id ) author.sexo_name = g.label
   			return {value: c.id, label: c.name}
   		})
		return  <AuthorEdit 
			author={author}

			handleChange={this.handleChange}
			handleBlur={this.handleBlur}

			optionsGender={optionsGender}
			handleGenderChange={this.handleGenderChange}	

			optionsCountry={optionsCountry}
			loadOptionsCountry={this.loadOptionsCountry}
			handleCountryChange={this.handleCountryChange}	

			handleProfessionChange={this.handleProfessionChange}
			loadOptionsProfession={this.loadOptionsProfession}

			handleSportChange={this.handleSportChange}
			loadOptionsSport={this.loadOptionsSport}

			handleGroupChange={this.handleGroupChange}
			loadOptionsGroup={this.loadOptionsGroup}

			handleAuthorRelatedChange={this.handleAuthorRelatedChange}
			loadOptionsAuthorRelated={this.loadOptionsAuthorRelated}

			isAuthorUploading={isAuthorUploading}
			onChangeAuthorUpload={this.onChangeAuthorUpload}

			openDialogPhotoEditor={openDialogPhotoEditor}
			handleOpenDialogPhotoEditor={this.handleOpenDialogPhotoEditor}
			handleCloseDialogPhotoEditor={this.handleCloseDialogPhotoEditor}

			handlePhotoEditorSave={this.handlePhotoEditorSave}
		/>
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeAuthor: state.author, 
		storeAuth: state.auth,
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorEditContainer)
