import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';

import PhotoEditorContainer from '../../../photoEditorContainer/PhotoEditorContainer'

import Button from '@material-ui/core/Button';


import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import Select from 'react-select'
import AsyncSelect from 'react-select/async';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import CircularProgress from '@material-ui/core/CircularProgress';

import PhotoIcon from '@material-ui/icons/Photo';

import Typography from '@material-ui/core/Typography';

var moment = require("moment");

const styles = theme => ({
	errorMsg: {
    	padding: theme.spacing(3, 2),
    	margin: theme.spacing(3, 2),
    	backgroundColor: "Red"
  	},
  	formRow: {
  		marginBottom: theme.spacing(2)
  	},
  	asyncSelect: {
		margin: theme.spacing(1),
		marginTop: theme.spacing(2),
		width: "100%"
  	},
  	textField: {
  		margin: theme.spacing(1),
  	},
  	subTitle:{
  		fontSize: "18px",
  		marginBottom: theme.spacing(2)
  	},
  	buttonPhoto:{
  		position: "absolute",
  		top: "5px",
  		right: "5px"
  	},
  	photoContainer:{
  		position: "relative"
  	}

});

const AuthorEdit = (props) => {
	const {author,authorImg,
		handleChange,handleBlur,

		handleCountryChange,loadOptionsCountry,
		handleAuthorRelatedChange,loadOptionsAuthorRelated,
		handleProfessionChange,handleProfessionInputChange,loadOptionsProfession,
		handleSportChange,loadOptionsSport,
		handleGroupChange,loadOptionsGroup,

		onInputCountryChange,optionsCountry,

		handleGenderChange,optionsGender,

		openDialogPhotoEditor, handleOpenDialogPhotoEditor,handleCloseDialogPhotoEditor,
		isAuthorUploading,
		handlePhotoEditorSave,

		classes} = props

	var sError = ""
	if( props.errorMsg){
		sError = <Paper className={classes.errorMsg}>
			<strong>Error: </strong> {props.errorMsg}
		</Paper>
	}

	let sPhoto = ""
	if(isAuthorUploading){
		sPhoto = <CircularProgress />
	}else{
		if( author.aut_url ){
			sPhoto = <img src={author.author_img} 
				alt="Foto del Autor" style={{width: "100%"}}/>
		}
	}

	return <Grid container spacing={2}>	 	{authorImg}
        	<Grid container>
        		<Grid container className={classes.formRow}>
	        		<Grid item xs={3} className={classes.photoContainer}>
	        			{sPhoto}
	        			<Button className={classes.buttonPhoto} 
	        				variant="contained" color="primary"
	        				onClick={handleOpenDialogPhotoEditor}>
	        				<PhotoIcon /></Button>
	        		</Grid>
	        		<Grid item xs={9} >
	        			<Grid container spacing={2} className={classes.formRow}>
	        				{sError}
	        
	        				<Grid item xs={6} >				
		        				<TextField
									label="Nombre" placeholder="Nombre" 
							    	name="aut_nombre"						    	
								    onChange={handleChange}  onBlur={handleBlur} 
								    className={classes.textField}
									fullWidth
								    value={author.aut_nombre} />
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="Url"
							    	name="aut_url"
							    	placeholder="Url" 
								    onChange={handleChange} 
								    onBlur={handleBlur} 
								    className={classes.textField} fullWidth
								    value={author.aut_url} />
							</Grid>
	        			</Grid>
	        			<Grid container spacing={2}>
	        				<Grid item xs={3}>
		        				<Select
									placeholder="País"
					          		cacheOptions
					          		options={optionsCountry}
					          		defaultOptions
					          		onChange={handleCountryChange}
					          		className={classes.asyncSelect} fullWidth
								    defaultValue={{label: author.country_name, value: author.country_id}} 					          		
					        	/>
							</Grid>
							<Grid item xs={3}>
	        					<Select
				          			name="sexo"         			
				          			menuPortalTarget={document.body}
				        			styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
									placeholder="Sexo"					
					          		options={optionsGender}
					          		className={classes.asyncSelect} fullWidth
					          		defaultValue={{label: author.sexo_name, value:author.sexo}} 
									onChange={handleGenderChange}
					        	/>
	        				</Grid>

							<Grid item xs={3}>
								<TextField
									type="date"
									label="Nacimiento"
							    	name="born"
							    	placeholder="Nacimiento" 
								    onChange={handleChange} onBlur={handleBlur} 
								    InputLabelProps={{
			          					shrink: true,
			        				}}
			        				className={classes.textField} fullWidth
								    value={moment(author.born).format("YYYY-MM-DD")} />						
							</Grid>

							<Grid item xs={3}>
								<TextField
									type="date"
									label="Muerte" placeholder="Muerte"
							    	name="die"						    	 
								    onChange={handleChange} onBlur={handleBlur} 
								    InputLabelProps={{
			          					shrink: true,
			        				}}
			        				className={classes.textField} fullWidth
								    value={moment(author.die).format("YYYY-MM-DD")} />
							</Grid>
	        			</Grid>
	        		</Grid>
	        	</Grid>
        	</Grid>

        	<Grid item xs={12} className={classes.formRow}>
				<TextField
					label="Descripción"	placeholder="Descripción" 
			    	name="description_short"
			    	multiline
				    onChange={handleChange} onBlur={handleBlur} 
				    className={classes.textField} fullWidth
				    value={author.description_short} />
        	</Grid>
        	
        	<Grid container spacing={2} className={classes.formRow}>
        		<Grid item xs={4}>
        			<InputLabel htmlFor="select-profession">Profesión</InputLabel>
					<AsyncSelect
						id="select-profession"
						menuPortalTarget={document.body}
        				styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
						placeholder="Profesión"	
		          		loadOptions={loadOptionsProfession}		          		
		          		onChange={handleProfessionChange}
		          		defaultValue={!author.professionList ||  author.professionList.map((p) => ({ value: p.id, label: p.name}))}
		          		defaultOptions
		          		isMulti
		        	/>
        		</Grid>
        		<Grid item xs={4}>
        			<InputLabel htmlFor="select-sport">Deportes</InputLabel>
					<AsyncSelect
						id="select-sport"
						menuPortalTarget={document.body}
        				styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
						placeholder="Deportes"	
		          		loadOptions={loadOptionsSport}		          		
		          		onChange={handleSportChange}
		          		defaultValue={!author.sportList ||  author.sportList.map((p) => ({ value: p.id, label: p.name}))}
		          		defaultOptions
		          		isMulti
		        	/>
        		</Grid>
        		<Grid item xs={4}>
        			<InputLabel htmlFor="select-group">Grupos</InputLabel>
        			<AsyncSelect
        				id="select-group"
						menuPortalTarget={document.body}
        				styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
						placeholder="Grupos"	
		          		loadOptions={loadOptionsGroup}		          		
		          		onChange={handleGroupChange}
		          		defaultValue={!author.groupList || author.groupList.map((p) => ({ value: p.id, label: p.name}))}
		          		defaultOptions
		          		isMulti
		        	/>
        		</Grid>
        	</Grid>

        	<Grid container spacing={2} className={classes.formRow}>        		
        		<Grid item xs={12}>
        			<InputLabel htmlFor="select-author-related">Autores Relacionados</InputLabel>
        			<AsyncSelect
        				id="select-author-related"
						menuPortalTarget={document.body}
        				styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
						placeholder="Autores Relacionados"	
		          		loadOptions={loadOptionsAuthorRelated}		          		
		          		onChange={handleAuthorRelatedChange}
		          		defaultValue={!author.relatedList || author.relatedList.map((p) => ({ value: p.aut_id, label: p.aut_nombre}))}
		          		defaultOptions
		          		isMulti
		        	/>
        		</Grid>
        	</Grid>
        	
        	<Grid item xs={12}>
        		<Typography component="h2" color="textSecondary" className={classes.subTitle} >Meta</Typography>
        	</Grid>

        	<Grid item xs={12} className={classes.formRow}>
				<TextField
					label="Meta Título"	placeholder="Meta Título" 
			    	name="aut_metatitle"
				    onChange={handleChange} onBlur={handleBlur} 
				    className={classes.textField} fullWidth
				    value={author.aut_metatitle} />
        	</Grid>
        	<Grid item xs={12} className={classes.formRow}>
				<TextField
					label="Meta Descripción"	placeholder="Meta Descripción" 
			    	name="aut_metadesc"
			    	multiline
				    onChange={handleChange} onBlur={handleBlur} 
				    className={classes.textField} fullWidth
				    value={author.aut_metadesc} />
        	</Grid>
        	<Dialog open={openDialogPhotoEditor} 
         		 onClose={handleCloseDialogPhotoEditor} 
          		fullWidth={true}
          			maxWidth="md">
          		<DialogContent dividers>
	        		<PhotoEditorContainer 
	        			handleSave={handlePhotoEditorSave}
	        			imageSrc={author.author_img_orig} 
	        			/>
          		</DialogContent>
      		</Dialog>
        </Grid>			
}

export default withStyles(styles)(AuthorEdit);