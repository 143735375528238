
import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import TagRow from './tagRow/TagRow';

class TagContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			tag: props.storeTag.list.find(tag => tag.id === props.id),
			openDialogEdit: false,
			errorMsg: ""
		}
	}

	componentDidUpdate(prevProps) {
		const {id,storeTag} = this.props
		if( !storeTag || !storeTag.list) return false

		if( prevProps.storeTag.isSaving && !storeTag.isSaving && prevProps.storeTag.isSavingId === id ){			
			const oTag = storeTag.list.find(tag => tag.id === prevProps.storeTag.isSavingId)
			if( !oTag ) return false
			let newState = update( this.state, {
				tag:  {$set: oTag},
			})

			this.setState( newState );
		}
			
	}
    
	render(){
		const {tag} = this.state
		const {handleOpenDialogEdit} = this.props

		if ( !tag ) return false


        let view = ""
        const viewType = ( this.props.view )?this.props.view:""
        switch(viewType){
        	case "row":
        	default:
				view =  <TagRow tag={tag} handleOpenDialogEdit={handleOpenDialogEdit} />
				break;	
        }


		return view
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeTag: state.tag,
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TagContainer);
