import React, {Component} from 'react';
import { connect } from 'react-redux'
import Auth from '../../modules/Auth';

import Menu from './menu/Menu'

class MenuContainer extends Component { 

	constructor(props) {
		super(props);
		
		
		
		this.state = {
			projectId: ""	
		}
	}

	render(){
		const siteName = Auth.getSiteName()
		
		return  <Menu siteName={siteName}/>
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {
			project: state.project, 
  	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
