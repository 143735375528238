import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { authorService, tagService } from '../../../services';
import { quoteActions } from '../../../redux/actions'

import QuoteEdit from './quoteEdit/QuoteEdit'

class QuoteEditContainer extends Component { 
	
	constructor(props) {
		super(props)

		this.state = {
			id: props.quote.frs_id,
			quote: props.quote,
			author_name: props.author.aut_nombre,
			errorMsg: ""
		}
	}	

	handleChange = (event) => {
		const target = event.target;
	   const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		let newState = update( this.state, {
			quote: { [name]: {$set: value} },
		});

		this.setState( newState );
	}
	
	handleBlur = ( event ) => {
		const {dispatch} = this.props

  		const target = event.target;
	   const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		
      dispatch(quoteActions.saveField( this.state.id, name, value )) 	    
   }

   handleChangeBlur = (event) => {
		const {dispatch} = this.props   	

   	const target = event.target;
	   const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		let newState = update( this.state, {
			quote: { [name]: {$set: value} },
		})

		this.setState( newState )

		dispatch(quoteActions.saveField( this.state.id, name, value )) 	    

   }

	handleAutorIdChange = (selectedOption) => {
		const {dispatch} = this.props

		if( selectedOption ){
			dispatch(quoteActions.saveField( this.state.id, "autor_id", selectedOption.value )) 	    
			let newState = update( this.state, {
				quote: {
					autor_id: {$set: selectedOption.value},
				},
				author_name: {$set: selectedOption.label}
			});
			this.setState( newState );

		}
  }
  
  handleTagListChange = (selectedOption) => {
		if( selectedOption ){
			let newState = update( this.state, {
				quote: {
					tagList: {$set: selectedOption.value},
				},
			});
			this.setState( newState );
		}
  	}

	loadOptionsAuthor = (inputValue, callback) => {
   		authorService.loadSuggest( inputValue ).then( (list) => {
   			callback( list.rows.map( (aut) =>{
   					return ({ value: aut.id, label: aut.label})
   			}))
	    }) 		
	}

	loadOptionsTag = (inputValue, callback) => {
   		tagService.loadSuggest( inputValue ).then( (list) => {
   			callback( list.rows.map( (aut) =>{
   					return ({ value: aut.id, label: aut.label})
   			}))
	    }) 		
	}

	render(){
		const {quote,author_name,errorMsg} = this.state
		return  <QuoteEdit quote={quote} author_name={author_name}
			errorMsg={errorMsg}
			loadOptionsAuthor={this.loadOptionsAuthor}
			loadOptionsTag={this.loadOptionsTag}
			handleChange={this.handleChange}
			handleBlur={this.handleBlur}
			handleChangeBlur={this.handleChangeBlur}
			handleAutorIdChange={this.handleAutorIdChange}
			handleTagListChange={this.handleTagListChange} />
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeTopic: state.topic, 
		storeAuth: state.auth,
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteEditContainer)
