import React, {Component} from 'react';

import {Card,CardMedia,CardContent,CardActions} from '@material-ui/core/';


import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	card: {
  		display: 'flex',
  		marginBottom: theme.spacing(2),
  		justifyContent: 'space-between'
  	},  	
	cover: {
   		height: '150px',
   		width: '150px',
   		flexShrink: 0,
  	},  
  	details: {
		display: 'flex',
		flexDirection: 'column',
  	},

  	content: {
  		flex: '1 0 auto'
  	},	
  	action: {
  		display: 'flex'
  	},
  	actionLeft: {
  		flexGrow: 1
  	},
  	avatar: {
  		width: '30px',
  		height: '30px'
  	},
});

class Quote extends Component {    	
	
	render() {
		const { quote, author,classes,actions,handleOpenDialogEdit} = this.props
		if( !quote )return false


		return <Card className={classes.card}>
			<CardMedia className={classes.cover}
	            image={"https://frasede.com/assets/content/autores/"+author.aut_url +".jpg"}
	            title={author.aut_nombre} />
			<div className={classes.details}>		
				<CardContent className={classes.content}>
					<Typography component="h1">{quote.frs_frase}</Typography>
          			<Typography component="h2" color="textSecondary">
            			{author.aut_nombre}
          			</Typography>
			   </CardContent>  
         <CardActions className={classes.action}>
            <Button size="small" color="primary" onClick={handleOpenDialogEdit}>
              Editar
            </Button>
			   	 {(actions)?actions.map( (a) => <Button key={a.label} size="small" color="primary" onClick={(e) => a.handleClick(e, quote.frs_id)}>
	       			{a.label}
	     			</Button> ):""}
 			    </CardActions>           	
			</div>
		</Card>
	}
}

export default withStyles(styles)(Quote);