import { quoteService } from '../../services';
import { alertActions } from './';


export const quoteActions = {
    loadCurrent,
    loadById,
    loadByIds,
    loadByPag,
    loadByAuthor,
    add,
    saveField,
    saveTopicId,
    deleteTopicId,
    _delete  
}

export const QUOTE_LOAD_CURRENT_REQUEST = 'QUOTE_LOAD_CURRENT_REQUEST'
export const QUOTE_LOAD_CURRENT_SUCCESS = 'QUOTE_LOAD_CURRENT_SUCCESS'
export const QUOTE_LOAD_CURRENT_FAILURE = 'QUOTE_LOAD_CURRENT_FAILURE'

function loadCurrent( rCurrent ) {
    return dispatch => {
        dispatch(request( rCurrent ));  
        if( rCurrent ){
            dispatch(success(rCurrent));
        }else{
            dispatch(failure());
        }
    };

    function request( ) { 
        return { type: QUOTE_LOAD_CURRENT_REQUEST } 
    }
    function success(rows) { return { type: QUOTE_LOAD_CURRENT_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_LOAD_CURRENT_FAILURE, error } }
}


export const QUOTE_LOAD_ID_REQUEST = 'QUOTE_LOAD_ID_REQUEST'
export const QUOTE_LOAD_ID_SUCCESS = 'QUOTE_LOAD_ID_SUCCESS'
export const QUOTE_LOAD_ID_FAILURE = 'QUOTE_LOAD_ID_FAILURE'

function loadById( id ) {
    return dispatch => {
        dispatch(request( ));   
        quoteService.loadById( id )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: QUOTE_LOAD_ID_REQUEST } 
    }
    function success(rows) { return { type: QUOTE_LOAD_ID_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_LOAD_ID_FAILURE, error } }
}

export const QUOTE_LOAD_IDS_REQUEST = 'QUOTE_LOAD_IDS_REQUEST'
export const QUOTE_LOAD_IDS_SUCCESS = 'QUOTE_LOAD_IDS_SUCCESS'
export const QUOTE_LOAD_IDS_FAILURE = 'QUOTE_LOAD_IDS_FAILURE'

function loadByIds( ids ) {
    return dispatch => {
        dispatch(request( ));   
        quoteService.loadByIds( ids )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: QUOTE_LOAD_IDS_REQUEST } 
    }
    function success(rows) { return { type: QUOTE_LOAD_IDS_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_LOAD_IDS_FAILURE, error } }
}

export const QUOTE_LOAD_PAG_REQUEST = 'QUOTE_LOAD_PAG_REQUEST'
export const QUOTE_LOAD_PAG_SUCCESS = 'QUOTE_LOAD_PAG_SUCCESS'
export const QUOTE_LOAD_PAG_FAILURE = 'QUOTE_LOAD_PAG_FAILURE'

function loadByPag( iPag ) {
    return dispatch => {
        dispatch(request( ));   
        quoteService.loadByPag( iPag )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: QUOTE_LOAD_PAG_REQUEST } 
    }
    function success(rows) { return { type: QUOTE_LOAD_PAG_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_LOAD_PAG_FAILURE, error } }
}

export const QUOTE_LOAD_AUTHOR_REQUEST = 'QUOTE_LOAD_AUTHOR_REQUEST'
export const QUOTE_LOAD_AUTHOR_SUCCESS = 'QUOTE_LOAD_AUTHOR_SUCCESS'
export const QUOTE_LOAD_AUTHOR_FAILURE = 'QUOTE_LOAD_AUTHOR_FAILURE'

function loadByAuthor( sAuthor ) {
    return dispatch => {
        dispatch(request( sAuthor ));   
        quoteService.loadByAuthor( sAuthor )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: QUOTE_LOAD_AUTHOR_REQUEST } 
    }
    function success(rows) { return { type: QUOTE_LOAD_AUTHOR_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_LOAD_AUTHOR_FAILURE, error } }
}

export const QUOTE_ADD_REQUEST = 'QUOTE_ADD_REQUEST'
export const QUOTE_ADD_SUCCESS = 'QUOTE_ADD_SUCCESS'
export const QUOTE_ADD_FAILURE = 'QUOTE_ADD_FAILURE'

function add( userId, quote ) {
    return dispatch => {
        dispatch(request( userId, quote ));   
        quoteService.add(quote)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: QUOTE_ADD_REQUEST, userId, quote } 
    }
    function success(rows) { return { type: QUOTE_ADD_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_ADD_FAILURE, error } }
}

export const QUOTE_SAVE_FIELD_REQUEST = 'QUOTE_SAVE_FIELD_REQUEST'
export const QUOTE_SAVE_FIELD_SUCCESS = 'QUOTE_SAVE_FIELD_SUCCESS'
export const QUOTE_SAVE_FIELD_FAILURE = 'QUOTE_SAVE_FIELD_FAILURE'

function saveField( id, name, value ) {
    return dispatch => {
        dispatch(request( id, name, value ));   
        quoteService.saveField(id, name, value)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: QUOTE_SAVE_FIELD_REQUEST, id, name, value } 
    }
    function success(rows) { return { type: QUOTE_SAVE_FIELD_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_SAVE_FIELD_FAILURE, error } }
}

export const QUOTE_SAVE_TOPIC_ID_REQUEST = 'QUOTE_SAVE_TOPIC_ID_REQUEST'
export const QUOTE_SAVE_TOPIC_ID_SUCCESS = 'QUOTE_SAVE_TOPIC_ID_SUCCESS'
export const QUOTE_SAVE_TOPIC_ID_FAILURE = 'QUOTE_SAVE_TOPIC_ID_FAILURE'

function saveTopicId( quoteId, topicId ) {
    return dispatch => {
        dispatch(request( quoteId, topicId ));   
        quoteService.saveTopicId(quoteId, topicId )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: QUOTE_SAVE_TOPIC_ID_REQUEST, quoteId, topicId } 
    }
    function success(rows) { return { type: QUOTE_SAVE_TOPIC_ID_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_SAVE_TOPIC_ID_FAILURE, error } }
}

export const QUOTE_DELETE_TOPIC_ID_REQUEST = 'QUOTE_DELETE_TOPIC_ID_REQUEST'
export const QUOTE_DELETE_TOPIC_ID_SUCCESS = 'QUOTE_DELETE_TOPIC_ID_SUCCESS'
export const QUOTE_DELETE_TOPIC_ID_FAILURE = 'QUOTE_DELETE_TOPIC_ID_FAILURE'

function deleteTopicId( quoteId, topicId ) {
    return dispatch => {
        dispatch(request( quoteId, topicId ));   
        quoteService.deleteTopicId(quoteId, topicId )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: QUOTE_DELETE_TOPIC_ID_REQUEST, quoteId, topicId } 
    }
    function success(rows) { return { type: QUOTE_DELETE_TOPIC_ID_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_DELETE_TOPIC_ID_FAILURE, error } }
}

export const QUOTE_DELETE_REQUEST = 'QUOTE_DELETE_REQUEST'
export const QUOTE_DELETE_SUCCESS = 'QUOTE_DELETE_SUCCESS'
export const QUOTE_DELETE_FAILURE = 'QUOTE_DELETE_FAILURE'

function _delete( id ) { 
    return dispatch => {
        dispatch(request( id ));   
        quoteService._delete( id )
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(id) { 
        return { type: QUOTE_DELETE_REQUEST, id: id } 
    }
    function success(rows) { return { type: QUOTE_DELETE_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_DELETE_FAILURE, error } }
}