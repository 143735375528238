import {fetchApi} from '../helpers';

export const quoteService = {
	loadById,
    loadByIds,
	loadByPag,
    loadByAuthorId,
    loadByTopicId,
    loadFilterWord,
    loadBetweenDates,
    add,
    saveField,
    saveTopicId,
    deleteTopicId,
    _delete
};

function loadById( id ) {
    const res  = fetchApi('api/quoteLoadById', {
        'id': id
    }) 

    return res
}

function loadByIds( ids ) {
    const res  = fetchApi('api/quoteLoadByIds', {
        'ids': ids
    }) 

    return res
}

function loadByPag( pag ) {
    const res  = fetchApi('api/quoteLoadByPag', {
        'pag': pag
    }) 

    return res
}

function loadByAuthorId( authorId ) {
    const res  = fetchApi('api/quoteLoadByAuthorId', {
        'authorId': authorId
    }) 

    return res
}

function loadByTopicId( topicId ) {
    const res  = fetchApi('api/quoteLoadByTopicId', {
        'topicId': topicId
    }) 

    return res
}

function loadBetweenDates( sIni, sEnd ) {
    const res  = fetchApi('api/quoteLoadBetweenDates', {
        'ini': sIni,
        'end': sEnd
    }) 

    return res
}

function loadFilterWord( word ) {
    const res  = fetchApi('api/quoteLoadFilterWord', {
        'word': word
    }) 

    return res
}

function add( quote ) {
    const res  = fetchApi('api/quoteAdd', {
        quote: quote,
    }) 

    return res
}

function saveField(id,name,value) {
    const res  = fetchApi('api/quoteSaveField', {
        id: id,
        name: name,
        value: value,
    }) 

    return res
}

function saveTopicId( quoteId, topicId ){
    const res  = fetchApi('api/quoteSaveTopicId', {
        quoteId: quoteId,
        topicId: topicId,
    }) 

    return res
}

function deleteTopicId( quoteId, topicId ){
    const res  = fetchApi('api/quoteDeleteTopicId', {
        quoteId: quoteId,
        topicId: topicId,
    }) 

    return res
}

function _delete(id) {
    const res  = fetchApi('api/quoteDelete', {
        id: id,
    }) 

    return res
}