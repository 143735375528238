import React, {Component} from 'react';

import { connect } from 'react-redux'

import TopicListContainer from '../topic/topicListContainer/TopicListContainer';
import TopicAddContainer from '../topic/topicAddContainer/TopicAddContainer';

import QuoteFilterContainer from '../quote/quoteFilterContainer/QuoteFilterContainer';
import TopicContainer from '../topic/topicContainer/TopicContainer';

import { isEmpty } from '../../functions';

import { DragDropContext } from 'react-beautiful-dnd';

import { quoteService, topicService } from '../../services';
import { quoteActions, authorActions } from '../../redux/actions';

import Page3Col from '../Page3Col';

import {DOMAIN_TITLE} from '../../constants';

import update from 'immutability-helper';


class TopicPage extends Component {
	constructor(props) {
		super(props)

		this.state = {
            topicId: '',
            topic: '',
			topicQuote: [],
        	filterQuote: [],
            filterWord: "",
		}
    }

    setHeaders() {
        document.title = DOMAIN_TITLE + ": Temas"
    }

    getTopic( topicId ){
        if( !topicId ) return false

        this.setState({
            topicId: topicId
        })

        topicService.loadById( topicId ).then( (listTopic) => {
            quoteService.loadByTopicId( topicId ).then( (listQuote) => {
                this.setState({
                    topic: listTopic.rows,
                    topicQuote: listQuote.rows
                })
                this.loadQuote( listQuote.rows )
            })
        })
        
        

    }
    componentWillMount(){
        
        this.setHeaders()
        
        const topicId = this.props.match.params.topicId
        if( !topicId ) return false
        
        this.getTopic(topicId)
    }
    
    componentWillReceiveProps( nextProps ){
        const topicId = nextProps.match.params.topicId
        if( this.state.topicId !== topicId ){
            this.getTopic( topicId )
        }
    }

	/**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: 'topicQuote',
        droppable2: 'filterQuote'
    };

    getList = id => this.state[this.id2List[id]];

    handleDeleteClick = (element, quoteId) => {
        const { dispatch } = this.props

        let topicQuote = []
        this.state.topicQuote.map( q => {
            if( q.id !== quoteId ){
                topicQuote.push(q)
            }

            return true
        })
        dispatch( quoteActions.deleteTopicId( quoteId, this.state.topicId ) )

        this.setState({
            topicQuote: topicQuote
        })
    }
    
    handleDeleteCallBack = () => {
        this.props.history.push('/topic/')
    }

    move = (source, destination, droppableSource, droppableDestination) => {
        const { dispatch } = this.props
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        let bElemExists = false
        destClone.map(q => {
            if( q.id === removed.id ){
                bElemExists = true
            }

            return true
        })

        if( !bElemExists ){
            destClone.splice(droppableDestination.index, 0, removed);
            dispatch( quoteActions.saveTopicId( removed.id, this.state.topicId ) )
        }

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    }

	onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) return        

        if (source.droppableId === destination.droppableId) {
            /*const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let state = { items };

            if (source.droppableId === 'droppable2') {
                state = { selected: items };
            }

            this.setState(state);*/
        } else {
            const result = this.move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            this.setState({
                topicQuote: result.droppable,
                filterQuote: result.droppable2
            });
        }
    }

    loadQuote = ( quoteList ) => {
        const {storeQuote,dispatch} = this.props
        let quoteIdList = []
        let authorIdList = []

        quoteList.map( q => {
            if( !storeQuote || !storeQuote.list || !storeQuote.list[q.id] ){
                quoteIdList.push(q.id)
                authorIdList.push(q.autor_id)
            }

            return true
        })
        if( !isEmpty(quoteIdList) ){
            dispatch( quoteActions.loadByIds( quoteIdList ) )
        }
        if( !isEmpty(authorIdList) ){
            dispatch( authorActions.loadByIds( authorIdList ) )
        }

        return true
    }

    getFreeQuotes = (quote) => {  
        let bFree = true
        this.state.topicQuote.map( quoteTopic => {
            if(quote.id === quoteTopic.id ){
                console.log("EXIST",quote, quoteTopic)
                bFree = false
            }
            return true
        })        
        return bFree
    }

    handleAuthorSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        const authorId  = suggestion.id
        if( !authorId ) return false
       


        quoteService.loadByAuthorId( authorId ).then( (list) =>{
            const filterQuote = list.rows.filter( this.getFreeQuotes )

            this.setState({
                filterQuote: filterQuote
            })
            this.loadQuote( filterQuote )
        })
    }

    handleFilterWordChange = ( event ) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let newState = update( this.state, {
            filterWord: {$set: value},
        });

        this.setState( newState );
    }

    handleFilterWordBlur = ( event ) => {

        quoteService.loadFilterWord( this.state.filterWord ).then( (list) =>{
            this.setState({
                filterQuote: list.rows
            })
            this.loadQuote( list.rows )
        })
    }

	render(){
		const {topicId,topic,filterQuote,topicQuote,filterWord} = this.state
		const left = <div>
			<TopicAddContainer />
			<TopicListContainer 
                id={topicId} />
		</div>

		const right = <QuoteFilterContainer 
            move={this.move} 
            onDragEnd={this.onDragEnd} 
            items={filterQuote}
            handleAuthorSelected={this.handleAuthorSelected} 
            handleFilterWordChange={this.handleFilterWordChange}
            handleFilterWordBlur={this.handleFilterWordBlur}            
            filterWord={filterWord} />

		const main = <TopicContainer 
            items={topicQuote}
            topic={topic}
            id={topicId}
            handleDeleteClick={this.handleDeleteClick} 
            handleDeleteCallBack={this.handleDeleteCallBack}/>
		
		return <div>
			<DragDropContext onDragEnd={this.onDragEnd}>
                <Page3Col  main={main} right={right} left={left} />
            </DragDropContext>
        </div>
		
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
        storeQuote: state.quote,
        storeTopic: state.topic,
    };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicPage);
