
import React, {Component} from 'react';
import { connect } from 'react-redux'

import QuoteList from './quoteList/QuoteList';

class QuoteListContainer extends Component { 
	render(){
		const {move, items,onDragEnd,
			view,
			reload,
			handleCheckChange,isChecked, } = this.props
		if( !items ) return false

		if( move ){
			return <QuoteList 
				reload={reload}
				move={move}
				onDragEnd={onDragEnd}
				items={items} />
		}else{
			return <QuoteList 
				reload={reload} 
				items={items}
				view={view}
				isChecked={isChecked}
				handleCheckChange={handleCheckChange} />	
		}
	}
}

const mapStateToProps = (state, ownProps) => {
	return {}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteListContainer);
