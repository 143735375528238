import {
	AUTHOR_LOAD_REQUEST,
  AUTHOR_LOAD_SUCCESS,
  AUTHOR_LOAD_FAILURE,
  AUTHOR_LOAD_ID_REQUEST,
  AUTHOR_LOAD_ID_SUCCESS,
  AUTHOR_LOAD_ID_FAILURE,
  AUTHOR_LOAD_IDS_REQUEST,
  AUTHOR_LOAD_IDS_SUCCESS,
  AUTHOR_LOAD_IDS_FAILURE,
  AUTHOR_LOAD_SUGGEST_REQUEST,
  AUTHOR_LOAD_SUGGEST_SUCCESS,
  AUTHOR_LOAD_SUGGEST_FAILURE,
  AUTHOR_ADD_REQUEST,
  AUTHOR_ADD_SUCCESS,
  AUTHOR_ADD_FAILURE,
  AUTHOR_SAVE_FIELD_REQUEST,
  AUTHOR_SAVE_FIELD_SUCCESS,
  AUTHOR_SAVE_FIELD_FAILURE,

  AUTHOR_ADD_PROFESION_REQUEST,
  AUTHOR_ADD_PROFESION_SUCCESS,
  AUTHOR_ADD_PROFESION_FAILURE,
  AUTHOR_DELETE_PROFESION_ID_REQUEST,
  AUTHOR_DELETE_PROFESION_ID_SUCCESS,
  AUTHOR_DELETE_PROFESION_ID_FAILURE,

  AUTHOR_ADD_SPORT_ID_REQUEST,
  AUTHOR_ADD_SPORT_ID_SUCCESS,
  AUTHOR_ADD_SPORT_ID_FAILURE,
  AUTHOR_DELETE_SPORT_ID_REQUEST,
  AUTHOR_DELETE_SPORT_ID_SUCCESS,
  AUTHOR_DELETE_SPORT_ID_FAILURE,

  AUTHOR_ADD_GROUP_ID_REQUEST,
  AUTHOR_ADD_GROUP_ID_SUCCESS,
  AUTHOR_ADD_GROUP_ID_FAILURE,
  AUTHOR_DELETE_GROUP_ID_REQUEST,
  AUTHOR_DELETE_GROUP_ID_SUCCESS,
  AUTHOR_DELETE_GROUP_ID_FAILURE,


  AUTHOR_ADD_AUTHOR_RELATED_ID_REQUEST,
  AUTHOR_ADD_AUTHOR_RELATED_ID_SUCCESS,
  AUTHOR_ADD_AUTHOR_RELATED_ID_FAILURE,
  AUTHOR_DELETE_AUTHOR_RELATED_ID_REQUEST,
  AUTHOR_DELETE_AUTHOR_RELATED_ID_SUCCESS,
  AUTHOR_DELETE_AUTHOR_RELATED_ID_FAILURE,


  AUTHOR_UPLOAD_PHOTO_REQUEST,
  AUTHOR_UPLOAD_PHOTO_SUCCESS,
  AUTHOR_UPLOAD_PHOTO_FAILURE,
  AUTHOR_UPLOAD_PHOTO_BLOB_REQUEST,
  AUTHOR_UPLOAD_PHOTO_BLOB_SUCCESS,
  AUTHOR_UPLOAD_PHOTO_BLOB_FAILURE,
  AUTHOR_DELETE_REQUEST,
  AUTHOR_DELETE_SUCCESS,
  AUTHOR_DELETE_FAILURE,
} from '../actions/author'

import Auth from '../../modules/Auth';

import {DOMAIN_CONTENT} from '../../constants/constants';

import update from 'immutability-helper';

function setAuthorImg( a, setVersion = false ){
	const subSite = Auth.getSubSite()
	const hostname = window && window.location && window.location.hostname;

	if( subSite == "frasesmotivacion" ){
		a.author_img = DOMAIN_CONTENT +"thumb/autores/200/"+ a.aut_url +".jpeg"
		a.author_img_orig = DOMAIN_CONTENT +"autores/"+ a.aut_url +".jpeg"
	}else{
		a.author_img = DOMAIN_CONTENT +"autores/"+ a.aut_url +".jpg"
		a.author_img_orig = DOMAIN_CONTENT +"autores/orig/"+ a.aut_url +".jpg"
	}
	if(setVersion){
		a.author_img += "?v="+ parseInt(Math.random()*100,10)
	}

	return a
}
const author = (state = [], action) => {
	let newState = ""
	let rows = {}
	let iDeletedId = ""
	let stateList = Object.assign( {}, state.list )	
	switch (action.type) {
		case AUTHOR_LOAD_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case AUTHOR_LOAD_SUCCESS:	
			action.rows.map( a => {
				stateList[a.aut_id] =   a
			})
			
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				list: {$set: stateList},				
			});

			return newState 

		case AUTHOR_LOAD_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState
		case AUTHOR_LOAD_ID_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case AUTHOR_LOAD_ID_SUCCESS:			
			stateList[action.rows.aut_id] =   action.rows
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				list: {$set: stateList},				
			});

			return newState 

		case AUTHOR_LOAD_ID_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState
		case AUTHOR_LOAD_IDS_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case AUTHOR_LOAD_IDS_SUCCESS:	
			action.rows.map( (aut) => {
				let a = setAuthorImg(aut)
				stateList[a.aut_id] =   a	
				return true
			})		
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				list: {$set: stateList},				
			});

			return newState 

		case AUTHOR_LOAD_IDS_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case AUTHOR_LOAD_SUGGEST_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case AUTHOR_LOAD_SUGGEST_SUCCESS:			
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				suggest: {$set: action.rows},				
			});

			return newState 

		case AUTHOR_LOAD_SUGGEST_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case AUTHOR_ADD_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case AUTHOR_ADD_SUCCESS:
			rows = Object.assign({},action.rows)
			newState = Object.assign( {}, state )
			if( rows ){
				newState = update( state, {
					isSaving: {$set: false},
					//list:  {$push: [rows]},
				});
			}
			return newState 
		case AUTHOR_ADD_FAILURE:
			return {};


		case AUTHOR_SAVE_FIELD_REQUEST:
		case AUTHOR_UPLOAD_PHOTO_REQUEST:
		case AUTHOR_UPLOAD_PHOTO_BLOB_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},	
//				uploadingListId: {$set: state.uploadingListId || [] },
			})
			if( action.type === "TODO_UPLOAD_PHOTO_REQUEST"){
				newState = update( newState, {
					isUploading: {$set: true},
				})
			}
			return newState 
			
		case AUTHOR_SAVE_FIELD_SUCCESS:
		case AUTHOR_UPLOAD_PHOTO_SUCCESS:
		case AUTHOR_UPLOAD_PHOTO_BLOB_SUCCESS:
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {$apply: function(authorList){
					const authorId = rows.aut_id
					if( action.type === "AUTHOR_UPLOAD_PHOTO_SUCCESS"
						|| action.type === "AUTHOR_UPLOAD_PHOTO_BLOB_SUCCESS"){
						authorList[authorId] = setAuthorImg( rows, true)
					}else{
						authorList[authorId] = setAuthorImg( rows, false)
					}
					return authorList			
				}},
			});
			if( action.type === "AUTHOR_UPLOAD_PHOTO_SUCCESS"){
				newState = update( newState, {
					isUploading: {$set: false}
				})
/*				const index = newState.uploadingListId.indexOf(action.todoId);
				if (index >= 0) {
  					//newState = update(newState, { uploadingListId: { $splice: [[index, 1]] } });
				}*/
			}
			return newState 
		
		case AUTHOR_SAVE_FIELD_FAILURE:
		case AUTHOR_UPLOAD_PHOTO_FAILURE:
		case AUTHOR_UPLOAD_PHOTO_BLOB_FAILURE:		
			return {};

		case AUTHOR_DELETE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case AUTHOR_DELETE_SUCCESS:
			iDeletedId = action.rows
			newState = update( state, {
				isSaving: {$set: false},
				list: {$unset: [iDeletedId]},
			});
			return newState 

		case AUTHOR_DELETE_FAILURE:
			return {};



		case AUTHOR_ADD_PROFESION_REQUEST:		
			newState = update( state, {
				isSaving: {$set: true},	
			})
			if( action.type === "TODO_UPLOAD_PHOTO_REQUEST"){
				newState = update( newState, {
					isUploading: {$set: true},
				})
			}
			return newState 
			
		case AUTHOR_ADD_PROFESION_SUCCESS:		
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {$apply: function(authorList){
					const authorId = rows.author_id
					const tag = rows.tag
					if( !authorList[authorId]['professionList'] ){
						authorList[authorId]['professionList'] = []
					}
					authorList[authorId]['professionList'][tag.id] = tag
					return authorList			
				}},
			});
			return newState 
		
		case AUTHOR_ADD_PROFESION_FAILURE:
			return {};

		case AUTHOR_DELETE_PROFESION_ID_REQUEST:		
			newState = update( state, {
				isSaving: {$set: true},	
			})
			if( action.type === "TODO_UPLOAD_PHOTO_REQUEST"){
				newState = update( newState, {
					isUploading: {$set: true},
				})
			}
			return newState 
			
		case AUTHOR_DELETE_PROFESION_ID_SUCCESS:		
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				//list: {$unset: authorList[rows.author_id][rows.tag_id]},
				list: {$apply: function(authorList){
					const authorId = rows.author_id
					const tagId = rows.tag_id
					if( authorList[authorId]['professionList']){
						const newAuthorList = authorList[authorId]['professionList'].filter(tag => tag.id != tagId )
						return newAuthorList
					}
					return authorList
					
				}},				
			});
			return newState 
		
		case AUTHOR_DELETE_PROFESION_ID_FAILURE:
			return {};




		case AUTHOR_ADD_SPORT_ID_REQUEST:		
			newState = update( state, {
				isSaving: {$set: true},	
			})
	
			return newState 
			
		case AUTHOR_ADD_SPORT_ID_SUCCESS:		
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {$apply: function(authorList){
					const authorId = rows.author_id
					const tag = rows.tag
					if( !authorList[authorId]['sportList'] ){
						authorList[authorId]['sportList'] = []
					}
					authorList[authorId]['sportList'][tag.id] = tag
					return authorList			
				}},
			});
			return newState 
		
		case AUTHOR_ADD_SPORT_ID_FAILURE:
			return {};

		case AUTHOR_DELETE_SPORT_ID_REQUEST:		
			newState = update( state, {
				isSaving: {$set: true},	
			})
			
			return newState 
			
		case AUTHOR_DELETE_SPORT_ID_SUCCESS:		
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {$apply: function(authorList){
					const authorId = rows.author_id
					const tagId = rows.tag_id
					if( authorList[authorId]['sportList']){
						const newAuthorList = authorList[authorId]['sportList'].filter(tag => tag.id != tagId )
						return newAuthorList
					}
					return authorList
					
				}},				
			});
			return newState 
		
		case AUTHOR_DELETE_SPORT_ID_FAILURE:
			return {};


		case AUTHOR_ADD_GROUP_ID_REQUEST:		
			newState = update( state, {
				isSaving: {$set: true},	
			})
	
			return newState 
			
		case AUTHOR_ADD_GROUP_ID_SUCCESS:		
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {$apply: function(authorList){
					const authorId = rows.author_id
					const tag = rows.tag
					if( !authorList[authorId]['groupList'] ){
						authorList[authorId]['groupList'] = []
					}
					authorList[authorId]['groupList'][tag.id] = tag
					return authorList			
				}},
			});
			return newState 
		
		case AUTHOR_ADD_GROUP_ID_FAILURE:
			return {};

		case AUTHOR_DELETE_GROUP_ID_REQUEST:		
			newState = update( state, {
				isSaving: {$set: true},	
			})
			
			return newState 
			
		case AUTHOR_DELETE_GROUP_ID_SUCCESS:		
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {$apply: function(authorList){
					const authorId = rows.author_id
					const tagId = rows.tag_id
					if( authorList[authorId]['groupList']){
						const newAuthorList = authorList[authorId]['groupList'].filter(tag => tag.id != tagId )
						return newAuthorList
					}
					return authorList
					
				}},				
			});
			return newState 
		
		case AUTHOR_DELETE_GROUP_ID_FAILURE:
				return {};


		case AUTHOR_ADD_AUTHOR_RELATED_ID_REQUEST:		
			newState = update( state, {
				isSaving: {$set: true},	
			})
	
			return newState 
			
		case AUTHOR_ADD_AUTHOR_RELATED_ID_SUCCESS:		
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {$apply: function(authorList){
					const authorId = rows.author_id
					const related = rows.related
					if( !authorList[authorId]['relatedList'] ){
						authorList[authorId]['relatedList'] = []
					}
					authorList[authorId]['relatedList'][related.aut_id] = related
					return authorList			
				}},
			});
			return newState 
		
		case AUTHOR_ADD_AUTHOR_RELATED_ID_FAILURE:
			return {};

		case AUTHOR_DELETE_AUTHOR_RELATED_ID_REQUEST:		
			newState = update( state, {
				isSaving: {$set: true},	
			})
			
			return newState 
			
		case AUTHOR_DELETE_AUTHOR_RELATED_ID_SUCCESS:		
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {$apply: function(authorList){
					console.log(rows)
					const authorId = rows.author_id
					const relatedId = rows.related_id
					if( authorList[authorId]['relatedList']){
						const newAuthorList = authorList[authorId]['relatedList'].filter(related => related.aut_id != relatedId )
						return newAuthorList
					}
					return authorList
					
				}},				
			});
			return newState 
		
		case AUTHOR_DELETE_GROUP_ID_FAILURE:
				return {};

		default:
			return state
  	}
}

export default author