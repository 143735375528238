import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles'

import MenuContainer from './menuContainer/MenuContainer';

const styles = theme => ({
	container: {
		height: '100vh',
		overflow: 'hidden',		
	},
	columns: {
		display: "flex"
	},
	colSettings: {

  		paddingRight: theme.spacing(2),
  		paddingLeft: theme.spacing(2),
  		paddingTop: theme.spacing(8),
	},
	left: {
  		overflow: 'auto',
  		overflowX: 'hidden',
  		height: '100vh',
  		width: '20%',
  		backgroundColor: theme.palette.background.default,
  		boxShadow:  theme.shadows[3],  		
  	},
  	main: {
  		height: '100vh',
  		overflow: 'auto',
     	width: '50%',
  	},
  	right: {
  		overflow: 'auto',
  		overflowX: 'hidden',
  		height: '100vh',
  		width: '30%',
  		backgroundColor: theme.palette.background.default,
  		boxShadow: theme.shadows[1],
  	},
});

const Page3Col = (props) => {
	const {classes} = props
	return <div className={classes.container}>
		<MenuContainer />
			
		<div className={classes.columns}>
			<div className={classnames(classes.left,classes.colSettings)}>
				{props.left}
			</div>
			<main className={classnames(classes.main,classes.colSettings)}>
				{props.main}			
			</main>
			<div className={classnames(classes.right,classes.colSettings)}>
				{props.right}
			</div>
		</div>
	</div>    
}

export default withStyles(styles)(Page3Col)