
import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import AuthorList from './authorList/AuthorList';
import { authorService } from '../../../services';

import { authorActions } from '../../../redux/actions';

class AuthorListContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			id: "",
			author: "",
			openDialogEdit: false,
			authorList: []			
		}
	}
	componentDidMount(){		
		this.componentWillReceiveProps(this.props)	
		authorService.load( ).then( (list) =>{
            let items = list.rows
            
            this.setState({
                authorList: items
            })
        })
  	}

	componentWillReceiveProps( nextProps ){
		const {dispatch,storeAuthor,id} = nextProps
		if( !this.state.id ){
			this.setState({
				id: id
			})
			this.setAuthor( dispatch, storeAuthor, id )

			return false
		}else{
			if( id !== this.state.id ){
				this.setState({
					id: id
				})
			}
			if( !storeAuthor ) return false;
			if( storeAuthor.isFetching ) return false;

			this.setAuthor( dispatch, storeAuthor, id )
		}
	}

	setAuthor( dispatch, storeAuthor, id ){
		if( !id ) return false;

		if( storeAuthor 
			&& ( storeAuthor.isFetching || storeAuthor.isError ) ) return false
		if( !storeAuthor 
			|| !storeAuthor.list 
			|| !storeAuthor.list[id] ){

            dispatch( authorActions.loadByIds( [id] ) )

			return false	
		}
		
		
		const a = storeAuthor.list[id]
		a.profession = []
		a.related = []

		this.setState({
			author: a,
			openDialogEdit: true,
		})

		return true
	}

	handleOpenDialogEdit = (author_id) => {
		const {dispatch,storeAuthor } = this.props
		this.setAuthor( dispatch, storeAuthor, author_id )        
    }

    handleCloseDialogEdit = () => {
        const newState = update(this.state, { openDialogEdit: {$set: false} } )
        this.setState(newState) 
    }

	render(){
		const {author,openDialogEdit,authorList} = this.state

		
		return <div>{openDialogEdit}
			<AuthorList items={authorList} 
				author={author}
				openDialogEdit={openDialogEdit}
				handleOpenDialogEdit={this.handleOpenDialogEdit}
				handleCloseDialogEdit={this.handleCloseDialogEdit} />
		</div>
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		storeAuthor: state.author,     
  	};
}

const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorListContainer);
