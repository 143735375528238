let apiUrl;
let domainUrl;
let domainContent;
let domainTitle;

const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
  apiUrl = 'http://localhost:5004/';
  domainUrl = 'http://localhost:3000/';
  if( process.env.REACT_APP_SUB_SITE == "frasesmotivacion"){
    domainTitle = 'Frases Motivación Admin';
    domainContent = 'http://content.frasesmotivacion.local/assets/content/';  
    domainContent = 'https://content.frasesmotivacion.net/assets/content/';
  }else{
    domainTitle = 'Frase Admin';
    domainContent = 'http://frasede.local.com/assets/content/';  
  }

  
} else {
  if( hostname == "a.frasesmotivacion.net"){
    apiUrl = 'https://x.hectormarti.com/fma/';
    domainUrl = 'https://a.frasesmotivacion.net/';
    domainTitle = 'Admin Frases Motivación'
    domainContent = 'https://content.frasesmotivacion.net/assets/content/';    
  }else{
    apiUrl = 'https://x.hectormarti.com/fa/';
    domainUrl = 'https://a.frasede.com/';
    domainTitle = 'Admin Frase De'
    domainContent = 'https://frasede.com/assets/content/'; 
  }
  
}


export const API_URL =	apiUrl;
export const DOMAIN_URL =	domainUrl;
export const DOMAIN_TITLE = domainTitle;
export const DOMAIN_CONTENT =	domainContent;

export const YEAR_INI = 2012