import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles'

import TagContainer from '../../tagContainer/TagContainer';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';





const styles = theme => ({});

class TagList extends Component {    	
	
	render() {
		const { list, 
      handleOpenDialogEdit
    } = this.props
		if( !list )return false


		return <Table>
			<TableHead>
	         	<TableRow>
		            <TableCell>Nombre</TableCell>
		            <TableCell>Título</TableCell>
		            <TableCell>Plural</TableCell>
		            <TableCell>Femenino</TableCell>
		            <TableCell>Femenino Plural</TableCell>
					     <TableCell></TableCell>
	          	</TableRow>
        	</TableHead>
        	<TableBody>
        		{list
              .sort((a, b) =>  a.name.localeCompare(b.name) )
              .map( row => <TagContainer key={row.id} id={row.id} 
                handleOpenDialogEdit={handleOpenDialogEdit} /> )}
        	</TableBody>
		</Table>
	}
}


export default withStyles(styles)(TagList);