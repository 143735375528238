import React, {Component} from 'react';
import ReactDOM from 'react-dom'

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'

import deburr from 'lodash/deburr';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';

import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  	textField: {
   	  marginLeft: theme.spacing(1),
	     marginRight: theme.spacing(1),
    	 width: 200,
  	},  
  	container: {
   	position: 'relative',
  	},
  	suggestionsContainerOpen: {
   	position: 'absolute',
    	marginTop: theme.spacing(1),
    	left: 0,
    	right: 0,
  	},
  	suggestion: {
   	display: 'block',
 	},
  	suggestionsList: {
   	margin: 0,
   	padding: 0,
   	listStyleType: 'none',
  	},
});

function renderSuggestion(suggestion, { query, isHighlighted }) {
  	const matches = match(suggestion.label, query);
  	const parts = parse(suggestion.label, matches);

  	return <MenuItem selected={isHighlighted} component="div">
		<div>
		  	{parts.map((part, index) => {
		   	return part.highlight ? (
		      	<span key={String(index)} style={{ fontWeight: 500 }}>
		        		{part.text}
		      	</span>
		    	) : (
		      	<strong key={String(index)} style={{ fontWeight: 300 }}>
		        		{part.text}
		      	</strong>
		    	)
		  	})}
		</div>
	</MenuItem>
}


function getSuggestionValue (suggestion){
  	return suggestion.label;
}

function renderInputComponent(inputProps) {
  	const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  	return <TextField
      	fullWidth
      	InputProps={{
        		inputRef: node => {
          	ref(node);
          	inputRef(node);
        	},
        	classes: {
         	input: classes.input,
        	},
      }}
      {...other}
    />
}

class AutoSuggest extends Component { 

	constructor(props) {
		super(props)

		this.state = {
			value: '',
    		suggestions: [],
    		list: props.list
  		}

  		this.myRef = React.createRef();
	}
	
	componentWillReceiveProps(nextProps){
		this.setState({
			list: nextProps.list
		})
	}

	handleSuggestionsFetchRequested = ({ value }) => {
		const inputValue = deburr(value.trim()).toLowerCase();
	  	const inputLength = inputValue.length;
		
		if( inputLength > 2){
			this.props.getSuggestions(value).then( (res) => {
				var list = res.rows	  		
		  		let count = 0;

		  		list.filter( suggestion => {
			        	const keep =
			         	count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

			        	if (keep) {
			         	count += 1;
			        	}
			        	return keep;
			      })

		  		this.setState({
      			suggestions: list,
    			})
		  		return list
			})
		}
  	}

  	handleSuggestionsClearRequested = () => {
    	this.setState({
      	suggestions: [],
    	})
  	}

  	handleChange = name => (event, { newValue }) => {
    	this.setState({
      	value: newValue,
    	})
  	}

	render() {
    	const { classes, placeholder } = this.props;

		const autosuggestProps = {
			renderInputComponent,
			suggestions: this.state.suggestions,
			onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
			onSuggestionsClearRequested: this.handleSuggestionsClearRequested,			
			onSuggestionSelected: this.props.onSelect,
			getSuggestionValue,
			renderSuggestion,
		}


		const suggestElem = <div ref={this.myRef} ><Autosuggest
			

         {...autosuggestProps}
          
         menuPortalTarget={document.body}
         styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
         
         inputProps={{
         	classes,
            placeholder: placeholder,
            value: this.state.value,
            onChange: this.handleChange('currentValue'),
         }}
         
         theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
         }}
         
         renderSuggestionsContainer={options => {
         		let width = "auto"
         		let left = "0"
         		if( this.myRef.current){
         			width = this.myRef.current.getBoundingClientRect().width + "px"
         			left = this.myRef.current.getBoundingClientRect().left + "px"
         		}
         		//el.getBoundingClientRect().width
            	return <Paper {...options.containerProps} square style={{zIndex: 9999,position:"fixed", width: width,left: left}}>
            		{options.children}
            	</Paper>
            }
          }
        />  </div>

      /*return ReactDOM.createPortal(
      	suggestElem,
      	document.body
    	)*/
    	return suggestElem      
	}
}

export default withStyles(styles)(AutoSuggest);