import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { topicActions } from '../../../redux/actions'

import TopicAdd from './topicAdd/TopicAdd'

class TopicAddContainer extends Component { 
	
	constructor(props) {
		super(props)

		this.state = {
			topic: {},	
		}
	}
	
	emptyTopic(){
		const emptyTopic = { 
			name: "",
		}
		return emptyTopic;
	}

	setEmptyTopic(){
		let newState = update( this.state, {
			topic: {$set: this.emptyTopic()}
		});		
		this.setState( newState );
	}

	handleChange = (event) => {
		const target = event.target;
	   	const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			topic: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleSubmit = (event) => {
		const {dispatch,storeAuth} = this.props

		dispatch( topicActions.add( storeAuth.user, this.state.topic) )
      	this.setEmptyTopic()
	}

	render(){
		const {topic} = this.state
		return  <TopicAdd topic={topic}
			handleChange={this.handleChange}
			handleSubmit={this.handleSubmit} />
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeTopic: state.topic, 
		storeAuth: state.auth,
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicAddContainer)
