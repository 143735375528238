import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles'

import MenuContainer from './menuContainer/MenuContainer';

const styles = theme => ({
	container: {
		display: 'flex',
		height: '100vh',
		overflow: 'hidden',
	},
	colSettings: {
//		marginRight: theme.spacing.unit * 2,
  		//marginLeft: theme.spacing.unit * 2,
  		paddingRight: theme.spacing(2),
  		paddingLeft: theme.spacing(2),
  		paddingTop: theme.spacing(2),
	},
	left: {
  		overflow: 'auto',
  		overflowX: 'hidden',
  		height: '100vh',
  		width: '20%',
  		backgroundColor: theme.palette.background.default,
  		boxShadow:  theme.shadows[3],  		
  	},
  	main: {
  		height: '100vh',
  		overflow: 'auto',
      width: '80%'
  	},
  	right: {
  		overflow: 'auto',
  		overflowX: 'hidden',
  		height: '100vh',
  		width: '20%',
  		backgroundColor: theme.palette.background.default,
  		boxShadow: theme.shadows[1],
  	},
});

const Page2Col = (props) => {
	const {classes} = props
  const left = ( props.left )?<div className={classnames(classes.left,classes.colSettings)}>{props.left}</div>:""
  const right = ( props.right )?<div className={classnames(classes.right,classes.colSettings)}>{props.right}</div>:""
	return <div>
		<MenuContainer />
		<div className={classes.container}>	
			{left}		
        	<main className={classnames(classes.main,classes.colSettings)}>
			 	{props.main}			
			</main>
			{right}
		</div>
	</div>    
}

export default withStyles(styles)(Page2Col)