import React from 'react';

import PhotoEditorContainer from '../../../photoEditorContainer/PhotoEditorContainer'


import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PhotoIcon from '@material-ui/icons/Photo';


const styles = theme => ({
	errorMsg: {
    	padding: theme.spacing(3, 2),
    	margin: theme.spacing(3, 2),
    	backgroundColor: "Red"
  	},
  	asyncSelect: {
		margin: theme.spacing(1),
		marginTop: theme.spacing(2),
  	},
  	textField: {
  		margin: theme.spacing(1),
  	},
  	buttonPhoto:{
  		position: "absolute",
  		top: "5px",
  		right: "5px"
  	},
  	photoContainer:{
  		position: "relative"
  	}
});

const AuthorAdd = (props) => {
	const { classes,
		author,
		imageAuthorUrl,imageAuthorOriginalUrl,
		handleChange,
		handleCountryChange,loadOptionsCountry,
		handleAuthorRelatedChange,loadOptionsAuthorRelated,
		handleProfessionChange,loadOptionsProfession,
		handleGenderChange,optionsGender,
		
		openDialogPhotoEditor, handleOpenDialogPhotoEditor,handleCloseDialogPhotoEditor,
		handlePhotoEditorSave,
		
		handleSubmit		
		} = props

	var sError = ""
	if( props.errorMsg){
		sError = <Paper className={classes.errorMsg}>
			<strong>Error: </strong> {props.errorMsg}
		</Paper>
	}


	let sPhoto = ""
	if( imageAuthorUrl ){
		sPhoto = <img src={imageAuthorUrl}
			alt="Foto del Autor" style={{width: "100%"}}/>
	}
	return <Grid container spacing={2}>
			<Grid item xs={3} className={classes.photoContainer}>
          		{sPhoto}
          		<Button className={classes.buttonPhoto} 
    				variant="contained" color="primary"
    				onClick={handleOpenDialogPhotoEditor}>
    				<PhotoIcon /></Button>
        	</Grid>
        	<Grid item xs={9} >
        		<Grid container spacing={2}>
	        		<Grid container >
		        		{sError}
		        		<Grid item xs={12} >	
			          		<TextField
								label="Nombre"
						    	name="aut_nombre"
						    	placeholder="Nombre" 
						    	fullWidth
						    	className={classes.textField}
							    onChange={handleChange} 
							    value={author.aut_nombre}  />
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={3} >	
							<AsyncSelect
			        			name="country_id"
								className={classes.asyncSelect}
			        			menuPortalTarget={document.body}
			        			styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
			        			placeholder="País"
				          		cacheOptions
				          		loadOptions={loadOptionsCountry}
				          		defaultOptions
				          		onChange={handleCountryChange}
				        	/>
						</Grid>
						<Grid item xs={3}>
			          		<Select
			          			name="sexo"         			
			          			menuPortalTarget={document.body}
			          			className={classes.asyncSelect}
			        			styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
								placeholder="Sexo"					
				          		options={optionsGender}
				          		defaultOptions
								onChange={handleGenderChange}
				        	/>
			        	</Grid>
			        	<Grid item xs={3}>
			        		<TextField
			        			label="Nacimiento"
								type="date"
						    	name="born"
						    	className={classes.textField}
							    onChange={handleChange} 
							    value={author.born} 
							    InputLabelProps={{
			      					shrink: true,
			    				}}
				        	/>
				        </Grid>
				        <Grid item xs={3}>
				        	<TextField
				        		label="Muerte"
								type="date"
						    	name="die"
							    onChange={handleChange} 
							    className={classes.textField}
							    value={author.die} 
							    InputLabelProps={{
			      					shrink: true,
			    				}}
				        	/>
				        </Grid>
				    </Grid>
				</Grid>
			</Grid>
			<Grid container >
				<Grid item xs={12} >	
					<TextField
						label="Descripción"
				    	name="description_short"
				    	placeholder="Descripción" 
				    	fullWidth
				    	className={classes.textField}
					    onChange={handleChange} 
					    value={author.description_short}  />
				</Grid>
        	</Grid>
        	<Grid container >
	        	<Grid item xs={6}>
	          		<AsyncSelect          			
	          			menuPortalTarget={document.body}
	          			className={classes.asyncSelect}
	        			styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
						placeholder="Autores Relacionados"	
						isMulti				
		          		loadOptions={loadOptionsAuthorRelated}
		          		defaultOptions
						onChange={handleAuthorRelatedChange}
		        	/>
	        	</Grid>
	        	<Grid item xs={6}>
	          		<AsyncSelect          			
	          			menuPortalTarget={document.body}
	          			className={classes.asyncSelect}
	        			styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
						placeholder="Profesión"	
						isMulti				
		          		loadOptions={loadOptionsProfession}
		          		defaultOptions
						onChange={handleProfessionChange}
		        	/>
	        	</Grid>
	        </Grid>

        	
        	<Grid item xs={12} style={{textAlign: "right"}}>
	        	<button className="btn btn-primary" type="button" 
					onClick={handleSubmit}>Añadir</button>
        	</Grid>

        	<Dialog open={openDialogPhotoEditor} 
         		 onClose={handleCloseDialogPhotoEditor} 
          		fullWidth={true}
          			maxWidth="md">
          		<DialogContent dividers>
	        		<PhotoEditorContainer 
	        			handleSave={handlePhotoEditorSave}
	        			imageSrc={imageAuthorOriginalUrl} 
	        			/>
          		</DialogContent>
      		</Dialog>
        </Grid>			
}

export default withStyles(styles)(AuthorAdd);