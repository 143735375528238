import React, {Component} from 'react';
import { connect } from 'react-redux'

import { authorActions } from '../../../redux/actions';

import update from 'immutability-helper';


import Author from './author/Author';

class AuthorContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			id: "",
			author: "",
			openDialogEdit: false			
		}
	}
	setAuthor( dispatch, storeAuthor, id ){
		if( !id ) return false;

		if( storeAuthor 
			&& ( storeAuthor.isFetching || storeAuthor.isError ) ) return false

		if( !storeAuthor 
			|| !storeAuthor.list 
			|| !storeAuthor.list[id] ){

            dispatch( authorActions.loadByIds( [id] ) )

			return false	
		}
		
		const a = storeAuthor.list[id]
		a.profession = []
		a.related = []

		this.setState({
			author: a
		})

		return true
	}

	componentDidMount(){		
		this.componentWillReceiveProps(this.props)	
  	}

	componentWillReceiveProps( nextProps ){
		const {dispatch,storeAuthor,id} = nextProps
		if( !this.state.id ){
			this.setState({
				id: id
			})
			this.setAuthor( dispatch, storeAuthor, id )

			return false
		}else{
			if( id !== this.state.id ){
				this.setState({
					id: id
				})
			}
			if( !storeAuthor ) return false;
			if( storeAuthor.isFetching ) return false;

			this.setAuthor( dispatch, storeAuthor, id )
		}
	}

	callBackDelete = () => {
		this.setState({
			id: ""
		})
	}

	handleOpenDialogEdit = () => {
        const newState = update(this.state, { 
            openDialogEdit: {$set: true},
        })
        this.setState(newState)
    }

    handleCloseDialogEdit = () => {
        const newState = update(this.state, { openDialogEdit: {$set: false} } )
        this.setState(newState) 
    }

	render(){
		const {author,openDialogEdit} = this.state

		if ( !author ) return false

		return <Author 
			author={author}
			openDialogEdit={openDialogEdit}
			handleOpenDialogEdit={this.handleOpenDialogEdit}
			handleCloseDialogEdit={this.handleCloseDialogEdit}
			callBackDelete={this.callBackDelete}
			actions={this.props.actions}
			page={this.props.page} />	
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeAuthor: state.author,     
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorContainer);
