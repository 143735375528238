import {fetchApi} from '../helpers';

export const topicService = {
	load,
    loadById,
	add,
    saveField,
    uploadPhoto,
    uploadPhotoBlob,
    _delete,
};

function load() {
    const res  = fetchApi('api/topicLoad', {
    }) 

    return res
}

function loadById( id ) {
    const res  = fetchApi('api/topicLoadById', {
        id: id,
    }) 

    return res
}

function add( topic ) {
    const res  = fetchApi('api/topicAdd', {
        topic: topic,
    }) 

    return res
}

function saveField(id,name,value) {
    const res  = fetchApi('api/topicSaveField', {
        id: id,
        name: name,
        value: value,
    }) 

    return res
}

function uploadPhoto( formData ) {
    const res  = fetchApi('api/topicUploadPhoto', formData,'file') 

    return res
}

function uploadPhotoBlob( topicId, oImg, oOriginal ) {
    let oData = {
        topicId: topicId,
        object: {},
        blob: {
            imageCropped: oImg,
            imageOriginal: oOriginal
        }
    }
    const res  = fetchApi('api/topicUploadPhotoBlob', oData,'blob') 

    return res
}

function _delete(id) {
    const res  = fetchApi('api/topicDelete', {
        id: id,
    }) 

    return res
}