import React, {Component} from 'react';

import Cropper from 'react-cropper';


import Button from '@material-ui/core/Button';


import 'cropperjs/dist/cropper.css';

import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid';


import CropIcon from '@material-ui/icons/Crop';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import RestoreIcon from '@material-ui/icons/Restore';
import FlipIcon from '@material-ui/icons/Flip';
import SaveIcon from '@material-ui/icons/Save';
import Divider from '@material-ui/core/Divider';
import PhotoIcon from '@material-ui/icons/Photo';
import OpenWithIcon from '@material-ui/icons/OpenWith';


const styles = theme => ({
	toolNav: {
		flexGrow: 1,
		justifyContent: "center",
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: theme.shape.borderRadius,
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.text.secondary,
		marginTop: theme.spacing(1.5),
		'& svg': {
		margin: theme.spacing(1.5),
		},
		'& hr': {
		margin: theme.spacing(0, 0.5),
		},
    },	    
    flipVertical: {
    	transform:  `rotate(90deg)`
    },
    menuNav: {
    	justifyContent: "center"	
    },
    buttonText: {
    	margin: theme.spacing(1.5)
    },
    buttonFile: {
    	margin: theme.spacing(1.5)
    }
});



class PhotoEditor extends Component {    	
	
	render() {
		const { classes,
			setCropper,
			ratio,
			handleUpload,
			handleModeCrop,handleModeView,
			handleRotateRight,handleRotateLeft,
			handleZoomIn,handleZoomOut,
			handleFlipHorizontal,handleFlipVertical,
			handleReset,handleSave,
			imageSrc } = this.props
	
		let sAspectRatio = ""
		switch( ratio ){
			case "free":
				sAspectRatio = ""
				break
			default:
				sAspectRatio = 1
		}

		let cropperElem = imageSrc? <Cropper
		        onInitialized={(instance) => {
            		setCropper(instance);
          		}}
		        src={imageSrc}
		        viewMode={1}
		        rotable={true}
		        scalable={true}
		        style={{height: 400, width: '100%'}}

		        aspectRatio={sAspectRatio}
		        guides={true} />:""
		  	

		return <div className={classes.root}>
			{cropperElem}
			imageSrc: {imageSrc}
		  	<Grid container className={classes.toolNav}>
		  		<Button onClick={handleModeView} ><OpenWithIcon /></Button>
		        <Button onClick={handleModeCrop} ><CropIcon /></Button>
		        
		        <Divider orientation="vertical" flexItem />		        
		        
		        <Button onClick={handleRotateLeft} ><RotateLeftIcon /></Button>
		        <Button onClick={handleRotateRight} ><RotateRightIcon /></Button>
		        
		        <Divider orientation="vertical" flexItem />
		        
		        <Button onClick={handleZoomIn} ><ZoomInIcon /></Button>
		        <Button onClick={handleZoomOut} ><ZoomOutIcon /></Button>
		        
		        <Divider orientation="vertical" flexItem />
		        
		        <Button onClick={handleFlipHorizontal} ><FlipIcon /></Button>
		        <Button onClick={handleFlipVertical} ><FlipIcon className={classes.flipVertical}/></Button>
	      	</Grid>
	      	<Grid container  className={classes.menuNav}>
	      		<Button variant="contained" color="primary" 
	      			startIcon={<RestoreIcon />}
	      			className={classes.buttonText} onClick={handleReset} >Deshacer</Button>
		        <Button variant="contained" color="primary"
		        	startIcon={<SaveIcon />}
		        	className={classes.buttonText}	onClick={handleSave} >Guardar</Button>
	          	
	          	<Button variant="contained" color="primary" component="label"  
	          		startIcon={<PhotoIcon />}
	          		htmlFor='uploadPhoto' className={classes.buttonFile}>
	          			Subir Foto</Button>
	        </Grid>
	      	<input type='file' id='uploadPhoto' onChange={handleUpload} 
	      		style={{visibility: "hidden"}}/> 

		</div>
		
	}
}

export default withStyles(styles)(PhotoEditor);