import React, {Component} from 'react';
import update from 'immutability-helper';
import PhotoEditor from './photoEditor/PhotoEditor'


import Cropper from 'react-cropper';

const cropper = React.createRef(null);

async function exists(url) {

		const result = await fetch(url, { method: 'HEAD' });
		console.log("PHOTO EDITOR EXISTS SRC",result,url,result.ok)
		return result.ok;
}

class PhotoEditorContainer extends Component { 
	
	constructor(props) {
		super(props)

		this.cropper = cropper
		this.flipHorizontal = 1
		this.flipVertical = 1
		
		this.state = {
			srcExists: exists(this.props.imageSrc),
			isBlob: false,
			imageBlob: {},
			imageBlobSrc: ""
		}
	}

	componentDidMount(){
		let newState = update( this.state, {
			srcExists:  exists(this.props.imageSrc),
		});

		this.setState( newState );
	}

	componentWillUpdate(nextProps){
		if( nextProps.imageSrc !== this.props.imageSrc ){
			console.log("UUUU UPDATE")
			let newState = update( this.state, {
				srcExists:  exists(nextProps.imageSrc),
			});

			this.setState( newState );		
		}
	}
	setCropper = (cropper) => { 
		this.cropper = cropper 
	}

	handleModeCrop = () => {		
        this.cropper.setDragMode('crop')
	}

	handleModeView = () => {
        this.cropper.setDragMode('move')
	}

	handleRotateRight = () => {
	
        this.cropper.rotate('90');
        return true
	}
	handleRotateLeft = () => {
        this.cropper.rotate('-90');
        return true
	}
	handleZoomIn = () => {
        this.cropper.zoom('0.5');
        return true
	}
	handleZoomOut = () => {
        this.cropper.zoom('-0.5');
        return true
	}
	handleFlipHorizontal = () => {
		if( this.flipHorizontal === -1 ){
			this.flipHorizontal = 1
		}else{
			this.flipHorizontal = -1
		}
		this.handleFlip()
		return true	
	}
	handleFlipVertical = () => {

		if( this.flipVertical === 1 ){
			this.flipVertical = -1
		}else{
			this.flipVertical = 1
		}
		this.handleFlip()
		return true	
	}

	handleFlip = () => {
		const {flipHorizontal,flipVertical} = this


        this.cropper.scale(flipHorizontal, flipVertical)
        return true
	}

	handleReset = () => {
		this.flipHorizontal = 1
		this.flipVertical = 1

        this.cropper.reset()
	}

	handleSave = () => {
		if( !this.props.handleSave) return false


        const handleSave = this.props.handleSave
        const imageBlob = this.state.imageBlob
    	this.cropper.getCroppedCanvas().toBlob(function(oImgCropped) {
    		handleSave(oImgCropped, imageBlob)
    	})

        return true
	}

	handleUpload = e => {
		/*if (typeof this.cropper.current.getCroppedCanvas() === 'undefined') {
            return;
        }*/
        const oFile = e.target.files[0]

		this.setState({
			isBlob: true,
			imageBlob: oFile,
			imageBlobSrc: URL.createObjectURL(oFile)
		})


        return true
	}

	render(){
		const {isBlob,imageBlobSrc,srcExists} = this.state
		const {ratio} = this.props
		
		let imageSrc = (isBlob)?imageBlobSrc:this.props.imageSrc
		if(!isBlob && !srcExists){
			console.log("UUUUUuSHIT",srcExists)
			imageSrc = ""
		}
		return  <PhotoEditor 
			setCropper={this.setCropper}

			ratio={ratio}
			
			handleModeCrop={this.handleModeCrop}
			handleModeView={this.handleModeView}
			handleRotateRight={this.handleRotateRight}
			handleRotateLeft={this.handleRotateLeft}
			handleZoomIn={this.handleZoomIn}
			handleZoomOut={this.handleZoomOut}
			handleFlipHorizontal={this.handleFlipHorizontal}
			handleFlipVertical={this.handleFlipVertical}
			handleReset={this.handleReset}
			handleSave={this.handleSave}
			handleUpload={this.handleUpload}

			imageSrc={imageSrc} />
		
  }
}


export default PhotoEditorContainer
