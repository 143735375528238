import React, {Component} from 'react';

import AuthorEditContainer from '../../authorEditContainer/AuthorEditContainer';
import AuthorDeleteContainer from '../../authorDeleteContainer/AuthorDeleteContainer';


import {formatDbDate,isEmptyDbDate} from '../../../../functions';


import IconButton from '@material-ui/core/IconButton';

import Avatar from '@material-ui/core/Avatar';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import EditIcon from '@material-ui/icons/Edit';

import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	card: {
  		display: 'flex',
  		marginBottom: theme.spacing(2),
  		justifyContent: 'space-between'
  	},  	
	cover: {
   		height: '150px',
   		width: '150px',
   		flexShrink: 0,
  	},  
  	details: {
		display: 'flex',
		flexDirection: 'column',
  	},

  	content: {
  		flex: '1 0 auto'
  	},	
  	action: {
  		display: 'flex'
  	},
  	actionLeft: {
  		flexGrow: 1
  	},
  	avatar: {
  		width: '30px',
  		height: '30px'
  	},

  	author:{
  		display: 'flex',
  		margin: theme.spacing(2),
  		marginBottom: theme.spacing(3)
  	},
  	title:{
  		fontSize: '40px',

  	},
  	large: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      marginRight: theme.spacing(3),
   },
});


class Author extends Component {    	
	
	render() {
		const {  author,classes,
      callBackDelete,
      handleOpenDialogEdit,handleCloseDialogEdit,openDialogEdit} = this.props
		if( !author )return false

		let sDates = ""
		if( isEmptyDbDate( author.born )){
			sDates += "???? "
		}else{
			sDates += formatDbDate(author.born)
		}
		if( !isEmptyDbDate( author.die )){
			sDates += " - "+ formatDbDate(author.die)
		}
		
    	let sProfession = ""
    	if( author.professionList ){
      	author.professionList.map( (p) => {
         	sProfession += " "+ p.name 
          return true
      	})
    	}

    	let sRelated = ""
    	if( author.relatedList ){
      	author.relatedList.map( (p) => {
         	sRelated += " "+ p.aut_nombre 
          return true
      	})
    	}

		return <div className={classes.author}>
			<Avatar title={author.aut_nombre} 
					src={author.author_img} 
					className={classes.large} />
			<div>
				{sProfession}
				<Typography component="h1" className={classes.title}>{author.aut_nombre}</Typography>
          	<Typography component="h2" color="textSecondary">
            	{author.country_name} | {sDates} 
          	</Typography>
          	{author.description_short}<br/>
          	{sRelated}
			</div>
			<div>
				<IconButton onClick={handleOpenDialogEdit}><EditIcon /></IconButton>
        <AuthorDeleteContainer id={author.aut_id} callBack={callBackDelete} view="iconButton" />
			</div>
      <Dialog open={openDialogEdit} 
          onClose={handleCloseDialogEdit} 
          fullWidth={true}
          maxWidth="md">
          <DialogContent dividers>
            <AuthorEditContainer author={author} />
          </DialogContent>
      </Dialog>
		</div>
		
	}
}

export default withStyles(styles)(Author);