import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';


import { tagActions } from '../../../redux/actions'

import TagAdd from './tagAdd/TagAdd'

class TagAddContainer extends Component { 
	
	constructor(props) {
		super(props)

		this.state = {
			tag: this.emptyTag(),		
			errorMsg: ""
		}
	}
	
	emptyTag(){
		let emptyTag = { 
			type: this.props.type,
			name: "",
			title: "",
			plural: "",
			female: "",
			female_plural: ""
		}

		return emptyTag;
	}

	setEmptyTag(){
		let newState = update( this.state, {
			tag: {$set: this.emptyTag()}
		});		
		this.setState( newState );
	}
	

	handleChange = (event) => {
		const target = event.target;
	   	const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			tag: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleSubmit = (event) => {
		const {dispatch} = this.props

		const {tag} = this.state
		if( tag.name === ""){
			let newState = update( this.state, {
				errorMsg: {$set: "El nombre no puede estar vacío"},
			})
			this.setState( newState );
		}else{
			dispatch(tagActions.add(tag))
			this.setEmptyTag()
		}
	}

	render(){
		const {tag,errorMsg,} = this.state
		
		return  <TagAdd tag={tag}
			errorMsg={errorMsg}
			
			handleChange={this.handleChange}			
			handleSubmit={this.handleSubmit} 
		/>
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagAddContainer)
