import React from 'react';
import { withStyles } from '@material-ui/core/styles'


import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';



const styles = theme => ({
	errorMsg: {
    	padding: theme.spacing(3, 2),
    	margin: theme.spacing(3, 2),
    	backgroundColor: "Red"
  	},
  	textField: {
  		marginBottom: theme.spacing(1)
  	},
  	actionRow: {
  		textAlign: "center",
  		marginTop: theme.spacing(2)
  	}
});

const TagAdd = (props) => {

	const {tag,
		handleChange,handleSubmit,
	classes} = props

	if( !tag )return false
	
	var sError = ""
	if( props.errorMsg){
		sError = <Paper className={classes.errorMsg}>
			<strong>Error: </strong> {props.errorMsg}
		</Paper>
	}
	return <div className={classes.root}>
		<Typography variant="h5" gutterBottom>
        	Añadir Etiqueta
      	</Typography>

        		{sError}
          		<TextField
					label="Nombre"
					placeholder="Nombre"
			    	
			    	name="name"
			    	value={tag.name} 
			    	
			    	onChange={handleChange}

			    	className={classes.textField}
			    	fullWidth
			    	/>
				<TextField
					label="Título"
			    	placeholder="Título"
			    	
			    	name="title"
			    	value={tag.title} 
			    	
			    	onChange={handleChange} 
					
					className={classes.textField}
					fullWidth
			    	/>
				<TextField
					label="Plural"
					placeholder="Plural" 
			    	
			    	name="plural"
			    	value={tag.plural}			    	
			    	
				    onChange={handleChange}

					className={classes.textField}	
					fullWidth			    
				    />
				<TextField
					label="Femenino"
			    	placeholder="Femenino"

			    	name="female"
			    	value={tag.female}

				    onChange={handleChange} 
					
					className={classes.textField}	
					fullWidth			    
				    />

				<TextField
					label="Femenino Plural"
			    	placeholder="Femenino Plural"

			    	name="female_plural"
			    	value={tag.female_plural}

				    onChange={handleChange} 
					
					className={classes.textField}	
					fullWidth			    
				    />
        	       	
        		<div className={classes.actionRow}>
	        		<Button onClick={handleSubmit}>Añadir</Button>
	        	</div>
       	</div>		
}

export default withStyles(styles)(TagAdd);