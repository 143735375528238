import { tagService } from '../../services';
import { alertActions } from './';


export const tagActions = {
    loadByType,
    loadById,
    add,
    save,
    saveField,
    _delete,
}

export const TAG_LOAD_BY_TYPE_REQUEST = 'TAG_LOAD_BY_TYPE_REQUEST'
export const TAG_LOAD_BY_TYPE_SUCCESS = 'TAG_LOAD_BY_TYPE_SUCCESS'
export const TAG_LOAD_BY_TYPE_FAILURE = 'TAG_LOAD_BY_TYPE_FAILURE'

function loadByType( type ) {
    return dispatch => {
        dispatch(request( type ));   
        tagService.loadByType( type )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( type ) { 
        return { type: TAG_LOAD_BY_TYPE_REQUEST } 
    }
    function success(rows) { return { type: TAG_LOAD_BY_TYPE_SUCCESS, rows } }
    function failure(error) { return { type: TAG_LOAD_BY_TYPE_FAILURE, error } }
}

export const TAG_LOAD_BY_ID_REQUEST = 'TAG_LOAD_BY_ID_REQUEST'
export const TAG_LOAD_BY_ID_SUCCESS = 'TAG_LOAD_BY_ID_SUCCESS'
export const TAG_LOAD_BY_ID_FAILURE = 'TAG_LOAD_BY_ID_FAILURE'

function loadById( id ) {
    return dispatch => {
        dispatch(request( id ));   
        tagService.loadById( id )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: TAG_LOAD_BY_ID_REQUEST } 
    }
    function success(rows) { return { type: TAG_LOAD_BY_ID_SUCCESS, rows } }
    function failure(error) { return { type: TAG_LOAD_BY_ID_FAILURE, error } }
}

export const TAG_ADD_REQUEST = 'TAG_ADD_REQUEST'
export const TAG_ADD_SUCCESS = 'TAG_ADD_SUCCESS'
export const TAG_ADD_FAILURE = 'TAG_ADD_FAILURE'

function add( tag ) {
    return dispatch => {
        dispatch(request( tag ));   
        tagService.add(tag)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( tag ) { 
        return { type: TAG_ADD_REQUEST, tag } 
    }
    function success(rows) { return { type: TAG_ADD_SUCCESS, rows } }
    function failure(error) { return { type: TAG_ADD_FAILURE, error } }
}

export const TAG_SAVE_REQUEST = 'TAG_SAVE_REQUEST'
export const TAG_SAVE_SUCCESS = 'TAG_SAVE_SUCCESS'
export const TAG_SAVE_FAILURE = 'TAG_SAVE_FAILURE'

function save( task ) {
    return dispatch => {
        dispatch(request( task ));   
        tagService.save(task)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: TAG_SAVE_REQUEST, task } 
    }
    function success(rows) { return { type: TAG_SAVE_SUCCESS, rows } }
    function failure(error) { return { type: TAG_SAVE_FAILURE, error } }
}

export const TAG_SAVE_FIELD_REQUEST = 'TAG_SAVE_FIELD_REQUEST'
export const TAG_SAVE_FIELD_SUCCESS = 'TAG_SAVE_FIELD_SUCCESS'
export const TAG_SAVE_FIELD_FAILURE = 'TAG_SAVE_FIELD_FAILURE'

function saveField( id, name, value ) {
    return dispatch => {
        dispatch(request( id, name, value ));   
        tagService.saveField(id, name, value)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: TAG_SAVE_FIELD_REQUEST, id, name, value } 
    }
    function success(rows) { return { type: TAG_SAVE_FIELD_SUCCESS, rows } }
    function failure(error) { return { type: TAG_SAVE_FIELD_FAILURE, error } }
}

export const TAG_DELETE_REQUEST = 'TAG_DELETE_REQUEST'
export const TAG_DELETE_SUCCESS = 'TAG_DELETE_SUCCESS'
export const TAG_DELETE_FAILURE = 'TAG_DELETE_FAILURE'

function _delete( id ) { 
    return dispatch => {
        dispatch(request( id ));   
        tagService._delete( id )
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(id) { 
        return { type: TAG_DELETE_REQUEST, id: id } 
    }
    function success(rows) { return { type: TAG_DELETE_SUCCESS, id } }
    function failure(error) { return { type: TAG_DELETE_FAILURE, error } }
}