import React, {Component} from 'react';
import { connect } from 'react-redux'

import { tagActions } from '../../../redux/actions'

import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

class TagDeleteContainer extends Component {


	handleClick = (event) => {
		const {dispatch,callBack} = this.props

		const removeItem = (id, dispatch) => new Promise((resolve, reject) => {
			dispatch( tagActions._delete(id) )
		  	resolve();
		})


		if(window.confirm('Seguro que quieres eliminar esta etiqueta?')){

			removeItem(this.props.id, dispatch).then(() => {
				if(callBack){
        			callBack()
        		}      			
    		})
        }

	}

	render(){	
		if( !this.props.id ) return false

		let view = ""
		
		switch( this.props.view ){
			case 'menuItem':
				view = <MenuItem onClick={this.handleClick}>Delete</MenuItem>
				break;
			case 'iconButton':
				view = <IconButton onClick={this.handleClick}><DeleteIcon/></IconButton>
				break;
			default:
				view = <button type="button"
					className="btn btn-outline-danger" 
					onClick={this.handleClick}>
              		<DeleteIcon /> Eliminar</button>
        }


		return view 
	}
}

const mapStateToProps = (state, ownProps) => {
	return {

	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagDeleteContainer);