import { combineReducers } from 'redux'
import auth from './auth'
import alert from './alert'

import topic from './topic'
import author from './author'
import quote from './quote'
import tag from './tag'


const fraseAdminApp = combineReducers({
	alert,
	auth,
	topic,
	author,
	quote,
	tag,
})
 
export default fraseAdminApp