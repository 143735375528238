import React, {Component} from 'react';

import {DOMAIN_TITLE} from '../../constants';

import { connect } from 'react-redux'
import queryString from 'query-string'
import * as moment from 'moment';



import CalendarContainer from '../calendarContainer/CalendarContainer';
import QuoteContainer from '../quote/quoteContainer/QuoteContainer';


import AuthorContainer from '../author/authorContainer/AuthorContainer';



import { isEmpty } from '../../functions';

//import { DragDropContext } from 'react-beautiful-dnd';

import { quoteService } from '../../services';
import { quoteActions,authorActions } from '../../redux/actions';

import Page1Col from '../Page1Col';

import { history } from '../../helpers/history';


class CalendarPage extends Component {
	constructor(props) {
		super(props)

		this.state = {            
            quoteCalendar: {},
        	filterQuote: [],
            filterWord: "",
            authorId: "",
            openDialogQuoteAdd: false,
            openDialogAuthorAdd: false,
		}
    }

    setHeaders() {
        document.title = DOMAIN_TITLE + ": Calendario"
    }

    componentDidMount(){   
        this.setHeaders()     
        const filter = queryString.parse(this.props.location.search)
        if( filter.word  ){
            this.setState({
                filterWord: filter.word}
            )
            this.loadFilterWord(filter.word )
        }
        if( filter.author_id  ){
            this.setState({
                authorId: filter.author_id})  
            this.loadAuthorQuotes(filter.author_id)
        }
    }


    loadQuote = ( quoteList ) => {
        const {storeQuote,dispatch} = this.props
        let quoteIdList = []
        let authorIdList = []

        quoteList.map( q => {
            if( !storeQuote || !storeQuote.list || !storeQuote.list[q.id] ){
                quoteIdList.push(q.id)
                authorIdList.push(q.autor_id)
            }

            return true
        })
        if( !isEmpty(quoteIdList) ){
            dispatch( quoteActions.loadByIds( quoteIdList ) )
        }
        if( !isEmpty(authorIdList) ){
            dispatch( authorActions.loadByIds( authorIdList ) )
        }

        return true
    }

    loadQuotesWeek = (sIni,sEnd) => {
        if( !sIni || !sEnd ) return false

        this.setState({
            dateIni: sIni,
            dateEnd: sEnd,
        })
        history.push({
            location: history.location,
            search: '?ini='+ sIni +'&end='+ sEnd
        })

        quoteService.loadBetweenDates( sIni,sEnd ).then( (list) =>{
            let items = list.rows
            if(list.rows){
                this.loadQuote( items )
            }
            
            this.setState({
                quoteCalendar: items
            })
        })
    }

    loadAuthorQuotes = ( authorId ) => {
        const {dispatch} = this.props
        if( !authorId ) return false
        this.setState({
            authorId: authorId,
            filterWord: "",
        })
        history.push({
            location: history.location,
            search: '?author_id='+ authorId
        })


        quoteService.loadByAuthorId( authorId ).then( (list) =>{
            let items = list.rows
            dispatch( quoteActions.loadByIds( items ) )
            
            this.setState({
                quoteList: items
            })
            this.loadQuote( items )
        })
    }
   
	render(){
		const {
            quoteCalendar,
            authorId} = this.state

	
        var oAuthor = ""
        if( authorId){
            oAuthor = <div><AuthorContainer id={authorId} /></div>
        }

        let eventList = {}
        if(quoteCalendar){
            Object.keys(quoteCalendar).map( i => {
                const q = quoteCalendar[i]
                const sKey = moment(q.frs_fechapublicacion).format("YYYY-MM-DD")
                if( !eventList[sKey] ){
                    eventList[sKey] = []
                }
                eventList[moment(q.frs_fechapublicacion).format("YYYY-MM-DD")].push( 
                    <QuoteContainer key={q.id} id={q.id} size="quoteCalendar" /> )

                return true
            })
        }
		const main = <div>
            {oAuthor}
            <CalendarContainer 
                loadEvents={this.loadQuotesWeek} 
                eventList={eventList} />
        </div>
		
		return <Page1Col  main={main}  />
		
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
        storeQuote: state.quote,
        storeTopic: state.topic,
    };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
