import React, {Component} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AuthorEditContainer from '../../authorEditContainer/AuthorEditContainer';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import EditIcon from '@material-ui/icons/Edit';

import { withStyles } from '@material-ui/core/styles'



const styles = theme => ({
  	draggableQuoteList: {
   		width: '100%',
        padding: theme.spacing(1),
  	},
    draggableQuote: {
        padding: 0,
    }
});




class AuthorList extends Component {    	
	
	render() {
		const {items,
        handleOpenDialogEdit,handleCloseDialogEdit,openDialogEdit, author, classes} = this.props

		if( !items ) return false 
            
        return <div>{openDialogEdit}<List dense={false}>
                {
                    Object.keys(items)
                    .sort((a, b) => items[a].aut_nombre > items[b].aut_nombre ? 1 : -1)
                    .map((index) => {
                        const item = items[index]
                        return <ListItem button component="a" href={"?author_id="+ item.aut_id} key={item.aut_id}>
                            <ListItemText primary={item.aut_nombre} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Modificar"
                                    onClick={() => handleOpenDialogEdit(item.aut_id)}>
                                    <EditIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    })
                }
            </List>              
            <Dialog open={openDialogEdit} 
                onClose={handleCloseDialogEdit} 
                fullWidth={true}
                maxWidth="md">
                <DialogContent dividers>
                    <AuthorEditContainer author={author} />
                </DialogContent>
            </Dialog>
        </div>
	}
}

export default withStyles(styles)(AuthorList);