import { topicService } from '../../services';
import { alertActions } from './';


export const topicActions = {
    load,
    loadById,
    add,
    save,
    saveField,
    uploadPhoto,
    uploadPhotoBlob,
    _delete,
}

export const TOPIC_LOAD_REQUEST = 'TOPIC_LOAD_REQUEST'
export const TOPIC_LOAD_SUCCESS = 'TOPIC_LOAD_SUCCESS'
export const TOPIC_LOAD_FAILURE = 'TOPIC_LOAD_FAILURE'

function load() {
    return dispatch => {
        dispatch(request( ));   
        topicService.load()
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: TOPIC_LOAD_REQUEST } 
    }
    function success(rows) { return { type: TOPIC_LOAD_SUCCESS, rows } }
    function failure(error) { return { type: TOPIC_LOAD_FAILURE, error } }
}

export const TOPIC_LOAD_BY_ID_REQUEST = 'TOPIC_LOAD_BY_ID_REQUEST'
export const TOPIC_LOAD_BY_ID_SUCCESS = 'TOPIC_LOAD_BY_ID_SUCCESS'
export const TOPIC_LOAD_BY_ID_FAILURE = 'TOPIC_LOAD_BY_ID_FAILURE'

function loadById( id ) {
    return dispatch => {
        dispatch(request( id ));   
        topicService.loadById( id )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: TOPIC_LOAD_BY_ID_REQUEST } 
    }
    function success(rows) { return { type: TOPIC_LOAD_BY_ID_SUCCESS, rows } }
    function failure(error) { return { type: TOPIC_LOAD_BY_ID_FAILURE, error } }
}

export const TOPIC_ADD_REQUEST = 'TOPIC_ADD_REQUEST'
export const TOPIC_ADD_SUCCESS = 'TOPIC_ADD_SUCCESS'
export const TOPIC_ADD_FAILURE = 'TOPIC_ADD_FAILURE'

function add( userId, topic ) {
    return dispatch => {
        dispatch(request( userId, topic ));   
        topicService.add(topic)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: TOPIC_ADD_REQUEST, userId, topic } 
    }
    function success(rows) { return { type: TOPIC_ADD_SUCCESS, rows } }
    function failure(error) { return { type: TOPIC_ADD_FAILURE, error } }
}

export const TOPIC_SAVE_REQUEST = 'TOPIC_SAVE_REQUEST'
export const TOPIC_SAVE_SUCCESS = 'TOPIC_SAVE_SUCCESS'
export const TOPIC_SAVE_FAILURE = 'TOPIC_SAVE_FAILURE'

function save( task ) {
    return dispatch => {
        dispatch(request( task ));   
        topicService.save(task)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: TOPIC_SAVE_REQUEST, task } 
    }
    function success(rows) { return { type: TOPIC_SAVE_SUCCESS, rows } }
    function failure(error) { return { type: TOPIC_SAVE_FAILURE, error } }
}

export const TOPIC_SAVE_FIELD_REQUEST = 'TOPIC_SAVE_FIELD_REQUEST'
export const TOPIC_SAVE_FIELD_SUCCESS = 'TOPIC_SAVE_FIELD_SUCCESS'
export const TOPIC_SAVE_FIELD_FAILURE = 'TOPIC_SAVE_FIELD_FAILURE'

function saveField( id, name, value ) {
    return dispatch => {
        dispatch(request( id, name, value ));   
        topicService.saveField(id, name, value)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: TOPIC_SAVE_FIELD_REQUEST, id, name, value } 
    }
    function success(rows) { return { type: TOPIC_SAVE_FIELD_SUCCESS, rows } }
    function failure(error) { return { type: TOPIC_SAVE_FIELD_FAILURE, error } }
}

export const TOPIC_UPLOAD_PHOTO_REQUEST = 'TOPIC_UPLOAD_PHOTO_REQUEST'
export const TOPIC_UPLOAD_PHOTO_SUCCESS = 'TOPIC_UPLOAD_PHOTO_SUCCESS'
export const TOPIC_UPLOAD_PHOTO_FAILURE = 'TOPIC_UPLOAD_PHOTO_FAILURE'

function uploadPhoto( formData ) {
    return dispatch => {
        dispatch(request( formData ));   
        topicService.uploadPhoto(formData)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: TOPIC_UPLOAD_PHOTO_REQUEST, task } 
    }
    function success(rows) { return { type: TOPIC_UPLOAD_PHOTO_SUCCESS, rows } }
    function failure(error) { return { type: TOPIC_UPLOAD_PHOTO_FAILURE, error } }
}

export const TOPIC_UPLOAD_PHOTO_BLOB_REQUEST = 'TOPIC_UPLOAD_PHOTO_BLOB_REQUEST'
export const TOPIC_UPLOAD_PHOTO_BLOB_SUCCESS = 'TOPIC_UPLOAD_PHOTO_BLOB_SUCCESS'
export const TOPIC_UPLOAD_PHOTO_BLOB_FAILURE = 'TOPIC_UPLOAD_PHOTO_BLOB_FAILURE'

function uploadPhotoBlob( authorId, oImg, oOriginal ) {
    return dispatch => {
        dispatch(request( authorId, oImg, oOriginal  ));   
        topicService.uploadPhotoBlob(authorId, oImg, oOriginal)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( authorId, oImg, oOriginal ) { 
        return { type: TOPIC_UPLOAD_PHOTO_BLOB_REQUEST, authorId, oImg, oOriginal } 
    }
    function success(rows) { return { type: TOPIC_UPLOAD_PHOTO_BLOB_SUCCESS, rows } }
    function failure(error) { return { type: TOPIC_UPLOAD_PHOTO_BLOB_FAILURE, error } }
}

export const TOPIC_DELETE_REQUEST = 'TOPIC_DELETE_REQUEST'
export const TOPIC_DELETE_SUCCESS = 'TOPIC_DELETE_SUCCESS'
export const TOPIC_DELETE_FAILURE = 'TOPIC_DELETE_FAILURE'

function _delete( id ) { 
    return dispatch => {
        dispatch(request( id ));   
        topicService._delete( id )
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(id) { 
        return { type: TOPIC_DELETE_REQUEST, id: id } 
    }
    function success(rows) { return { type: TOPIC_DELETE_SUCCESS, rows } }
    function failure(error) { return { type: TOPIC_DELETE_FAILURE, error } }
}