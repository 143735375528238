import React, {Component} from 'react';

import { connect } from 'react-redux'
import queryString from 'query-string'

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';


import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import {DOMAIN_TITLE} from '../../constants';

import QuoteAddContainer from '../quote/quoteAddContainer/QuoteAddContainer';
import QuoteFilterContainer from '../quote/quoteFilterContainer/QuoteFilterContainer';
import QuoteListContainer from '../quote/quoteListContainer/QuoteListContainer'

import AuthorContainer from '../author/authorContainer/AuthorContainer';
import AuthorAddContainer from '../author/authorAddContainer/AuthorAddContainer';
import AuthorListContainer from '../author/authorListContainer/AuthorListContainer';


import { isEmpty } from '../../functions';

//import { DragDropContext } from 'react-beautiful-dnd';

import { quoteService,authorService } from '../../services';
import { quoteActions,authorActions } from '../../redux/actions';

import Page3Col from '../Page3Col';

import update from 'immutability-helper';
import { history } from '../../helpers/history';



class QuotePage extends Component {
	constructor(props) {
		super(props)

		this.state = {            
            quoteList: [],
        	filterQuote: [],
            filterWord: "",
            authorId: "",
            openDialogQuoteAdd: false,
            openDialogAuthorAdd: false,
		}
    }
    
    setHeaders() {
        document.title = DOMAIN_TITLE + ": Frases"
    }

    componentWillMount(){  
        const {storeAuthor,dispatch} = this.props

        this.setHeaders()
              
        const filter = queryString.parse(this.props.location.search)
        if( filter.word  ){
            this.setState({
                filterWord: filter.word})
            this.loadFilterWord(filter.word )
        }
        if( filter.author_id  ){
            this.setState({
                authorId: filter.author_id})  
            this.loadAuthorQuotes(filter.author_id)
        }
    }
    
    componentWillReceiveProps( nextProps ){
  
    }

    

    loadQuote = ( quoteList ) => {
        const {storeQuote,dispatch} = this.props
        let quoteIdList = []
        let authorIdList = []

        quoteList.map( q => {
            if( !storeQuote || !storeQuote.list || !storeQuote.list[q.id] ){
                quoteIdList.push(q.id)
                authorIdList.push(q.autor_id)
            }

            return true
        })
        if( !isEmpty(quoteIdList) ){
            dispatch( quoteActions.loadByIds( quoteIdList ) )
        }
        if( !isEmpty(authorIdList) ){
            dispatch( authorActions.loadByIds( authorIdList ) )
        }

        return true
    }

    reload = ( authorId ) => {
        if( !authorId) {
            authorId = this.state.authorId
        }
        this.loadAuthorQuotes(authorId)
    }

    loadAuthorQuotes = ( authorId ) => {
        const {dispatch} = this.props
        if( !authorId ) return false
        this.setState({
            authorId: authorId,
            filterWord: "",
        })
        history.push({
            location: history.location,
            search: '?author_id='+ authorId
        })


        quoteService.loadByAuthorId( authorId ).then( (list) =>{
            let items = list.rows
            dispatch( quoteActions.loadByIds( items ) )
            
            this.setState({
                quoteList: items
            })
            this.loadQuote( items )
        })
    }
    handleAuthorSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        const authorId  = suggestion.id
        if( !authorId ) return false

        this.loadAuthorQuotes( authorId )
    }

    handleFilterWordChange = ( event ) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let newState = update( this.state, {
            filterWord: {$set: value},
            authorId: {$set: ""}
        });

        this.setState( newState );
        history.push({
            location: history.location,
            search: '?word='+ value
        })
    }
    loadFilterWord( word ){
       quoteService.loadFilterWord( word ).then( (list) =>{
            this.setState({
                quoteList: list.rows
            })
            this.loadQuote( list.rows )
        }) 
    }

    handleFilterWordBlur = ( event ) => {
        this.loadFilterWord( this.state.filterWord )
    }

    handleOpenDialogQuoteAdd = (cityId) => {
        const newState = update(this.state, { 
            openDialogQuoteAdd: {$set: true},
        })
        this.setState(newState)
    }    

    handleCloseDialogQuoteAdd = () => {
        const newState = update(this.state, { openDialogQuoteAdd: {$set: false} } )
        this.setState(newState) 
    }


    handleOpenDialogAuthorAdd = (authorId) => {
        const newState = update(this.state, { 
            openDialogAuthorAdd: {$set: true},
        })
        this.setState(newState)
    }

    handleCloseDialogAuthorAdd = () => {
        const newState = update(this.state, { openDialogAuthorAdd: {$set: false} } )
        this.setState(newState) 
    }


	render(){
		const {
            openDialogQuoteAdd,openDialogAuthorAdd,
            filterQuote,quoteList,filterWord,
            authorId, authorList} = this.state
        const {storeAuthor} = this.props
        

        

		const left = <div>
            <List>
              <ListItem button onClick={this.handleOpenDialogQuoteAdd}>
                <ListItemIcon><AddIcon /></ListItemIcon>
                <ListItemText primary="Añadir Frase" />
              </ListItem>
              <ListItem button onClick={this.handleOpenDialogAuthorAdd}>
                <ListItemIcon><AddIcon /></ListItemIcon>
                <ListItemText primary="Añadir Autor" />
              </ListItem>
            </List>
            <Dialog open={openDialogQuoteAdd} 
                onClose={this.handleCloseDialogQuoteAdd} 
                fullWidth={true}
                maxWidth="md">
                <DialogContent dividers>
                    <QuoteAddContainer authorId={authorId} reload={(aId) => this.reload(aId)} />
                </DialogContent>
            </Dialog>
            <Dialog open={openDialogAuthorAdd} 
                onClose={this.handleCloseDialogAuthorAdd} 
                fullWidth={true}
                maxWidth="md">
                <DialogContent dividers>
                    <AuthorAddContainer />
                </DialogContent>
            </Dialog>
          <Divider />
		</div>

		const right = <div style={{display: "flex",flexDirection: "column",height: "100%"}}>
            <QuoteFilterContainer 
                items={filterQuote}
                handleAuthorSelected={this.handleAuthorSelected} 
                handleFilterWordChange={this.handleFilterWordChange}
                handleFilterWordBlur={this.handleFilterWordBlur}            
                filterWord={filterWord} />
            <div style={{overflowY: "scroll"}}>
                <AuthorListContainer />
            </div>
        </div>

        var oAuthor = ""
        if( authorId){
            oAuthor = <div>
                <AuthorContainer id={authorId} />                
            </div>
        }
		const main = <div>
            {oAuthor}
            <QuoteListContainer
                reload={(aId) => this.reload(aId)}
                items={quoteList} />
        </div>
		
		return <Page3Col  main={main} right={right} left={left} />
		
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
        storeQuote: state.quote,
        storeTopic: state.topic,
        storeAuthor: state.author, 
    };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotePage);
