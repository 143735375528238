import React, {Component} from 'react';

import QuoteContainer from '../../quoteContainer/QuoteContainer'

import { withStyles } from '@material-ui/core/styles'

import { Droppable, Draggable } from 'react-beautiful-dnd';


const styles = theme => ({
  	draggableQuoteList: {
   		width: '100%',
        padding: theme.spacing(1),
  	},
    draggableQuote: {
        padding: 0,
    }
});


const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'transparent',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    //background: isDraggingOver ? 'lightblue' : 'lightgrey',    
})


class QuoteList extends Component {    	
	
    onDragEnd = this.props.onDragEnd

	render() {
		const {items, classes,reload,
            view,
            handleCheckChange,isChecked} = this.props
		if( !items ) return false 

        if( this.onDragEnd ){
    		return <Droppable droppableId="droppable2">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        className={classes.draggableQuoteList}
                        style={getListStyle(snapshot.isDraggingOver)}>
                        {items.map((item, index) => (
                            <Draggable
                                key={item.id}
                                draggableId={item.id.toString()}
                                index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}

                                        className={classes.draggableQuote}
                                        
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}>
                                        <QuoteContainer 
                                            key={item.id} id={item.id} size="xs"
                                            reload={reload} />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        }else{
            let sView = "noPhoto"
            if(view){
                sView = view
            }
            return <div>
                {items.map((item, index) => (
                    <div key={item.id}>
                        <QuoteContainer 
                            key={item.id} id={item.id} size={sView}
                            handleCheckChange={handleCheckChange}
                            isChecked={isChecked}
                            reload={reload}/>
                    </div>))}
            </div>          
        }
	}
}

export default withStyles(styles)(QuoteList);