import React from 'react';
import { withStyles } from '@material-ui/core/styles'



import PhotoEditorContainer from '../../../photoEditorContainer/PhotoEditorContainer'


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


import CircularProgress from '@material-ui/core/CircularProgress';
import PhotoIcon from '@material-ui/icons/Photo';


const styles = theme => ({
  	textField: {
   		margin: theme.spacing(1),
  	},  	
  	buttonPhoto:{
  		position: "absolute",
  		top: "5px",
  		right: "5px"
  	},
  	photoContainer:{
  		position: "relative"
  	}
});

const TopicEdit = (props) => {
	const {topic,classes,
		handleChange,handleBlur,handleChangeBlur,
		isTopicUploading,
		openDialogPhotoEditor, handleOpenDialogPhotoEditor,handleCloseDialogPhotoEditor,
		handlePhotoEditorSave,
	} = props

	if( !topic ) return false

	let sPhoto = ""
	if(isTopicUploading){
		sPhoto = <CircularProgress />
	}else{
		if( topic.topic_img ){
			sPhoto = <img src={topic.topic_img} 
				alt="Foto del Tema" style={{width: "100%"}}/>
		}
	}

	return <div>
		<Grid container spacing={2}>
			<Grid item xs={3} className={classes.photoContainer}>
				{sPhoto}
    			<Button className={classes.buttonPhoto} 
    				variant="contained" color="primary"
    				onClick={handleOpenDialogPhotoEditor}>
    				<PhotoIcon /></Button>
			</Grid>
			<Grid item>
				<TextField
					label="Nombre"
					fullWidth
			    	className={classes.textField}
			    	name="name"
			    	placeholder="Nombre" 
				    onChange={handleChange} 
				    onBlur={handleBlur} 
				    value={topic.name} />

				<TextField
					label="Título"
					fullWidth
			    	className={classes.textField}
			    	name="title"
			    	placeholder="Nombre" 
				    onChange={handleChange} 
				    onBlur={handleBlur} 
				    value={topic.title} />
			</Grid>
		</Grid>

			<TextField
				multiline
          		rowsMax="8"
				label="Descripción Corta"
				fullWidth
		    	className={classes.textField}
		    	name="description_short"
		    	placeholder="Descripción Corta" 
			    onChange={handleChange} 
			    onBlur={handleBlur} 
			    value={topic.description_short}
			     />

			<TextField
				multiline
          		rowsMax="16"
				label="Descripción Larga"
				fullWidth
		    	className={classes.textField}
		    	name="description_long"
		    	placeholder="Descripción Larga" 
			    onChange={handleChange} 
			    onBlur={handleBlur} 
			    value={topic.description_long} />

			<TextField
				label="Orden"
		    	className={classes.textField}
		    	name="position"
		    	placeholder="Orden" 
			    onChange={handleChange} 
			    onBlur={handleBlur} 
			    value={topic.position} />

			<FormControlLabel
				control={
					<Checkbox
						name="featured"
          				checked={(topic.featured)?topic.featured:false}
          				onChange={handleChangeBlur} 
          				value="checked" />
				}
				label="Destacado"
			/>
			<Dialog open={openDialogPhotoEditor} 
         		 onClose={handleCloseDialogPhotoEditor} 
          		fullWidth={true}
          			maxWidth="md">
          		<DialogContent dividers>
	        		<PhotoEditorContainer 
	        			ratio="free"
	        			handleSave={handlePhotoEditorSave}
	        			imageSrc={topic.topic_img_orig} 
	        			/>
          		</DialogContent>
      		</Dialog>
		</div>
}

export default withStyles(styles)(TopicEdit);