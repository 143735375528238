import React, {Component} from 'react';
import { connect } from 'react-redux'

import Resizer from 'react-image-file-resizer';

import update from 'immutability-helper';

import { topicActions } from '../../../redux/actions'


import TopicEdit from './topicEdit/TopicEdit'

class TopicEditContainer extends Component { 
	
	constructor(props) {
		super(props)

		this.state = {
			id: '',
			topic: '',	
		}
	}

	setTopic( dispatch, storeTopic, id ){
		if( !id ) return false;

		if( storeTopic 
			&& storeTopic.isFetching) return false

		if( !storeTopic 
			|| !storeTopic.list ){
			//let topicList = storeTopic.list.filter( t => t.id === id)
			//dispatch( topicActions.loadById( id ) )
			return false
		}

		let topic = ""
		storeTopic.list.map( t => {
			if( t.id === parseInt(id,10) ) topic = t

			return true
		})

		this.setState({
			id: id,
			topic: topic,
		})

		return true
	}

	componentWillMount(){
		const {dispatch, storeTopic, id} =	this.props
		if( !id ) return false

		this.setTopic( dispatch, storeTopic, id )		
	}

	componentWillReceiveProps( nextProps ){
		const {dispatch, storeTopic, id} =	nextProps
		if( !id ) return false

		if( this.state.id !== id){
			this.setTopic( dispatch, storeTopic, nextProps.id )
		}
	}

	handleChange = ( event ) => {
        const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		let newState = update( this.state, {
			topic: { [name]: {$set: value} },
		});

		this.setState( newState );
    }

    handleBlur = ( event ) => {
		const {dispatch} = this.props

  		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		
        dispatch(topicActions.saveField( this.state.id, name, value )) 	    
    }

    handleChangeBlur = (event) => {
    	this.handleChange( event )
    	this.handleBlur( event )
    }


	onChangeAuthorUpload = e => {
		const {dispatch} = this.props

    	const files = Array.from(e.target.files)

    	let oData = {
    		files: files,
    		topicId: this.state.id
    	}
	    dispatch( topicActions.uploadPhoto( oData ) )    	
  	}

  	handleOpenDialogPhotoEditor = () => {
        const newState = update(this.state, { 
            openDialogPhotoEditor: {$set: true},
        })
        this.setState(newState)
    }

    handleCloseDialogPhotoEditor = () => {
        const newState = update(this.state, { openDialogPhotoEditor: {$set: false} } )
        this.setState(newState) 
    }

    handlePhotoEditorSave = (oImageCropped,oImageOriginal) => {
		const {dispatch} = this.props

		Resizer.imageFileResizer(
    		oImageCropped, //is the file of the new image that can now be uploaded...
    		600, // is the maxWidth of the  new image
    		600, // is the maxHeight of the  new image
    		'JPEG', // is the compressFormat of the  new image
    		'100', // is the quality of the  new image
    		0, // is the rotation of the  new image
    		oCropped => {
    			dispatch( topicActions.uploadPhotoBlob( this.state.id, oCropped, oImageOriginal ) )    	
            },// is the callBack function of the new image URI
    		'blob'// is the output type of the new image
    	);

	    this.handleCloseDialogPhotoEditor()
	    
    	return true
    }

	render(){
		const {topic,
			openDialogPhotoEditor} = this.state
		if( !topic ) return false
		return  <TopicEdit 
			handleChange={this.handleChange}
			handleBlur={this.handleBlur}
			handleChangeBlur={this.handleChangeBlur}
			
			openDialogPhotoEditor={openDialogPhotoEditor}
			handleOpenDialogPhotoEditor={this.handleOpenDialogPhotoEditor}
			handleCloseDialogPhotoEditor={this.handleCloseDialogPhotoEditor}

			handlePhotoEditorSave={this.handlePhotoEditorSave}

			topic={topic} />
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeTopic: state.topic, 
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicEditContainer)
