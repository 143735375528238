class Auth {

  static authenticateUser(token,user,subSite,siteName) {    
    localStorage.setItem('token', token);
    localStorage.setItem('userName', user);
    localStorage.setItem('subSite', subSite);
    localStorage.setItem('siteName', siteName);
  }

  static isUserAuthenticated() {
    return localStorage.getItem('token') !== null;
  }

  static deauthenticateUser() {
    localStorage.removeItem('token');
  }

  static getToken() {
    return localStorage.getItem('token');
  }

  static getUserName() {
    return localStorage.getItem('userName');
  }

  static getSiteName() {
    return localStorage.getItem('siteName');
  }
  
  static getSubSite() {
    return localStorage.getItem('subSite');
  }

}

export default Auth;