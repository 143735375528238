import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { tagActions } from '../../../redux/actions'

import TagEdit from './tagEdit/TagEdit'

class TagEditContainer extends Component { 
	
	constructor(props) {
		super(props)

		this.state = {
			tag: props.storeTag.list.find(tag => tag.id === props.id),
			errorMsg: ""
		}
	}	


	componentDidUpdate(prevProps) {
		const {storeTag} = this.props
		if( !storeTag || !storeTag.list) return false
		
		
		if( prevProps.storeTag.isSaving && !storeTag.isSaving ){
			this.setState({
				list: storeTag.list
			})
		}
	}

	handleChange = (event) => {
		const target = event.target;
	   	const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		let newState = update( this.state, {
			tag: { [name]: {$set: value} },
		});

		this.setState( newState );
	}
	
	handleBlur = ( event ) => {
		const {dispatch} = this.props
		const {tag} = this.state


  		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		
        dispatch(tagActions.saveField( tag.id, name, value )) 	    
    }
	
	render(){
		const {tag, errorMsg} = this.state
		return  <TagEdit tag={tag} 
			errorMsg={errorMsg}
			
			handleChange={this.handleChange}
			handleBlur={this.handleBlur}
		/>
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeTag: state.tag, 
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagEditContainer)
