import React from 'react';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'
import AsyncSelect from 'react-select/async';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';



const styles = theme => ({
	errorMsg: {
    	padding: theme.spacing(3, 2),
    	margin: theme.spacing(3, 2),
    	backgroundColor: "Red"
  	},
});

const QuoteAdd = (props) => {

	const {quote,author,
		handleChange,handleAutorIdChange,handleTagListChange,handleSubmit,
		loadOptionsAuthor,loadOptionsTag,classes} = props

	if( !quote )return false
	var sError = ""
	if( props.errorMsg){
		sError = <Paper className={classes.errorMsg}>
			<strong>Error: </strong> {props.errorMsg}
		</Paper>
	}
	return <Grid container spacing={1}>	 	
        	<Grid item xs={12}>
        		{sError}
          		<TextField
					label="Frase"
			    	name="frase"
			    	placeholder="Nombre" 
			    	variant="outlined"
			    	multiline
				    onChange={handleChange} 
				    value={quote.frase} 
				    style={{width: "100%",margin: 0}}/>
        	</Grid>
        	<Grid item xs={8}>
        		<AsyncSelect
        			name="tag_list"

        			menuPortalTarget={document.body}
        			styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        			
				    isMulti
					placeholder="Categorias"
	          		cacheOptions
	          		loadOptions={loadOptionsTag}
	          		defaultOptions
	          		onChange={handleTagListChange}
	        	/>

        	</Grid>
        	<Grid item xs={4}>
          		<AsyncSelect          			
          			menuPortalTarget={document.body}
        			styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
					defaultInputValue={author.aut_nombre}
					placeholder="Autor"
	          		cacheOptions
	          		loadOptions={loadOptionsAuthor}
	          		defaultOptions
					onChange={handleAutorIdChange}
	        	/>
        	</Grid>
        	<Grid item xs={6}>
	 		    <TextField
					type="date"
			    	name="date"
				    onChange={handleChange} 
				    value={quote.date} 
				    InputLabelProps={{
	          			shrink: true,
	        		}}
	        		style={{margin: 0}}
	        	/>
	 		</Grid>	
        	<Grid item xs={6} style={{textAlign: "right"}}>
	        	<button className="btn btn-outline-secondary" type="button" 
					onClick={handleSubmit}>Añadir</button>
        	</Grid>
        </Grid>			
}

export default withStyles(styles)(QuoteAdd);