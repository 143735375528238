import React, {Component} from 'react';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { topicActions } from '../../../redux/actions'


class TopicDeleteContainer extends Component {

	handleClick = (event) => {
		const {dispatch,callBack} = this.props

		if(window.confirm('Seguro que quieres eliminar este tema?')){
        	dispatch( topicActions._delete(this.props.id) )
        	if(callBack){
        		callBack()
        	}

        }

	}

	render(){	
		if( !this.props.id ) return false

		return <button type="button"
			className="btn btn-outline-danger" 
			onClick={this.handleClick}>
              <FontAwesomeIcon icon="trash-alt"/> Eliminar
      </button>
	}
}

const mapStateToProps = (state, ownProps) => {
	return {

	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicDeleteContainer);