import React, {Component} from 'react';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import List from '@material-ui/core/List';


import { withStyles } from '@material-ui/core/styles'


import 'moment/locale/es' 


const styles = theme => ({
	root: {
	},
	dateLabel:{
		backgroundColor: theme.palette.primary.light,
		margin: theme.spacing(1),
		padding: theme.spacing(1),
		textAlign: "center"
	},
	dateName: {
		fontSize: "90%"
	},
	eventList:{
		margin: theme.spacing(1)
	},
	addEventContainer:{
		textAlign: "center"
	},
	buttonAdd:{
		textAlign: "center",
		backgroundColor: theme.palette.secondary.main,
		padding: theme.spacing(1),
		marginBottom: theme.spacing(2),
		color: "white",
		width: "100%",
	},
});

class ColDay extends Component {    	
	
	render() {
		const { classes,
			date, eventList,
			handleOpenDialogAdd, } = this.props
			
		if( !date || !eventList ) return false
		date.locale('es');
		return <div className={classes.root}>
			<div className={classes.dateLabel}>
				<span className={classes.dayName}>{date.format("dddd")}</span>
				<h5>{date.format("DD MMMM")}</h5>
			</div>
			<div className={classes.eventList}>
				<div className={classes.addEventContainer}>
					<Button className={classes.buttonAdd}
						onClick={(d) => handleOpenDialogAdd(date.format("YYYY-MM-DD"))} 
						>
							<AddIcon />
					</Button>
				</div>
				<List component="nav">
				{eventList.map( (e) => {
					return e
				} )}
				</List>
			</div>
		</div>
		
	}
}

export default withStyles(styles)(ColDay);