import {fetchApi} from '../helpers';

export const tagService = {
    loadByType,
	loadById,
    loadByIds,
	loadByUrl,
    loadSuggestByType,
    loadSuggest,
    add,
    saveField,
    _delete,
};

function loadByType( type ) {
    const res  = fetchApi('api/tagLoadByType', {
        'type': type
    }) 

    return res
}

function loadById( id ) {
    const res  = fetchApi('api/tagLoadById', {
        'id': id
    }) 

    return res
}

function loadByIds( ids ) {
    const res  = fetchApi('api/tagLoadByIds', {
        'ids': ids
    }) 

    return res
}

function loadByUrl( url ) {
    const res  = fetchApi('api/tagLoadByUrl', {
        'url': url
    }) 

    return res
}

async function loadSuggestByType( type, name ) {
    const res  = fetchApi('api/tagLoadSuggestByType', {
        'type': type,
        'name': name
    }) 

    return res
}

async function loadSuggest( name ) {
    const res  = fetchApi('api/tagLoadSuggest', {
        'name': name
    }) 

    return res
}

function add( tag ) {
    const res  = fetchApi('api/tagAdd', {
        tag: tag,
    }) 

    return res
}
function saveField(id,name,value) {
    const res  = fetchApi('api/tagSaveField', {
        id: id,
        name: name,
        value: value,
    }) 

    return res
}


function _delete(id) {
    const res  = fetchApi('api/tagDelete', {
        id: id,
    }) 

    return res
}