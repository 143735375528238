import React from 'react';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
  	textField: {
   		margin: theme.spacing(2)
  	},  	
});

const TopicAdd = (props) => {
	const {topic,handleChange,handleSubmit,classes} = props

	return <div>
			<TextField
				label="Nuevo Tema"
		    	className={classes.textField}
		    	name="name"
		    	placeholder="Nombre" 
			    onChange={handleChange} 
			    value={topic.name} />

			<button className="btn btn-outline-secondary" type="button" 
				onClick={handleSubmit}>Añadir</button>
		</div>
}

export default withStyles(styles)(TopicAdd);