import React, {Component} from 'react';

import {Card,CardContent} from '@material-ui/core/';

import { withStyles } from '@material-ui/core/styles'


import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	card: {
  		display: 'flex',
  		marginBottom: theme.spacing(1),
  		justifyContent: 'space-between'
  	},  	
  	quote:{
  		fontWeight: 'bold',
  	},
  	content: {
  		display: 'flex',
		flexDirection: 'column',
  	},	
});

class Quote extends Component {    	
	
	render() {
		const { quote, author,classes } = this.props
		if( !quote )return false


		return <Card className={classes.card}>
			<CardContent className={classes.content}>
				<Typography variant="body1" className={classes.quote}>
					{quote.frs_frase}
				</Typography>
       		<Typography variant="body1" color="textSecondary">
         		{author.aut_nombre}
       		</Typography>
		   </CardContent>
		</Card>
	}
}

export default withStyles(styles)(Quote);