import React, {Component} from 'react';
import { connect } from 'react-redux'

import { authorService } from '../../../services'

import QuoteFilter from './quoteFilter/QuoteFilter'
import QuoteListContainer from '../quoteListContainer/QuoteListContainer'

class QuoteFilterContainer extends Component { 
	
	constructor(props) {
		super(props)

		this.state = {
			quoteList: [],
			authorSuggest: []
		}
	}

	getSuggestionsAuthor(name){
		return authorService.loadSuggest(name)
	}	



	render(){
		const {authorSuggest} = this.state
		let listContainer = ""
		if( this.props.onDragEnd ){
			listContainer = <QuoteListContainer
				move={this.props.move}
				onDragEnd={this.props.onDragEnd}
				items={this.props.items} />
		}
		return  <div>
			<QuoteFilter 
				handleFilterWordChange={this.props.handleFilterWordChange}
				handleFilterWordBlur={this.props.handleFilterWordBlur}
				filterWord={this.props.filterWord}
				handleAuthorSelected={this.props.handleAuthorSelected}				
				authorSuggest={authorSuggest} 
				getSuggestionsAuthor={this.getSuggestionsAuthor} />

			{listContainer}
		</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeAuthor: state.author, 
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteFilterContainer)
