import React, {Component} from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';


import { withStyles } from '@material-ui/core/styles'

import AutoSuggest from '../../../AutoSuggest'

const styles = theme => ({
  	textField: {
   	marginLeft: theme.spacing(1),
	   marginRight: theme.spacing(1),
	   marginBottom: theme.spacing(2),
  	}
});

class QuoteFilter extends Component { 

  	handleChange = name => (event, { newValue }) => {
    	this.setState({
      		[name]: newValue,
    	})
  	}


	render() {
		const {filterWord,handleFilterWordChange,handleFilterWordBlur,
			classes,authorSuggest,handleAuthorSelected,getSuggestionsAuthor} = this.props


		//if( !list ) return false

		return <div>
			<div className={classes.textField}>
				<TextField
					label="Buscar por palabra"
			    	name="name"
			    	fullWidth
			    	placeholder="Nombre" 
				   	onChange={handleFilterWordChange} 
				   	onBlur={handleFilterWordBlur} 
				   	value={filterWord}
				   	InputProps={{
            			endAdornment: (
              				<InputAdornment position="end">
                				<IconButton
                  					aria-label="Toggle password visibility"
                  					onClick={this.handleClickShowPassword}
                				>
                  					{filterWord ? <SearchIcon color="primary" /> : <SearchIcon color="secondary"/>}
                				</IconButton>
              				</InputAdornment>
            			)}} 
            	/>
			</div>

			<div className={classes.textField}>
	        	<AutoSuggest 
	        		styles={{ zIndex: 9999 }}
	        		placeholder="Busca un Autor"
	        		getSuggestions={getSuggestionsAuthor}
	            	list={authorSuggest}
	        		onSelect={handleAuthorSelected} />        
	      </div>
		</div>
	}
}

export default withStyles(styles)(QuoteFilter);