import React, {Component} from 'react';
import { connect } from 'react-redux'

import { tagActions } from '../../../redux/actions';

import TagList from './tagList/TagList';




class TagListContainer extends Component { 

	constructor(props) {
		super(props)

		this.state = {            
            list: [],        	
            type: "",
            openDialogTagAdd: false,
		}

		if(props.type){
			props.dispatch( tagActions.loadByType( props.type ) )
		}
    }

	componentDidUpdate(prevProps) {
		const {dispatch,type,storeTag} = this.props
		if( !type ) return false

		if( type !== prevProps.type ){
			dispatch( tagActions.loadByType( type ) )
		}

		if( prevProps.storeTag.isFetching && !storeTag.isFetching ){
			this.setState({
				list: storeTag.list
			})
		}

		if( (prevProps.storeTag.isDeleting && !storeTag.isDeleting) 
			||  (prevProps.storeTag.isAdding && !storeTag.isAdding) ){
			this.setState({
				list: storeTag.list
			})
		}	
	}


	render(){
		const { handleOpenDialogEdit } = this.props
		const {list} = this.state
		if( !list ) return false

		return <TagList 
			handleOpenDialogEdit={handleOpenDialogEdit}
			list={list}
		/>	
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
        storeTag: state.tag,
    };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TagListContainer);
