import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';
import Resizer from 'react-image-file-resizer';

import { tagService,authorService } from '../../../services';

import { authorActions } from '../../../redux/actions'

import {authorGender} from '../../../constants';

import AuthorAdd from './authorAdd/AuthorAdd'

class AuthorAddContainer extends Component { 
	
	constructor(props) {
		super(props)

		this.state = {
			author: this.emptyAuthor(),	
			errorMsg: "",
			openDialogPhotoEditor: false,
			imageAuthor: "",
			imageAuthorUrl: "",
			imageAuthorOriginal: "",
			imageAuthorOriginalUrl: ""
		}
	}
	
	emptyAuthor(){
		const emptyQuote = { 
			aut_nombre: "",
			country_id: "",
			sexo: "",
			born: "",
			die: "",
			description_short: "",
			authorRelated: [],
			profession: [],
			imageAuthorCropped: "",
			imageAuthorOriginal: ""
		}
		return emptyQuote;
	}

	setEmptyAuthor(){
		let newState = update( this.state, {
			author: {$set: this.emptyAuthor()},
			imageAuthor: {$set: ""},
			imageAuthorOriginal: {$set: ""}
		});		
		this.setState( newState );
	}

	handleChange = (event) => {
		const target = event.target;
	   	const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			author: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleGenderChange = (selectedOption) => {
		let newState = update( this.state, {
			author: {
				sexo: { $set: selectedOption.value},
			}
		});

		this.setState( newState );
	}
  	
	handleCountryChange = (selectedOption) => {
		if( selectedOption ){
			let newState = update( this.state, {
				author: {
					country_id: {$set: selectedOption.value},
				},
			});
			this.setState( newState );
		}
  	}

	loadOptionsCountry = (inputValue, callback) => {
   		tagService.loadSuggestByType( "country", inputValue).then( (list) => {
   			callback( list.rows.map( (tag) =>{
   				return ({ value: tag.id, label: tag.label})
   			}))
	    }) 		
	}

	handleAuthorRelatedChange = (selectedOption) => {
		if( selectedOption ){
			let newState = update( this.state, {
				author: {
					authorRelated: {$push: selectedOption}
				},
			});

			this.setState( newState );
		}
  	}
	loadOptionsAuthorRelated = (inputValue, callback) => {
   		authorService.loadSuggest( inputValue ).then( (list) => {
   			callback( list.rows.map( (aut) =>{
   				return ({ value: aut.id, label: aut.label})
   			}))
	    }) 		
	}

	handleProfessionChange = (selectedOption) => {
		if( selectedOption ){
			let newState = update( this.state, {
				author: {
					profession: {$push: selectedOption}
				}
			});
			this.setState( newState );
		}
  	}
	loadOptionsProfession = (inputValue, callback) => {
   		tagService.loadSuggestByType( "profesion", inputValue ).then( (list) => {
   			callback( list.rows.map( (tag) =>{
   				return ({ value: tag.id, label: tag.label})
   			}))
	    }) 		
	}


	handleSubmit = (event) => {
		const {dispatch,storeAuth} = this.props
		var {author,imageAuthorOriginal,imageAuthor} = this.state
		if( author.aut_nombre === "" ){
			let newState = update( this.state, {
				errorMsg: {$set: "El nombre no debe estar vacío"},
			})
			this.setState( newState );
		}else{
			if( imageAuthor ){			
				Resizer.imageFileResizer(
	    			imageAuthor, //is the file of the new image that can now be uploaded...
	    			300, // is the maxWidth of the  new image
	    			300, // is the maxHeight of the  new image
	    			'JPEG', // is the compressFormat of the  new image
	    			'100', // is the quality of the  new image
	    			0, // is the rotation of the  new image
	    			oCropped => {
	    				dispatch( authorActions.add( storeAuth.user, this.state.author, 
							imageAuthor, imageAuthorOriginal ) )
						this.setEmptyAuthor()    				
	            	},// is the callBack function of the new image URI
	    			'blob'// is the output type of the new image
	    		);
	    	}else{
	    		dispatch( authorActions.add( storeAuth.user, this.state.author, '', '' ) )
	    	}

			
		}
	}


	handleOpenDialogPhotoEditor = () => {
        const newState = update(this.state, { 
            openDialogPhotoEditor: {$set: true},
        })
        this.setState(newState)
    }

    handleCloseDialogPhotoEditor = () => {
        const newState = update(this.state, { openDialogPhotoEditor: {$set: false} } )
        this.setState(newState) 
    }

	handlePhotoEditorSave = (oImageCropped,oImageOriginal) => {

		this.setState({
			imageAuthor: oImageCropped,
			imageAuthorUrl: URL.createObjectURL(oImageCropped),
			imageAuthorOriginal: oImageOriginal,
			imageAuthorOriginalUrl: URL.createObjectURL(oImageOriginal),
		})
		
		this.handleCloseDialogPhotoEditor()
    	return true
    }

	render(){
		const {author,errorMsg,openDialogPhotoEditor,
			imageAuthorUrl,imageAuthorOriginalUrl} = this.state

		let optionsGender = Object.keys(authorGender).map( (i) => {
			let g = authorGender[i]
   			return {value: g.value, label: g.label}
   		})

		return  <AuthorAdd author={author}
			errorMsg={errorMsg}
			handleChange={this.handleChange}

			imageAuthorUrl={imageAuthorUrl}
			imageAuthorOriginalUrl={imageAuthorOriginalUrl}

			loadOptionsCountry={this.loadOptionsCountry}
			handleCountryChange={this.handleCountryChange}	

			handleProfessionChange={this.handleProfessionChange}
			loadOptionsProfession={this.loadOptionsProfession}

			handleAuthorRelatedChange={this.handleAuthorRelatedChange}
			loadOptionsAuthorRelated={this.loadOptionsAuthorRelated}

			optionsGender={optionsGender}
			handleGenderChange={this.handleGenderChange}	
					
			openDialogPhotoEditor={openDialogPhotoEditor}
			handleOpenDialogPhotoEditor={this.handleOpenDialogPhotoEditor}
			handleCloseDialogPhotoEditor={this.handleCloseDialogPhotoEditor}

			handlePhotoEditorSave={this.handlePhotoEditorSave}

			handleSubmit={this.handleSubmit} />
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeAuthor: state.author, 
		storeAuth: state.auth,
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorAddContainer)
